
import React, {Component} from 'react';
import 'antd/dist/antd.css';
import { Tabs, Button } from 'antd';
import MainNavigation from '../Navigation/mainNavbar'
import '../Navigation/mainNavbar.css';
import FinalLoginVendor from '../login/LoginVendor/LoginVendor'
import FinalLoginAdmin from '../login/LoginAdmin/LoginAdmin'
import FinalLoginUser from '../login/LoginUser/LoginUser'

const { TabPane } = Tabs;
const bgImage = require('../../../src/bg.jpg');
const logoImage = require('../../../src/payflow logo.png')
const divStyle = {
 
//   backgroundImage:`url(${bgImage})`,
  backgroundColor:'red',
  backgroundSize:'cover',
  height:'99vh',
};

const Logo = {
    height:'30vh',
};
class Login extends Component{
    render() {
        return(
<div>
            <div style={divStyle}>
                <MainNavigation/>
                <div>
                    <div style={{textAlign:'center',marginTop:'15px'}}>
                        <img height="250" width="250" src={logoImage}/>
                        <div>
                            <h3>PayFlow</h3>
                        </div>
                    </div>
                <Tabs style={{textAlign:'center',zIndex:1}}>
                    {/* <TabPane  tab="Admin Login" key="1">
                        <FinalLoginAdmin/>
                    </TabPane> */}
                    <TabPane tab="Vendor Login" key="2">
                        <FinalLoginVendor/>
                    </TabPane>
                    <TabPane tab="Department User Login" key="3">
                        <FinalLoginUser/>
                    </TabPane>
                </Tabs>

            </div>
            </div>

            </div>
        )
    }
}

export default Login

