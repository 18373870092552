import React,{Component} from 'react';
import { Table,Button } from 'antd';
import {bindActionCreators} from "redux";
import {updateVendor} from "../../actions/vendorsAction";
import {connect} from "react-redux";
import {notification,Icon} from 'antd';


class PendingVendors extends Component{



 // notification for approve vendor button

  openNotificationApproveVendor = () => {
    notification.open({
      message: 'Approve Vendor',
      description:
        'Vendor Approve Successfully',
      icon: <Icon style={{color:'#388E3C'}} type="check" style={{ color: '#108ee9' }} />,
    });
  };

  // notification for reject vendor button

 openNotificationRejectVendor = () => {
    notification.open({
      message: 'Reject Vendor',
      description:
        'Vendor Rejected',
      icon: <Icon style={{color:'#b71c1c'}} type="close" style={{ color: '#108ee9' }} />,
    });
  };


    render(){

        // const dataSource = [
        //     {
        //       key: '1',
        //       companyName: 'Mike',
        //       age: 32,
        //       address: '10 Downing Street',
        //     },
        //     {
        //       key: '2',
        //       companyName: 'John',
        //       age: 42,
        //       address: '10 Downing Street',
        //     },
        //     {
        //         key: '3',
        //         companyName: 'John',
        //         age: 42,
        //         address: '10 Downing Street',
        //       },
        //       {
        //         key: '4',
        //         companyName: 'John',
        //         age: 42,
        //         address: '10 Downing Street',
        //       },
        //       {
        //         key: '5',
        //         companyName: 'John',
        //         age: 42,
        //         address: '10 Downing Street',
        //       },
        //       {
        //         key: '6',
        //         companyName: 'John',
        //         age: 42,
        //         address: '10 Downing Street',
        //       },
        //   ];
        const columns = [
            { title: 'Company Name', dataIndex: 'companyName', key: 'companyName' },
            { title: 'Vendor Email', dataIndex: 'vendorEmail', key: 'vendorEmail' },
            { title: 'GST No', dataIndex: 'gstNo', key: 'gstNo' },
            { title: 'Phone No', dataIndex: 'phoneNo', key: 'phoneNo' },
            { title: 'Account Name', dataIndex: 'accountName', key: 'accountName' },
            { title: 'Bank', dataIndex: 'bank', key: 'bank' },
            { title: 'Account No', dataIndex: 'accountNo', key: 'accountNo' },
            { title: 'IFSC Code', dataIndex: 'ifscCode', key: 'ifscCode' },
            { title: 'Branch', dataIndex: 'branch', key: 'branch' },
            { title: 'services', dataIndex: 'services', key: 'services' },
            { title: 'address', dataIndex: 'address', key: 'address' },

            {
              title: 'Action',
              dataIndex: '',
              key: 'x',
              render: (record) =>{
                  console.log('record',record);
                 return(
                     <div>
                         <Button style={{backgroundColor:'#549914',color:'#FFF'}} onClick={() => this.handleApprove(record._id)}>Approve</Button>&nbsp;&nbsp;&nbsp;
                         <Button style={{backgroundColor:'#DC380C',color:'#FFF'}} onClick={() => this.handleReject(record._id)}>Reject</Button>
                     </div>
                 )
              }

            },
        ];


        return(
            <div>
                <Table
                    scroll={{ x: 'max-content'}}
                    columns={columns}
                    expandedRowRender={record => <p style={{ margin: 0 }}>{record.bank}</p>}
                    dataSource={this.props.tableData}
                />,
            </div>
        )
    }

    handleReject(vendorId) {
        this.props.updateVendor({
            token:this.props.token,
            status:'rejected',
            vendorId:vendorId
        });
        this.openNotificationRejectVendor()
        // this.openNotificationWithIcon('Reject', 'Vendor Rejected', `Rejected vendor!`);

        this.props.updateMe()
    }

    handleApprove(vendorId) {
        this.props.updateVendor({
            token:this.props.token,
            status:'approved',
            vendorId:vendorId
        });
        this.openNotificationApproveVendor()
        this.props.updateMe()
    }
}

function mapStateToProps(state) {
    return {
        register: state.register,
        vendor: state.vendorReducer,
        token: state.auth.token,
        pendingVendorsList: state.vendorReducer.pendingVendorsList,
        approvedVendorsList: state.vendorReducer.approvedVendorsList,
        rejectedVendorsList: state.vendorReducer.rejectedVendorsList
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ updateVendor: updateVendor }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(PendingVendors)


