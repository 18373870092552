import React from 'react';
import UsersList from './usersList';
import {withRouter, Link} from "react-router-dom";
import {connect} from "react-redux";
import {fetchLevelsList} from "../../../actions/vendorsAction";
import {bindActionCreators} from "redux";
import {changeUsersList} from "../../../actions/userAction";
import {fetchUsersList} from "../../../actions/userAction";

class manageUser extends React.Component {

    fetchUsers(page=1, options={}){
        //console.log("Searching user: ", page);
        this.props.changeUsersList();
        this.props.fetchUsersList({
            token: this.props.authToken,
            ...options
        });
    }
    static handleUserEdit(userId){
        console.log("Editing user: ", userId);
        //browserHistory.push('/app/user/edit/'+userId);
        return (<Link to={`/app/user/${userId}`} />)
    }
    fetchLevels=()=>{
        this.props.fetchLevelsList({
            token:this.props.authToken
        })
    }

    componentDidMount(){
        console.log("calling fetch users");
        this.fetchUsers();
        this.fetchLevels()
    }



    render () {
        console.log("UserListContainer props: ", this.props);
        return (
            <div className="box box-default">
                <div className="box-header">
                    <h2>Manage Users</h2>
                </div>
                <div className="box-body">
                    <UsersList
                        authToken={this.props.authToken}
                        vendorUsersList={this.props.vendorUsersList}
                        levelsList={this.props.levelsList}
                        fetchUsers={this.fetchUsers}
                        handleUserEdit={this.handleUserEdit}
                        userListLoading={this.props.userListLoading}
                        handleDeviceStatusClick={this.handleDeviceStatusClick}/>
                </div>

            </div>
        )
    }
}


const mapStateToProps = (state, props) => {
    console.log('routes/docs-list/component/userListContainer state: ', state);
    console.log('routes/docs-list/component/userListContainer props: ', props);
    return {
        authToken: state.auth.token,
        userListLoading: state.users.userListLoading,
        levelsList: state.vendorReducer.levelsList
    }
};

// const mapDispatchToProps = (dispatch) => {
//   console.log("userListContainer Dispatch is being mapped: ", typeof dispatch);
//   //console.log("Actions are: ", actions);
//   const mapped = {
//     changeUsersList: actions.changeUsersList,
//     fetchUsersList: actions.fetchUsersList,
//       fetchLevelsList:fetchLevelsList
//   };
//   console.log("userListContainer mapDispatchToProps mapped:", {mapped});
//   return mapped;
// };
function matchDispatchToProps(dispatch) {
    return bindActionCreators({ fetchLevelsList: fetchLevelsList,changeUsersList ,fetchUsersList}, dispatch)
}

export default withRouter(connect(mapStateToProps,matchDispatchToProps  )(manageUser));
