import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Icon, Button, Row, Col } from 'antd';
import './landing.modules.css'
import LoginOptions from './loginOptions'
import LoginVendor from '../../components/login/LoginVendor/LoginVendor'
import LoginUser from '../../components/login/LoginUser/LoginUser'
import LoginAdmin from '../../components/login/LoginAdmin/LoginAdmin'
import MainNavbar from '../../components/Navigation/mainNavbar'
const logoImage = require('../../../src/payflow-logo.png');
const userLogo = require('../../../src/userLogo.jpg')
const logo = require('../../../src/payflow-logo.png')
const payflowLogo = require('../../../src/payflowGraphics.svg')
// const divStyle = {

//     //   backgroundImage:`url(${bgImage})`,
//       backgroundColor:'#d0edfb ',
//       backgroundSize:'cover',
//       height:'100vh',
//     };


class LandingPage extends Component {


  render() {
    return (

      <div className="outer">
         <div style={{backgroundColor:'#b3dbff'}}>
          <div className="inner">
            {/* <div style={{ marginBottom: '38%',height:'10%',width:'30%'}}>
              <img height="80" width="80" src={logo}/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;<strong>PAYFLOW</strong>
            </div> */}
            <div  style={{ marginBottom: '35%',height:'20%',width:'19%'}}> <img height="75" width="75" src={logoImage}/> &nbsp;&nbsp;&nbsp;<strong style={{fontFamily:'MyriadPro',color:'#1f3f7a',fontSize:'20px'}}>PayFlow</strong></div>
          
            <div className="prod-left">

              <div style={{
                backgroundColor: '#FFF',
                height: '480px',
                width: '520px',
                position: 'absolute',
                margin: '10px',
                top: '15%',
                right:'50%',
                borderRadius: '20px',
                border: '2px solid #b3b3b3',
                boxShadow: '10px 10px 10px 10px rgba(0,0,0,0.2)',
                transition: '2.5s',
                marginLeft: '170px'

              }}>
                <LoginVendor />
              </div>

            </div>
            <div className="prod-right">
              <img src={payflowLogo} className="prod-payflow-img" alt="prod" height="680" width="740" marginLeft="50px" />
            </div>
          </div>

        </div>
       </div>


    )
  }
}

export default LandingPage