import React from 'react'
import ReactToPrint from 'react-to-print';
import PrintPurchaseOrder from './printPurchaseOrder'
class Example extends React.Component {
    render() {
      return (
        <div>
          <ReactToPrint
            trigger={() => <a href="#">Print this out!</a>}
            content={() => this.componentRef}
          />
          <PrintPurchaseOrder style={{display:'none'}} ref={el => (this.componentRef = el)} />
        </div>
      );
    }
  }

  export default Example