import { Table, Input, Button, Icon } from 'antd';
import Highlighter from 'react-highlight-words';
import React from "react";
import EProgress from "./EProgress";


class EDynamicTable extends React.Component {
    state = {
        searchText: '',
        searchedColumn: '',
    };

    // getColumnSearchProps = dataIndex => ({
    //     filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    //         <div style={{ padding: 8 }}>
    //             <Input
    //                 ref={node => {
    //                     this.searchInput = node;
    //                 }}
    //                 placeholder={`Search ${dataIndex}`}
    //                 value={selectedKeys[0]}
    //                 onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
    //                 onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
    //                 style={{ width: 188, marginBottom: 8, display: 'block' }}
    //             />
    //             <Button
    //                 type="primary"
    //                 onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
    //                 icon="search"
    //                 size="small"
    //                 style={{ width: 90, marginRight: 8 }}
    //             >
    //                 Search
    //             </Button>
    //             <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
    //                 Reset
    //             </Button>
    //         </div>
    //     ),
    //     filterIcon: filtered => (
    //         <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    //     ),
    //     onFilter: (value, record) =>
    //         record[dataIndex]
    //             .toString()
    //             .toLowerCase()
    //             .includes(value.toLowerCase()),
    //     onFilterDropdownVisibleChange: visible => {
    //         if (visible) {
    //             setTimeout(() => this.searchInput.select());
    //         }
    //     },
    //     render: text =>
    //         this.state.searchedColumn === dataIndex ? (
    //             <Highlighter
    //                 highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
    //                 searchWords={[this.state.searchText]}
    //                 autoEscape
    //                 textToHighlight={text.toString()}
    //             />
    //         ) : (
    //             text
    //         ),
    // });

    // handleSearch = (selectedKeys, confirm, dataIndex) => {
    //     confirm();
    //     this.setState({
    //         searchText: selectedKeys[0],
    //         searchedColumn: dataIndex,
    //     });
    // };

    // handleReset = clearFilters => {
    //     clearFilters();
    //     this.setState({ searchText: '' });
    // };

    render() {
        const columns = [
            {
                title: 'Vendor Name',
                dataIndex: 'createdByVendor.companyName',
                key: 'vendorName',
                width: '30%',
                // ...this.getColumnSearchProps('vendorName'),
            },
            {
                title: 'Invoice Number',
                dataIndex: 'invoiceNo',
                key: 'invoiceNo',
                width: '20%',
                // ...this.getColumnSearchProps('invoiceNo'),
            },
            {
                title: 'Level Number',
                dataIndex: 'approvedCount',
                key: 'levelNo',
                width: '20%',
                render:(index,record)=>{
                    let levelNos = "L"+ record.approvedCount
                    console.log("levels number in record are...in invoicesList.js",levelNos)
                    return(
                        <div>
                            <span>
                                {levelNos}
                            </span>
                        </div>
                    )
                }
            },

            {
                title: 'Progress',
                dataIndex: 'progress',
                key: 'progress',
                render: (code,record) =>
                    <div>
                        <EProgress record={record}/>
                    </div>
            },
        ];
        return <Table
            columns={columns}
            dataSource={this.props.data}
            pagination={{ pageSize: 4 }}
        />;
    }
}

export default EDynamicTable