import React, {Component} from 'react';
import { Suspense, lazy } from 'react';
import {BrowserRouter,HashRouter,Route,Switch,Redirect} from 'react-router-dom';
import './App.css';

// imports components
import Login from './components/login/Login'
import LoginAdmin from './components/login/LoginAdmin/LoginAdmin'
import registrationVendor from './components/registration/registrationVendor'
import vendorDashboard from './components/vendorDashboard/vendorDashboard'
import AdminDashboard from './components/adminDashboard/AdminDashboard'
import UserDashboard  from './components/userDashboard/userDashboard'
import RouteMe from './components/RouteMe'
import Loading from './components/loading/loader'

// import Landing Page
// import LandingPage from './components/landingPage/landing'
import LoginOptions from './components/landingPage/loginOptions'

// redux imports
import{createStore,applyMiddleware} from 'redux';
import { Provider } from 'react-redux';
import allReducers from './reducers/index';
import thunk from 'redux-thunk';
import addUser from "./components/vendorDashboard/users/addUser";
let store = createStore(allReducers, applyMiddleware(thunk));

// const Login = lazy(()=>import('./components/login/Login'))

const LandingPage = lazy(() => {
    return new Promise(resolve => {
     setTimeout(() => resolve(import("./components/landingPage/landing")), 1200);
         });
  });

class App extends Component{
  
  render() {
      console.log("Props of Router",this.props);
      return (
          <Suspense fallback={<div style={{textAlign:'center',marginTop:'25%'}}><Loading/></div>}>
          <div>
              <Provider store={store}>
                  <HashRouter>
                      <Switch>
                          <Route path="/landingPage" component={LandingPage}/>
                          <Route path="/loginOptions" component={LoginOptions}/>
                          <Route path="/loginVendor"  component={Login} />
                          <Route path="/registrationVendor" component={registrationVendor}/>
                          <Route path="/vendorDashboard" component={vendorDashboard}/>
                          <Route path="/admin-dashboard" component={AdminDashboard}/>
                          <Route path="/user-dashboard" component={UserDashboard}/>
                          <Route path="/loginAdmin" component={LoginAdmin}/>
                      </Switch>
                      <RouteMe/>
                  </HashRouter>
              </Provider>
          </div>
          </Suspense>

      );
  }
}

export default App;
