import React, {Component} from 'react';
import {Link, NavLink} from 'react-router-dom';
import { Radio } from "antd";
import LandingPage from './landing';
import LoginVendor from '../../components/login/LoginVendor/LoginVendor'
import LoginUser from '../../components/login/LoginUser/LoginUser'
import LoginAdmin from '../../components/login/LoginAdmin/LoginAdmin'
const userLogo = require('../../../src/user.jpg')


 class LoginOptions extends Component{
    constructor(props) {
        super(props);

        this.state = {
            visibleLoginVendor: true,
            visibleLoginDepartment: false,
            visibleLoginManagement: false,
            radio:''  
        };

        this.loginVendor = this.loginVendor.bind(this);
        this.loginDeptUser = this.loginDeptUser.bind(this);
        this.loginManagement = this.loginManagement.bind(this);
        this.onRadiochange = this.onRadiochange.bind(this);
    }

      // open login vendor on click
      loginVendor() {
        this.setState({visibleLoginVendor: !this.state.visibleLoginVendor})
    }

      // open login Department click
      loginDeptUser() {
        this.setState({visibleLoginDepartment: !this.state.visibleLoginDepartment})
    }

      // open login managemnt click
      loginManagement() {
        this.setState({visibleLoginManagement: !this.state.visibleLoginManagement})
    }

    // Radio onChange
    onRadiochange = value => {
        console.log("selected radio value:",value.target.value);
        this.setState({radio: value.target.value})
      };
     render(){
         console.log('updated state is',this.state.radio)
         return(
             <div>
                <div>
                <h4 style={{fontFamily:'MyriadPro',textAlign:'center',padding:'5px'}}>LOGIN AS:</h4>
                   <Radio.Group defaultValue="vendor"   onChange={this.onRadiochange}  buttonStyle="solid" >
                        <div style={{
                        height:'30px',
                        width:'100%',
                        marginLeft:'60px',
                        borderRadius:'10px',
                        marginTop:'20px',
                        

                     }}>
                            <Radio.Button  style={{fontFamily:'MyriadPro',width:'100%',color:'white',border:'blue',textAlign:'center',borderRadius:'25px',color:'#FFF'}}  onClick={this.loginVendor} value="vendor">VENDOR</Radio.Button>
</div>
                    <div style={{
                        height:'30px',
                        width:'100%',
                        marginLeft:'60px',
                        borderRadius:'10px',
                        marginTop:'20px'

                     }}>

<Radio.Button style={{fontFamily:'MyriadPro',width:'100%',color:'white',border:'blue',textAlign:'center',borderRadius:'25px',color:'#FFF'}} onClick={this.loginDeptUser} value="department">DEPARTMENT</Radio.Button>

</div>

                    <div style={{
                        height:'30px',
                        width:'100%',
                        marginLeft:'60px',
                        borderRadius:'10px',
                        marginTop:'20px',


                     }}>

<Radio.Button style={{fontFamily:'MyriadPro',width:'100%',color:'white',border:'blue',textAlign:'center',borderRadius:'25px',color:'#FFF'}} onClick={this.loginManagement} value="management">MANAGEMENT</Radio.Button>

</div>
                        </Radio.Group>
                </div>

                {/*Division for User Logo for vendor login page as well as Login Page Component imported  */}
                {/* {this.state.radio === 'vendor' &&  */}
                    <div>
                    <div style={{position:'absolute',
                            backgroundColor:'#eb3b95',
                            border:'3px solid  #eb3b95  ',
                            right:'295%',
                            margin:'10px',
                            top:'-30%',
                            borderRadius:'50px'}}>    
                                <img style={{borderRadius:'50px'}} height="50" width="50" src={userLogo}/> 
                     </div>
                                <div style={{
                                        backgroundColor:'#5793CE',
                                        height:'340px',
                                        width:'500px',
                                        position:'absolute',
                                        right:'220%',
                                        margin:'10px',
                                        top:'-10%',
                                        borderRadius:'20px',
                                        zIndex: 1,
                                        border:'2px solid #1f3f7a'}}>
                                        <LoginVendor />
                                    </div>
                    </div>
                {/* } */}


                {/*Division for User Logo for department user login page as well as Department user login Component imported  */}
                {this.state.radio === 'department' &&
                    <div>
                    <div style={{position:'absolute',
                            backgroundColor:'#eb3b95',
                            border:'3px solid  #eb3b95  ',
                            right:'295%',
                            margin:'10px',
                            top:'-30%',
                            borderRadius:'50px'}}>    
                                <img style={{borderRadius:'50px'}} height="50" width="50" src={userLogo}/> 
                                </div>
                                <div style={{
                                        backgroundColor:'#5793CE',
                                        height:'340px',
                                        width:'500px',
                                        position:'absolute',
                                        right:'220%',
                                        margin:'10px',
                                        top:'-10%',
                                        borderRadius:'20px',
                                        zIndex: 1,
                                        border:'2px solid #1f3f7a'}}>
                                        <LoginUser />
                                    </div>
                    </div>
                }

                   {/*Division for User Logo for management login page as well as Management login Component imported  */}
                   {this.state.radio === 'management' &&
                    <div>
                    <div style={{position:'absolute',
                            backgroundColor:'#eb3b95',
                            border:'3px solid  #eb3b95  ',
                            right:'295%',
                            margin:'10px',
                            top:'-30%',
                            borderRadius:'50px'}}>    
                                <img style={{borderRadius:'50px'}} height="50" width="50" src={userLogo}/> 
                                </div>
                                <div style={{
                                        backgroundColor:'#5793CE',
                                        height:'340px',
                                        width:'500px',
                                        position:'absolute',
                                        right:'220%',
                                        margin:'10px',
                                        top:'-10%',
                                        borderRadius:'20px',
                                        zIndex: 1,
                                        border:'2px solid #1f3f7a'}}>
                                        <LoginAdmin />
                                    </div>
                    </div>
                }
             </div>
         )
     }
 }

 export default LoginOptions