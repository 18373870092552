import React,{Component} from 'react';
import {Button, Icon, Table} from 'antd';
import {fetchLevelsList} from "../../../actions/vendorsAction";
import {bindActionCreators} from "redux";
import {updateVendor} from "../../../actions/vendorsAction";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

class manageLevels extends Component{


    fetchLevelsList = ()=>{
        this.props.fetchLevelsList({
            token:this.props.token
        })
      };
      
       componentDidMount() {
           this.fetchLevelsList();
       }

    render(){
        console.log("Manage-level list",this.props.vendor.levelsList)
        const columns = [
            { title: 'Department Name', dataIndex: 'departmentName', key: 'levelName' },
            { title: 'Number of levels', dataIndex: 'numberOfLevels', key: 'numberOfLevels' },
            {
                title: 'Edit',
                dataIndex: '',
                key: 'x',
                render: (code,department) =>
                    <div>
                        {console.log("record",department)}
                        <span>
                              <Link to={{pathname : '/admin-dashboard/add-levels',department:department}}>
                                    <Icon type="edit" theme="outlined" />
                                </Link>
                      </span>
                    </div>
            },

        ];


        return(
            <div>
                <Table
                    scroll={{ x: 'max-content' }}
                    columns={columns}
                    rowKey={record => record._id}
                    dataSource={this.props.vendor.levelsList}
                />,
            </div>
        )
    }

    handleEdit() {
        console.log('click to edit')
    }

    handleDelete() {
        console.log('click to delete')
    }
}

function mapStateToProps(state) {
    return {
        register: state.register,
        vendor: state.vendorReducer,
        token: state.auth.token,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ fetchLevelsList: fetchLevelsList }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(manageLevels)


