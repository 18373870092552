import React,{Component} from 'react';
import { Table,Button,Row,Col,Icon,Divider } from 'antd';
import {bindActionCreators} from "redux";
import { usersList, invoicesList, fetchLevelsList} from "../../../actions/vendorsAction";
import {fetchUsersList} from '../../../actions/userAction'
import UsersDashboard from '../dashboard/usersDashboard';
import LevelsDashboard from '../dashboard/levelsDashboard';
import InvoicesDashboard from '../dashboard/invoicesDashboard'
import {connect} from "react-redux";

 
class Dashboard extends Component{
    fetchLevelsList = ()=>{
        this.props.levelsList2({
            token:this.props.token
        })
      };

      fetchInvoicesList = ()=>{
        this.props.invoicesList2({
            token:this.props.token
        })
      };

      fetchUsersList = ()=>{
        this.props.usersList2({
            token: this.props.token,
            
        })
    };
      
       componentDidMount() {
           this.fetchLevelsList();
           this.fetchInvoicesList();
           this.fetchUsersList();

       }
    render(){
        return(
        <div>
                    <Row>
                        {/* Users Count */}
                        {/* <Col style={{backgroundColor:'#FFF',height:'80px',width:'250px',borderRadius:'10px',boxShadow:'0 4px 10px 0 grey'}} xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
                            <Col style={{backgroundColor:'#4caf50',float:'left',height:'80px',width:'80px',borderRadius:'10px',paddingLeft:'25px'}}>
                                <div>
                                <Icon style={{fontSize:'30px',paddingRight:'5px',paddingTop:'20px',color:'#FFF'}}  type="usergroup-add" />
                                </div>
                            </Col>   
                                <div style={{textAlign:'center',paddingTop:'15px',color:'#000'}}>
                                    USERS
                                </div>
                            <div style={{textAlign:'center',paddingTop:'15px',color:'#000'}}>
                            <strong style={{fontWeight:'bold',fontSize:'20px'}}>
                                {this.props.usersCount}
                                </strong>
                            </div>

                        </Col> */}

                            {/* Invoices Count */}
                        <Col style={{backgroundColor:'#FFF',height:'80px',width:'250px',borderRadius:'10px',boxShadow:'0 4px 10px 0 grey'}} xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
                            <Col style={{backgroundColor:'#c2185b',float:'left',height:'80px',width:'80px',borderRadius:'10px',paddingLeft:'25px'}} >
                                <div>
                                <Icon style={{fontSize:'30px',paddingRight:'5px',paddingTop:'20px',color:'#FFF'}}  type="file-text" />
                                </div>
                            </Col>   
                            <div style={{textAlign:'center',paddingTop:'15px',color:'#000'}}>
                                INVOICES
                            </div>
                            <div style={{textAlign:'center',paddingTop:'15px',color:'#000'}}>
                                <strong style={{fontWeight:'bold',fontSize:'20px'}}>
                                {this.props.invoicesCount}
                                </strong>
                            </div>   
                        </Col>
                        
                        {/* Levels count */}
                        {/* <Col style={{backgroundColor:'#FFF',height:'80px',width:'250px',borderRadius:'10px',boxShadow:'0 4px 10px 0 grey'}} xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
                        <Col style={{backgroundColor:'#512da8',float:'left',height:'80px',width:'80px',borderRadius:'10px',paddingLeft:'25px'}} >
                            <div>
                            <Icon style={{fontSize:'30px',paddingRight:'5px',paddingTop:'20px',color:'#FFF'}}  type="bar-chart" />
                            </div>
                        </Col>
                        <div style={{textAlign:'center',paddingTop:'15px',color:'#000'}}>LEVELS</div>
                            <div style={{textAlign:'center',paddingTop:'15px',color:'#000'}}>
                            <strong style={{fontWeight:'bold',fontSize:'20px'}}>
                                {this.props.levelsCount}
                                </strong>    
                            </div>          
                        </Col> */}
                </Row>
                <Divider></Divider>
            <Divider></Divider>

     {/*<div style={{width:'1000px',height:'50px'}}>*/}
     {/*                 /!* Users Text *!/*/}
     {/*       <div style={{width:'400px',height:'50px',float:'left'}}>*/}
     {/*           <h3>USERS</h3>*/}
     {/*       </div>*/}
     {/*                   /!* Invoices Text *!/*/}
     {/*       <div style={{width:'400px',height:'50px',float:'right'}}>*/}
     {/*           <h3>INVOICES</h3>*/}
     {/*       </div>*/}
     {/* </div>*/}

     {/*          /!* User Table *!/*/}
     {/*          <div style={{width:'1000px',height:'400px'}}>*/}
     {/*     <div style={{borderRadius:'10px',boxShadow:'0 4px 10px 0 #F3F3F1',width:'400px',backgroundColor:'#F3F3F1',height:'250px',float:'left'}}>*/}
     {/*       <UsersDashboard/>*/}
     {/*     </div>*/}
     {/*     /!* Invoices Table *!/*/}
     {/*     <div style={{borderRadius:'10px',boxShadow:'0 4px 10px 0 #F3F3F1',width:'400px',backgroundColor:'#F3F3F1',height:'250px',float:'right'}}>*/}
     {/*     </div>*/}
     {/* </div>*/}
    </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        register: state.register,
        vendor: state.vendorReducer,
        users:state.usersReducer,
        token: state.auth.token,
        levelsList: state.vendorReducer.levelsList,
        usersList: state.users.usersList,
        invoicesList:state.vendorReducer.invoicesList,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({levelsList2:fetchLevelsList,usersList2:fetchUsersList,invoicesList2:invoicesList}, dispatch)
}

export default connect(mapStateToProps,matchDispatchToProps)(Dashboard)