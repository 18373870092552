import React, { Component } from 'react'
import {
    Form,
    Input,
    Select,
    Upload,
    Button,
    Icon,
    AutoComplete,
    notification,
    DatePicker,
    Collapse
  } from 'antd';
  import moment from "moment";
  import { connect } from 'react-redux';
  import {compose} from "recompose";
  import { withRouter, Link } from "react-router-dom";
  import { bindActionCreators } from 'redux';
  import {savePurchaseOrder} from '../../../actions/userAction'
  import {vendorsList} from "../../../actions/vendorsAction";
  import ItemInput from './itemsContainer'
  
  const { Panel } = Collapse;
  const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

//   const { Option } = Select;
// const children = [];
// for (let i = 10; i < 36; i++) {
//   children.push(<Option key={}>{i.toString(36) + i}</Option>);
// }

function handleChange(value) {
    console.log(`selected...// ${value}`);
  }
class addPurchaseOrder extends Component {
    state = {
        amount:(this.props.location.purchaseOrder && this.props.location.purchaseOrder.totalAmount )?this.props.location.purchaseOrder.totalAmount:0 ,
        editAmount:0,
        calculate:0,
        confirmDirty: false,
        autoCompleteResult: [],
        file:null,
        count:0,
        homes: [],
        // startDate: moment()._d,
        startDate: moment().startOf('day'),
        items:this.props.location.purchaseOrder && this.props.location.purchaseOrder.itemsDetails ? this.props.location.purchaseOrder.itemsDetails : [{itemName:null, partNo:null,price:null,taxRate:null,quantity:null,amount:null,description:null}],
        collapsedKey:'',
        totalAmountAdd:0,

        // Edit purchase order states
        purchaseOrderId:(this.props.location.purchaseOrder && this.props.location.purchaseOrder._id),
        vendorName: (this.props.location.purchaseOrder && this.props.location.purchaseOrder.vendor.vendorEmail),
        purchaseOrderNo:(this.props.location.purchaseOrder && this.props.location.purchaseOrder.purchaseOrderNo),
        createdDateEdit:(this.props.location.purchaseOrder && this.props.location.purchaseOrder.createdDate),
        shippingAddress:(this.props.location.purchaseOrder && this.props.location.purchaseOrder.shippingAddress),
        deliveryAddress:(this.props.location.purchaseOrder && this.props.location.purchaseOrder.deliveryAddress),
        // totalAmount:(this.props.location.purchaseOrder && this.props.location.purchaseOrder.totalAmount )?this.props.location.purchaseOrder.totalAmount:0 ,
        itemsList:(this.props.location.purchaseOrder && this.props.location.purchaseOrder.itemsDetails)
      };



      handleConfirmBlur = e => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
      };
        compareToFirstPassword = (rule, value, callback) => {
            const { form } = this.props;
            if (value && value !== form.getFieldValue('vendorPassword')) {
                callback('Two passwords that you enter is inconsistent!');
            } else {
                callback();
            }
        };

        // validate password  function
  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };


  handleChange = (e,idx,type) => {
      console.log("Params",e,idx)
      console.log("events===",e)
      if(e && e.target){
          if (["itemName", "partNumber", "price","taxRate","quantity", "amount","description"].includes(e.target.className) ) {
              let items = [...this.state.items]
              items[e.target.dataset.id][e.target.className] = e.target.value

              if(items[idx] && items[idx].quantity ){
                  items[idx].amount =items[idx].quantity*items[idx].price +(items[idx].quantity*items[idx].price*items[idx].taxRate)/100;
                  console.log("Amount",items[idx].amount)
              }
              this.setState({ items }, () => this.addAmount());
          } else {
              this.setState({ [e.target.name]: e.target.value })
          }
      }else{
          let items = [...this.state.items]
          console.log("Items in state",items[idx])
          console.log("Items of name",...this.state.items);
          if(type==='itemName'){
              items[idx].itemName = e;
              console.log("itemsName",items[idx].itemName)
              this.props.itemsList.map(x=>{
                  if(x._id===e){
                      items[idx].partNumber = x.partNumber;
                      items[idx].price = x.price;
                      items[idx].taxRate= x.taxRate;
                  }
              })
          }
          if(type==='taxRate'){
            items[idx].taxRate = e;
          }
          this.setState({
              items : items
          })
          console.log("State......",this.state,items.itemName)
      }
  };




    addItems = (e) => {
    this.setState((prevState) => ({
      items: [...prevState.items, {itemName:"", partNumber:"",price:"",taxRate:"",quantity:"",amount:0,description:""}],
    }));
  }



  onDateChange = (date, dateString) => {
    console.log("date is",date,dateString);
    var startDate = date;
    console.log("start date is",startDate);
        this.setState({
            startDate: startDate,
        });
        console.log("selected date is",this.state.startDate)

};

   onGstChange(value) {
    console.log(`selected ${value}`);
  }

 callback = (key) =>  {
     let keyValue = key;
    console.log("key is in function",keyValue);
    this.setState({collapsedKey: keyValue});
  }

  addAmount=()=>{
    let amount = 0
    this.state.items.map(item=>{
        amount = parseInt(amount) + parseInt(item.amount)
    })
    this.setState({
        amount:amount
    })
    amount = 0
  }




  fetchItemsList(){
    this.props.updateMe()
}

componentDidMount(){
    this.fetchItemsList()
}
  

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {

      if (!err) {
        console.log('Received values of form: ', values,this.state.items);
        console.log("created by admin on Submit",vendorsList);
        console.log("created date...",values.createdDate)
        let token = this.props.token;
        let status = 'FRESH'
        let itemsDetails = this.state.items
        // let adminId = null;

        // if(this.props.allVendorData){
        //     this.props.allVendorData.map((vendor =>{
        //         if(vendor._id === values.vendorName){
        //             adminId = vendor.createdByAdmin
        //         }

        //     }))
        // }
        // console.log("AdminID",adminId)
        this.props.savePurchaseOrder({
            vendorName:values.vendorName,
            purchaseOrderId:this.state.purchaseOrderId,
            // adminId:adminId,
            purchaseOrderNo:values.purchaseOrderNo,
            shippingAddress:values.shippingAddress,
            deliveryAddress:values.deliveryAddress,
            createdDate:values.createdDate.startOf('day').valueOf(),
            itemDetails:values.itemDetails,
            quantity:values.quantity,
            price:values.price,
            gstRate:values.gstRate,
            totalAmount:values.totalAmount,
            status,
            token,
            itemsDetails           
        }
        )
          this.props.history.push("/user-dashboard/viewPurchaseOrder")


      }
    });
  };

  setFinalAmount=()=>{
    console.log("calculate in state",this.state);

    let finalCal = null
    console.log("items selected in render",this.state.items.map((items=>{
        console.log("items in .map",items.taxRate)
        let calculate = (items.price * items.quantity)
        console.log("calculate is",calculate)
            finalCal = calculate
    })))
    if(finalCal > 0){
        this.setState({
            calculate:finalCal
        })
    }
    console.log("calculated val",this.state.calculate)
  }


    render(){

        let {owner, description, items} = this.state
        console.log("Add Purchase Order State",this.state);
        console.log("Add Purchase Order Props",this.props);
        console.log("Purchase order details onClick edit",this.props.location.purchaseOrder)
        console.log("Purchase order id on edit click",this.state.purchaseOrderId)
        console.log("Items on edit click",this.state.itemsList)
        console.log("created date",this.state.createdDate)
        console.log('all items...',this.state.items)

        const { Option } = Select;
        const children = [];
        var newDates = moment().format('LL')
        console.log("date is",newDates)
        console.log("start date in state is",this.state.startDate)
        let vendorsList = [];
        console.log('vendors List in add Purchase Order',this.props.allVendorData);

        if(this.props.allVendorData){
            this.props.allVendorData.map((vendor =>{
                let opt = {
                    email:vendor.vendorEmail,
                    key:vendor._id,
                    createdByAdmin:vendor.createdByAdmin
                };
                console.log("vendors list and vendors in map",opt);
                vendorsList.push(opt)
            }))
        }

        let adminIdOptions = [];
        if(this.props.allVendorData){
            this.props.allVendorData.map((vendor =>{
                let opt = {
                    adminId:vendor.createdByAdmin,
                };
                console.log(".map",opt);
                adminIdOptions.push(opt)
            }))
        }
        console.log('adminID...',adminIdOptions)

        vendorsList.map((vendor =>{
            console.log("key is...",vendor.key)
            children.push(<Option key={vendor.key}>{vendor.email}</Option>);
        }))
          
        console.log("vendorsList = []",vendorsList)
        const { getFieldDecorator } = this.props.form;
        console.log('vendors List in add Purchase Order',this.props.allVendorData)
        
        const First = (
            <div style={{display:'flex',width:'100%',justifyContent:'space-between'}}>
                            <div style={{width:'80%',marginRight:'2%'}}>
                                {/* Add vendor Name block */}
                                { !this.state.vendorName &&
                                      <div>
                                        {/* Choose Vendor Field */}
                                            <Form.Item  label="Choose Vendor">
                                                {getFieldDecorator('vendorName', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Please input your vendor name',
                                                        },
                                                    ],
                                                })( <Select  style={{ width: '100%' }} placeholder="Choose Vendor" onChange={handleChange}>
                                                {children}
                                            </Select>)}
                                            </Form.Item>
                                     </div>
                                }


                                      {/* Edit vendor Name block */}
                                      { this.state.vendorName &&
                                      <div>
                                        {/* Choose Vendor Field */}
                                            <Form.Item  label="Choose Vendor">
                                                {getFieldDecorator('vendorName', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Please input your vendor name',
                                                        },
                                                    ],
                                                    initialValue: this.state.vendorName,
                                                })( <Select  style={{ width: '100%' }} placeholder="Choose Vendor" onChange={handleChange}>
                                                {children}
                                            </Select>)}
                                            </Form.Item>
                                     </div>
                                }
                          
                            </div> 


                        <div style={{width:'80%',marginRight:'2%'}}>
                            {/* Add Purchase Order No Block */}
                            { !this.state.purchaseOrderNo &&
                                   <div>
                                   {/* Purchase Order No Field */}
                                   <Form.Item label="Purchase Order No">
                                       {getFieldDecorator('purchaseOrderNo', {
                                           initialValue:this.props.purchaseOrderNumber+1,
                                           rules: [
                                               { required: true, message: 'Please input your Purchase Order No!' },
                                           ],
                                       })(<Input type="number"  placeholder="insert Purchase Order No" style={{ width: '100%' }} />)}
                                   </Form.Item>
                               </div>
                            }

                              {/* Edit Purchase Order No Block */}
                              {this.state.purchaseOrderNo &&
                                   <div>
                                   {/* Purchase Order No Field */}
                                   <Form.Item label="Purchase Order No">
                                       {getFieldDecorator('purchaseOrderNo', {
                                           rules: [
                                               { required: true, message: 'Please input your Purchase Order No!' },
                                           ],
                                           initialValue:this.state.purchaseOrderNo,

                                       })(<Input type="number"  placeholder="insert Purchase Order No" style={{ width: '100%' }} />)}
                                   </Form.Item>
                               </div>
                            }              
                            </div>


                            <div style={{width:'80%',marginRight:'2%'}}>
                                        <div>
                                          {/* Created Date Field */}
                                    <Form.Item label="Choose Date" hasFeedback>
                                        {getFieldDecorator('createdDate', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please input your date',
                                                },
                                            ],
                                            initialValue: moment(this.state.createdDateEdit),	
                                        })(    <DatePicker  format={'DD-MM-YYYY'}  onChange={onCreatedDateChange} />
                                            )}
                                    </Form.Item>
                                        </div>
                            </div>
            </div>
          );

          const Second = (
            <div style={{display:'flex',width:'100%',justifyContent:'space-between'}}>
            <div style={{width:'80%',marginRight:'2%'}}>

                {/* Add Shipping Address Block */}
                {!this.state.shippingAddress &&
                            <div>
                            {/* Shipping Address Field */}
                            <Form.Item label="Shipping Address">
                                 {getFieldDecorator('shippingAddress', {
                                     rules: [
                                         {
                                             required: true,
                                             message: 'Please input your Shipping Address',
                                         },
                                     ],
                                 })(<textarea style={{padding:'5px',borderRadius:'10px',border:'1px solid #7D7D7D',height:'80px',width:'190px'}}
                                 placeholder="insert your shipping Address" />)}
                             </Form.Item>
                         </div>
                }

                {/* Edit Shipping Address Block */}

                        {this.state.shippingAddress &&
                            <div>
                            {/* Shipping Address Field */}
                            <Form.Item label="Shipping Address">
                                 {getFieldDecorator('shippingAddress', {
                                     rules: [
                                         {
                                             required: true,
                                             message: 'Please input your Shipping Address',
                                         },
                                     ],
                                     initialValue:this.state.shippingAddress
                                 })(<textarea style={{padding:'5px',borderRadius:'10px',border:'1px solid #7D7D7D',height:'80px',width:'190px'}}
                                 placeholder="insert your shipping Address" />)}
                             </Form.Item>
                         </div>
                }
    
            </div> 


        <div style={{width:'80%',marginRight:'2%'}}>

            {/* Add Delivery Address Block */}
            {!this.state.deliveryAddress &&
                  <div>
                  {/* Add Billing Field */}
                  <Form.Item label="Billing Address">
                      {getFieldDecorator('deliveryAddress', {
                          rules: [
                              {
                                  required: true,
                                  message: 'Please input your delivery Address',
                              },
                          ],
                      })(<textarea style={{padding:'5px',borderRadius:'10px',border:'1px solid #7D7D7D',height:'80px',width:'190px'}} placeholder="Please insert your delivery Address" />)}
                  </Form.Item>
             </div>
            }

                 {/* Edit Delivery Address Block */}
                 {this.state.deliveryAddress &&
                  <div>
                  {/* Edit Delivery Field */}
                  <Form.Item label="Billing Address">
                      {getFieldDecorator('deliveryAddress', {
                          rules: [
                              {
                                  required: true,
                                  message: 'Please input your delivery Address',
                              },
                          ],
                          initialValue:this.state.deliveryAddress
                      })(<textarea style={{padding:'5px',borderRadius:'10px',border:'1px solid #7D7D7D',height:'80px',width:'190px'}} placeholder="Please insert your delivery Address" />)}
                  </Form.Item>
             </div>
            }
                      
            </div>
</div>
          );

          const Third = (
            <div style={{display:'flex',width:'100%',justifyContent:'space-between'}}>
            <div style={{width:'80%'}}>
            <div>
            <ItemInput purchaseOrderNumber={this.props.purchaseOrderNumber} items={items} onChange={this.handleChange} addAmout={this.addAmout}/>
            <Icon style={{marginBottom:'10%',marginLeft:'120%',fontSize:'30px',color: '#08c'}} onClick={this.addItems} type="plus-circle"/>
            </div>
            </div>
</div>
          )

        return(
      <div>

             <h3>{`${this.state.purchaseOrderId ? 'Update' : 'Add'} Purchase Order`}</h3>  

            <div style={{boxShadow:'0 4px 8px 0 rgba(0,0,0,0.2)',
                transition: '2.5s',
                width:'90%',
                borderRadius:'15px',
                alignItems:'center',
                backgroundColor:'#FFF'
            }}>
             <Form  onSubmit={this.handleSubmit} onChange={this.handleChange}>
                 <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginBottom:20, padding: '21px'}}>
                    <div style={{display:'flex',width:'100%',justifyContent:'space-around'}}>
                               <div>
                                </div>
                                <div style={{width:'80%',marginRight:'2%'}}> 
                                <Collapse onChange={this.callback} bordered={true}>
                                        <Panel header="Basic Details" key="1">
                                        {First}
                                        </Panel>

                                        <Panel header="Shipping Details" key="2">
                                        {Second}
                                        </Panel>
                                        <Panel header="Item Details" key="3">
                                        {Third}
                                        </Panel>
                                    </Collapse>
                                </div>
   
                    </div>
                    
                {/* total amount */}
                <div style={{display:'flex',width:'100%',justifyContent:'space-between'}}>
                    <div style={{width:'80%',marginRight:'2%'}}>
                        {/* Add Totoal Amount */}
                        {!this.state.totalAmount &&
                               <div>
                               <Form.Item label="Total Amount">
                                  {getFieldDecorator('totalAmount', {
                                      rules: [
                                          { required: true, message: 'Please input your total amount!' }
                                      ],
                                      initialValue: this.state.amount,
                                  })(<Input type="number"  placeholder="Please insert total amount" style={{ width: '60%' }} />)}
                              </Form.Item>
                          </div>
                        }
                           


                              {/* Edit Totoal Amount */}
                              {this.state.totalAmount &&
                              <div>
                              <Form.Item label="Total Amount Edited">
                                  {getFieldDecorator('totalAmount', {
                                      rules: [
                                          { required: true, message: 'Please input your total amount!' }
                                      ],
                                      initialValue: this.state.amount,
                                  })(<Input type="number"  placeholder="Please insert total amount" style={{ width: '60%' }} />)}
                              </Form.Item>
                         </div>
                        }
                  
                    </div>

                    <div style={{width:'80%',marginTop:'5%',marginRight:'2%'}}>
                    <div>
                    <Form.Item>
                            <Button style={{marginLeft:'80%'}}  type="primary" htmlType="submit">
                                Save
                            </Button>
                    </Form.Item>
                     </div>
                    </div>
                </div>
                 </div> 
                </Form>
            </div>
      </div>
        )
    }
}

function mapStateToProps(state) {
  return {
    token:state.auth.token,
    pendingVendorsList: state.vendorReducer.pendingVendorsList,
  };
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({savePurchaseOrder:savePurchaseOrder, vendorsList:vendorsList}, dispatch)
}


function onCreatedDateChange(value, selectedOptions) {
    console.log(value, selectedOptions);
}

const WrappedAddPurchaseOrder = Form.create()(addPurchaseOrder)



export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)(WrappedAddPurchaseOrder);
