import React, {Component} from "react";
import {Button,notification} from "antd";
import {Link, Redirect} from "react-router-dom";
import {bindActionCreators} from "redux";
import {tryAlreadyLoggedInProfile} from '../actions/vendorsAction'
import {vendorRegister} from "../actions/vendorsAction";
import {connect} from "react-redux";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message,
      description,
    });
  };

class RouteMe extends Component{

    componentDidMount(){
        this.props.tryAlreadyLoggedInProfile()
    }
    render() {
        console.log("token at routeme.js",this.props.token)

         if(this.props.isLoggedIn){
            if(this.props.status==='pending'){
                console.log("pending vendor");
    
                 openNotificationWithIcon(
                    'error',
                    'Your Account yet to be approved',
                    `Please contact Admin!`
                  )
                  return ( <Redirect to={'/landingPage'} /> );
    
                }
             if(this.props.status==='REJECTED'){
                 console.log("REJECTED vendor");

                 openNotificationWithIcon(
                     'error',
                     'Your Account is Rejected',
                     `Please contact Admin!`
                 )
                 return ( <Redirect to={'/landingPage'} /> );

             }

             else if(this.props.userPermissions.indexOf('vendor') > -1){
                        return ( <Redirect to={'/vendorDashboard/dashboard'} /> );
                    
            }
            else if (this.props.userPermissions.indexOf('admin') > -1){
                return ( <Redirect to={'/admin-dashboard/dashboard'} /> );
            }
            return ( <Redirect to={'/user-dashboard/pending'} /> );
        }
        if(!this.props.isLoggedIn){
            return ( <Redirect to={'/landingPage'} /> );
        }
    
        return(
            <div>
                <Link to={'/loginVendor'}>
                    <Button id={'goToLogin'} style={{display:'none'}}> Login</Button>
                </Link>
            </div>
        )
    }
}



function mapStateToProps(state) {
    let permissions = [];
    try {
        permissions = state.auth.meta.data.permissions;
    }
    catch(ex){
        console.log("permissions not proper in login yet");
    }

    return {
        isLoggedIn: state.auth.isLoggedIn,
        userPermissions: permissions,
        token: state.auth.token,
        loading: state.auth.loading,
        meta: state.auth.meta,
        status:state.auth.status,
    }

}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ vendorRegister: vendorRegister,tryAlreadyLoggedInProfile:tryAlreadyLoggedInProfile }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(RouteMe)