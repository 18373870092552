import React, { Component } from 'react'
import {Button, Table, List, Icon, Col, Pagination, Row, Form, Input, Cascader, Modal,notification} from 'antd';
import {Link} from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {compose} from "recompose";
import {updatePurchaseOrder,deletePurchaseOrder} from "../../../actions/userAction";
import moment from "moment";
class viewPurchaseOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pagination: {},
            userDevices: {},
            page : 1,
            visible: false  ,
            vendorName:null,
            poId:null
        };
        this.toggleMenu = this.toggleMenu.bind(this);

    }
  

    componentDidMount(){
        this.fetchPurchaseOrder()
    }

    fetchPurchaseOrder(){
        this.props.updateMe()
    }
    
    handleConfirmBlur = e => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
      };

      toggleMenu(purchaseOrderId) {
        this.setState({visible: !this.state.visible})
        console.log("purchaseOrderId",purchaseOrderId)

    }


          //onClick form submit function
    handleSubmit = e => {
        e.preventDefault();
     
        this.props.form.validateFieldsAndScroll((err, values) => {
          if (!err) {
            console.log('Received values of form: ', values);
            // this.props.vendorRegister(  
            //   formData
            // );
          }
        });
      };

      openNotificationApprovePo = () => {
        notification.open({
          message: 'Approve Purchase Order',
          description:
            'Purchase Order Approve Successfully',
          icon: <Icon style={{color:'#388E3C'}} type="check" style={{ color: '#4C9925' }} />,
        });
      };

      
 openNotificationRejectPo = () => {
    notification.open({
      message: 'Reject Purchase Order',
      description:
        'Purchase Order Rejected',
      icon: <Icon style={{color:'#b71c1c'}} type="close" style={{ color: '#FB1313' }} />,
    });
  };

    handleApprove(purchaseOrderId) {
        console.log("PurchaseOrderID onApproved Clicked",purchaseOrderId)
        this.props.updatePurchaseOrder({
            token:this.props.token,
            status:'APPROVED',
            purchaseOrderId:purchaseOrderId
        });
        this.openNotificationApprovePo()
        this.props.updateMe()
    }


    showModal = (PurchaseId) => {
        let vendorName = null;
        this.props.purchaseOrderList.map(po=>{
            if(po._id===PurchaseId){
                vendorName = po.vendor.companyName
            }
        })
        this.setState({
            visible: true,
            vendorName:vendorName,
            poId:PurchaseId
        });


    };

    handleOk = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
        this.props.updatePurchaseOrder({
            description:this.state.description,
            token:this.props.token,
            status:'REJECTED',
            purchaseOrderId:this.state.poId
        });
        this.openNotificationRejectPo()
        this.props.updateMe()
    };

    handleCancel = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

// Delete Purchase Order
    handleDelete = (purchaseOrderId) => {
        this.props.deletePurchaseOrder({
            token:this.props.token,
            purchaseOrderId:purchaseOrderId,
        })
        console.log("purchaase order Id in",purchaseOrderId);
        this.props.updateMe()
        this.props.updateMe()

    };

    handleChange(event) {
        this.setState({description: event.target.value});
    }
    render(){
        const { getFieldDecorator } = this.props.form;
        let options = [];
        let optinsApproved = [];
        console.log("user permission in......",this.props.permission);
        console.log("get PO status from admin dashboard",this.props.approvedStatus)
        console.log("purchase order in view purchase order",this.props.purchaseOrderList)
        console.log("user permission.....",this.props.permission)
        if(this.props.purchaseOrderList){
            this.props.purchaseOrderList.map((purchaseOrder=>{
                console.log('.map purchase order amount',purchaseOrder.totalAmount)
                console.log("created Date",purchaseOrder.createdDate);
                if(this.props.permission === 'user'){
                    options.push(purchaseOrder)
                }else if(this.props.permission === 'admin' && this.props.pendingStatus === 'FRESH' && purchaseOrder.status === 'FRESH'){
                    options.push(purchaseOrder)
                }
                else if(this.props.permission === 'admin' && this.props.approvedStatus === 'APPROVED' && purchaseOrder.status === 'APPROVED'){
                    options.push(purchaseOrder)
                    console.log("only approved view",purchaseOrder)
                }
                else if(this.props.permission === 'admin' && this.props.rejectedStatus === 'REJECTED' && purchaseOrder.status === 'REJECTED'){
                    options.push(purchaseOrder)
                    console.log("only rejected view",purchaseOrder)
                }
                // let createdDate = new Date(purchaseOrder.createdDate.toISOString().split('T')[0])
                // let opt = {
                //     createdDate:purchaseOrder.createdDate,
                //     purchaseOrderNo:purchaseOrder.purchaseOrderNo,
                //     companyName:purchaseOrder.vendor.companyName,
                //     amount:purchaseOrder.totalAmount,
                //     purchaseOrderId:purchaseOrder._id
                // }
                // options.push(opt)
            }))
            console.log('Purchase Order in....',options)

        }
        let columns = [
            {
                title: 'Created Date',
                dataIndex: 'createdDate',
                
                key: 'x',
                render:(record)=>{
                    console.log("created date record",record)
                    let newDate = new Date(record)
                    // console.log("newDate",newDate.toISOString().split('T')[0])
                    let dateFormat = newDate.toLocaleDateString("en-US")
                    console.log("dateFormat",dateFormat)
                    let convertedDate = moment(dateFormat).format('DD-MM-YYYY');
                    return(
                        <div>
                            {convertedDate}
                        </div>
                    )
                }
            },
            { title: 'Purchase Order No.', 
            dataIndex: 'purchaseOrderNo',
            
            },
            {
                title: 'Vendor Name',
                dataIndex: 'vendor.companyName',
                width:'12%'
                
            },
            {
                title: 'Status',
                dataIndex: 'status',
                width:'12%',
                render:(record)=>{
                    console.log("status.....",record)
                    return(

                        <div>
                            {
                                record === 'APPROVED' &&
                                <span style={{color:'green'}}>{record}</span>
                            }
                            {
                                record === 'REJECTED' &&
                                <span style={{color:'red'}}>{record}</span>
                            }

                            {
                                record === 'FRESH' &&
                                <span style={{color:'#000'}}>{record}</span>
                            }
                        </div>
                    )
                }
                
            },
            {
                title: 'Reason',
                dataIndex: 'description',
                width:'12%'
                
            },
            {
                title: 'Amount',
                dataIndex: 'totalAmount',
                width:'20%',
                render:(record)=>{
                    console.log("totalAmount....",record)
                    let totalAmount = record
                    let newTotalAmount = parseFloat(totalAmount).toFixed(2)
                    return(
                        <div>
                            {newTotalAmount}
                        </div>
                    )
                }

            },
            {
                title: 'Action',
                dataIndex: '',
                key: 'x1',
                render: (code,purchaseOrder) =>
                    <div>
                        {console.log("record,code....",purchaseOrder._id,purchaseOrder)}
                        {/* { purchaseOrderId = purchaseOrder._id} */}
                        <span>
                            <Row style={{left:`${this.props.permission === 'admin' ? '28%' : '5%'}`}} gutter={12}>
                                <Col style={{right:'32%'}} className="gutter-row" span={1}>
                                    <div className="gutter-box">
                                        <span>
                                        <Link to={{pathname : this.props.path,state:{purchaseOrder:purchaseOrder}}}>
                                    <Button  key={purchaseOrder._id}><Icon type="eye" theme="outlined" />View</Button>
                                    </Link>
                                    {this.props.permission === 'user' &&
                                    <div>
                                             <Link to={{pathname : '/user-dashboard/addPurchaseOrder', purchaseOrder:purchaseOrder}}>
                                          <Button style={{marginTop:'10px',width:'85px',backgroundColor:'#549914',color:'#FFF'}}  key={purchaseOrder._id}><Icon type="edit" theme="outlined" />Edit</Button>
                                          </Link>
                                          
                                          <Button type={'danger'} style={{marginTop:'10px',width:'85px'}} onClick={()=>this.handleDelete(purchaseOrder._id)}><Icon type="delete" theme="outlined" />Delete</Button>
                                    </div>
                                     
                                    }
                                  
                                        </span>
                                    </div>
                                 </Col>
                                 {this.props.permission === 'admin' && this.props.pendingStatus === 'FRESH' && purchaseOrder.status === 'FRESH' &&
                                 <div>
                                        <Col style={{left:'7%'}} className="gutter-row" span={8}>
                                     <div className="gutter-box">
                                     <Button  key={purchaseOrder._id} onClick={() => this.handleApprove(purchaseOrder._id)} style={{backgroundColor:'#549914',color:'#FFF'}}><Icon type="check" />Approve</Button>
                                    </div>
                                  </Col>
                                  <Col style={{left:'30%'}} className="gutter-row" span={8}>
                                      <div className="gutter-box">
                                        <Button  key={purchaseOrder._id} onClick={() => this.showModal(purchaseOrder._id)} type={'danger'} style={{color:'#FFF'}}><Icon type="close" />Reject</Button>
                                      </div>
                                   </Col>
                                 </div>
                              
                                 } 
                                </Row>
                                {this.state.visible &&  
                                <Row>
                                    <Col>
                                    <Form  onSubmit={this.handleSubmit}>
                                    {/* <Form.Item  label="Description">
                                        {getFieldDecorator('rejectDescription', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please input your reject description',
                                                },
                                            ],
                                            initialValue: `${purchaseOrder._id}`
                                        })(<Input placeholder="Please Insert reject description" />)}
                                    </Form.Item> */}
                                    </Form>
                                    </Col>
                                 </Row>
                                }

                      </span>
                        <div>
                            <Modal
                                title="Are you sure you want to reject the advoice?"
                                visible={this.state.visible}
                                onOk={this.handleOk}
                                onCancel={this.handleCancel}
                            >
                                <Form layout={formLayout}>
                                    <Form.Item label="Vendor Name" {...formItemLayout}>
                                        <Input placeholder="Enter Vendor Name" value={this.state.vendorName}/>
                                    </Form.Item>
                                    <Form.Item label="Description" {...formItemLayout}>
                                        <Input placeholder="Enter your Reason." onChange={(x) => this.handleChange(x)}/>
                                    </Form.Item>
                                </Form>
                            </Modal>
                        </div>
                    </div>
            }
        ];

        const { formLayout } = this.state;
        const formItemLayout =
            formLayout === 'horizontal'
                ? {
                    labelCol: { span: 4 },
                    wrapperCol: { span: 14 },
                }
                : null;
        const buttonItemLayout =
            formLayout === 'horizontal'
                ? {
                    wrapperCol: { span: 14, offset: 4 },
                }
                : null;

        return(
            <div>
                { this.props.permission === 'user' &&
                    <h3>Purchase Orders</h3>
                }

                { this.props.permission === 'admin' && this.props.pendingStatus === 'FRESH' &&
                    <h3>Fresh Purchase Orders</h3>
                }

                { this.props.permission === 'admin' && this.props.approvedStatus === 'APPROVED' &&
                    <h3>Approved Purchase Orders</h3>
                }

                { this.props.permission === 'admin' && this.props.rejectedStatus === 'REJECTED' &&
                    <h3>Rejected Purchase Orders</h3>
                }
                
                    <Table
                        scroll={{ x: 'max-content'}}
                        rowKey={record => record._id}
                        columns={columns}
                        dataSource={options}
                        pagination={this.state.pagination}
                    />
            </div>
        )
    }
}






function mapStateToProps(state) {
    let userId = null;
    userId = (state.auth.meta && state.auth.meta.data && state.auth.meta.data._id ) ? state.auth.meta.data._id : null;
  return {
      register: state.register,
      isRegistration: state.register.isRegistration,
      InvoicesList:state.vendorReducer.invoicesList,
      token:state.auth.token,
      user:state.auth.meta,
      vendorId:userId,
      levelsList: state.vendorReducer.levelsList
  };
}

const wrappedPurchaseOrder = Form.create()(viewPurchaseOrder)


function mapDispatchToProps(dispatch) {
  return bindActionCreators({ updatePurchaseOrder:updatePurchaseOrder,deletePurchaseOrder:deletePurchaseOrder }, dispatch)
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)(wrappedPurchaseOrder);