import * as types from '../constants/actionsTypes';
import axios from 'axios';
import AppConfig from '../constants/appConfig';
import {notification,Icon} from 'antd';

function goToDashboard() {
    document.getElementById('goToVendorDashboard').click()
}


const openNotificationWithIcon = (type, message, description) => {
  notification[type]({
      message,
      description,
  });
};



// Fetch all purchase order lists
export function getPurchaseOrder(params={}){
  console.log("Fetching Purchase Order in User Action");
  console.log("purchase order",params);
  return(dispatch=>{
      dispatch({type:types.RESPONSE_PURCHASE_ORDER_FETCH,data:{}});
      const config = {
          headers: {
              'content-type': 'application/json',
              'Authorization': params.token,
          }
      };
      axios.get(AppConfig.baseAPIUrl+'/api/purchaseOrder/admin/purchaseOrders',  config)
      .then((result) => {
          const data = result.data && result.data.data ? result.data.data : {};
          console.log("AdminActions: FetchPurchaseOrder: Purchase order result success: ", data);
          dispatch({ type: types.RESPONSE_PURCHASE_ORDER_FETCH, data: { error: false, data, remember: params.remember } });
          return data
      })
      .catch((error) => {
          let data = { error: true };
          try {
              data['errors'] = error.response.data.errors;
          }
          catch (ex) {
              data['errors'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
          }
          dispatch({ type: types.RESPONSE_PURCHASE_ORDER_FETCH, data: {} });
          return data

      });
  })
}


export function adminLogin(params={}){
    console.log(params);
    return(dispatch=>{
        dispatch({type:types.AUTH_REQUEST_LOGIN,data:{}});
        const config = {
            headers: {
                'content-type': 'application/json'
            }
        };
        axios.post(AppConfig.baseAPIUrl+'/api/auth/admin/signin', {
            email: params.email,
            password: params.password,
        }, config)
            .then((result) => {
                const data = result.data && result.data.data && result.data.data._id ? result.data.data : {};
                console.log("authActions: loginUser: Login user result success: ", data);
                saveProfileToLocalStore(data);
                dispatch({ type: types.AUTH_RESPONSE_LOGIN, data: { error: false, data, remember: params.remember } });
                goToDashboard()
            })
            .catch((error) => {
                let data = { error: true };
                try {
                    data['errors'] = error.response.data.errors;
                    // console.log("authActions: tryLogin: sign-in User result failed: ", error);
                    // console.log("Error response: ", error.response);
                    // console.log("Error response body: ", error.response.body);
                    openNotificationWithIcon('error', 'Login Failed!', 'Please try again!');
                }
                catch (ex) {
                    data['errors'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
                }
                // openNotificationWithIcon('error', 'Login Failed!', 'Please try again!');

                dispatch({ type: types.LOGIN_ADMIN, data: data });
            });
    })
}

export function tryAlreadyLoggedInProfile(){
    console.log("trying already logIn")
    return (dispatch => {
      let data = loadProfileFromLocalStore();
      if(data && data.length){
        try {
          data = JSON.parse(atob(data));
          return dispatch({type: types.AUTH_RESPONSE_LOGIN, data: { error: false, data: data }});
        }
        catch(ex){
          console.log("Error loading profile: ", ex, data);
        }
      }

    })
}

function loadProfileFromLocalStore(){
    //const authToken = localStorage.getItem("thtt");
    const data =  localStorage.getItem('thup');
    return data;
  }

  function saveProfileToLocalStore(data){
    localStorage.setItem("thup", btoa(JSON.stringify(data)));
    //localStorage.setItem("thtt", btoa(data.token));
  }


export function logout(){
  return (dispatch => {
    deleteProfileFromLocalStore();
    return dispatch({type: types.AUTH_DO_LOGOUT, data: { error: false, data: {} } });
  })
}

function deleteProfileFromLocalStore(){
  return localStorage.removeItem('thup');
}
