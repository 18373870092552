import React, {Component} from "react";

class MyProfile extends Component{
    render() {
        return(
            <div>
                MyProfile
            </div>
        )
    }
}

export  default MyProfile
