import { Progress } from 'antd';
import React, {Component} from "react"


class EProgress extends Component{
    render() {
        let percentage = 0;
        if(this.props.record){
            percentage = (this.props.record.approvedCount/this.props.record.servicesDepartment.numberOfLevels)*100;
            percentage = Math.round(percentage)
        }
        return(
            <div>
                <Progress
                    strokeColor={{
                        '0%': '#108ee9',
                        '100%': '#87d068',
                    }}
                    percent={percentage}
                    status={'active'}
                />
            </div>
        )
    }
}

export default EProgress;