import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose } from 'recompose';

import {Form, Input, Tooltip, Icon, Select, Button, notification, Checkbox, Cascader} from 'antd';
import {fetchLevelsList} from "../../../actions/vendorsAction";
import { fetchUsersList, saveUser, updateUser } from '../../../actions/userAction';
import { getAuthToken, getUserList } from '../../../selectors';
import vendorReducer from "../../../reducers/vendorReducer";
import ESelect from "../../../elements/ESelect";
const { Option } = Select;

const FormItem = Form.Item;

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message,
        description,
    });
};








class UserForm extends Component {
    constructor(props) {
        super(props);
        if(this.props.location.user){
            const { _id } = this.props.location.user;
            console.log(_id);
        }
        const { match: { params } } = props;
        this.state = {
            userDetails: this.props.userDetails || {},
            userId: (this.props.location.user && this.props.location.user._id),
            permissions: params.permissions,
            initialValue: {},
            plainOptions:[],
            departmentIds:[]
        };
    }
    fetchLevelsList2= ()=>{
        const { actions } = this.props;
        actions.fetchLevelsList({
            token:this.props.token
        })
    };
    fetchUsersList2 = ()=>{
        const { actions } = this.props;
        console.log("token",this.props.token);
        actions.fetchUsersList({
            token:this.props.token
        })
    };


    componentDidMount=()=> {
        let token = this.props.token;
        this.fetchLevelsList2(token);
        this.fetchUsersList2(token);
        this.setInitialValue();

    }

    handleCancel = (e) => {
        e.preventDefault()
        this.props.history.push('/admin-dashboard/manage-users')
    }

    setInitialValue() {
        const { userList,vendorId } = this.props;
        const { userId } = this.state;

        console.log("userList",userList,userId);
        if (userId && userList.length > 0) {
            let user = userList.find(user => user._id === userId);
            if (user) {
                console.log("Initial Value User",user);

                user.password = '';
                this.setState({
                    initialValue: user,
                });
                let departmentsIds= [];
                if(user.departments){
                    user.departments.map((department)=>{
                        departmentsIds.push(department._id)
                    });
                }
                let plainOptions = [];

                departmentsIds.map((departmentId)=>{
                    this.props.levelsList.map((level)=>{
                        if(departmentId===level._id){
                            console.log("Matched");
                            for(let i=0;i<level.numberOfLevels;i++){
                                plainOptions.push(`L${i+1} ${level.departmentName}`)
                            }
                        }
                    })
                })
                this.setState({
                    plainOptions:plainOptions,
                })
                this.setState(prevState => ({
                    initialValue: {                   // object that we want to update
                        ...prevState.initialValue,    // keep all other key-value pairs
                        selectedOption: departmentsIds       // update the value of specific key
                    }
                }));
                console.log("selectedOption",this.state.initialValue.selectedOption)
                this.props.form.setFieldsValue({
                    department: departmentsIds,
                })
            }
        }
    }

    handleSubmit = (e) => {
        console.log("Updated User",this.state)
        e.preventDefault();
        const { actions ,adminId} = this.props;
        const { initialValue, userId } = this.state;
        this.props.form.validateFieldsAndScroll((err, values) => {
            let data = {};
            console.log("state permissions",this.state.initialValue.permissions);
            data["permissions"]=this.state.initialValue.permissions;
            Object.keys(values).map(key=>{
                if(key === "role"){
                    if(initialValue.permissions!==values[key]){
                        data.permissions=values[key];
                    }
                    return null;
                }else if(initialValue[key]!==values[key]) {
                    data[key]=values[key];
                    return null;
                }
                return null;
            });
            data["departments"]=this.state.initialValue?this.state.initialValue.department:this.state.departmentIds;
            data["email"]=values.email;
            data["userId"]=userId;
            data["name"]=values.name;
            data["createdByAdmin"]=adminId;

            if (!err && data && Object.keys(data).length>0) {
                actions.updateUser({
                    token: this.props.token,
                    formData: data
                },userId);
                this.setState({initialValue:data});
            }
        });
        this.setState({
            initialValue: {},
        })
            this.props.form.resetFields();
    };

    handleUserRoleChange(newRole) {
        console.log("new role is: ", newRole);
    }

    handleUserStatusChange(newStatus) {
        console.log("New status is: ", newStatus);
    }
    plainOptions = [];

    handleChange=(values)=> {
        let plainOptions = [];
        let departmentIds = values;
        departmentIds.map((departmentId)=>{
            this.props.levelsList.map((level)=>{
                if(departmentId===level._id){
                    console.log("Matched");
                    for(let i=0;i<level.numberOfLevels;i++){
                        plainOptions.push(`L${i+1} ${level.departmentName}`)
                    }
                }
            })
        })
        this.setState({
            plainOptions:plainOptions,
            departmentIds:values
        })
        console.log(`selected ${values}`);
        console.log(this.plainOptions,"plainoption")
    }

    UNSAFE_componentWillReceiveProps(newProps){
        if(newProps.saveUserStatus){
            if(newProps.saveUserStatus.error){
                newProps.saveUserStatus.errorMsg.map((err) => setTimeout(() => openNotificationWithIcon(
                    'error', `Error: ${err.param}`, err.msg)
                ), 500);
            }
            else {
                openNotificationWithIcon('success', 'Saved User!', 'Successfully saved the user details');
                this.props.form.resetFields();
            }
        }
    }

    render() {
        console.log("levels in add user",this.props.levelsList)


        const { form, saveUserLoading } = this.props;
        const { getFieldDecorator } = form;
        const { initialValue, userId } = this.state;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 14,
                    offset: 6,
                },
            },
        };

        let optionList = [];

        if(this.props.levelsList){
            this.props.levelsList.map((level=>{
                let opt = {
                    value: level._id,
                    label:level.departmentName
                };
                optionList.push(opt)
            }))
        }

        return (
// Changes in UI (change by: omkar)

            <div className="box box-default">
                <div className="box-header">
                    <h2>{`${userId ? 'Edit' : 'Add'} User`}</h2>
                </div>
                <div className="box-body">
                    <div>
                        <section className="form-v1-container">
                        <div style={{boxShadow:'0 4px 8px 0 rgba(0,0,0,0.2)',
                    transition: '2.5s',
                    width:'60%',
                    borderRadius:'15px',
                    margin: '0 auto 0 auto',
                    marginTop:'20px',
                    backgroundColor:'#FFF'

                }}>
                        <Form
                                onSubmit={this.handleSubmit}
                                className="form-v1">
                             <div style={{alignItems:'center',paddingTop:20,paddingLeft:'20px'}}>
                                <FormItem
                                    {...formItemLayout}
                                    label={(
                                        <span>
                                          Name &nbsp;
                                            <Tooltip title="What's your name?">
                                            <Icon type="question-circle-o" />
                                          </Tooltip>
                                        </span>
                                    )}
                                    hasFeedback
                                >
                                    {getFieldDecorator('name', {
                                        rules: [{ required: true, message: 'Please input your name!', whitespace: true }],
                                        initialValue: initialValue.name,
                                    })(
                                        <Input />
                                    )}
                                </FormItem>
                                <FormItem
                                    {...formItemLayout}
                                    label={(
                                        <span>
                                          Department  &nbsp;
                                            <Tooltip title="Assign Department">
                                            <Icon type="question-circle-o" />
                                          </Tooltip>
                                        </span>
                                    )}
                                    hasFeedback
                                >
                                    {getFieldDecorator('department', {
                                         initialValue: initialValue.departmentIds,
                                    })(
                                        <Checkbox.Group options={optionList} onChange={this.handleChange} />
                                    )}
                                </FormItem>
                                <FormItem
                                    {...formItemLayout}
                                    label="E-mail"
                                    hasFeedback
                                >
                                    {getFieldDecorator('email', {
                                        rules: [{
                                            type: 'email', message: 'The input is not valid E-mail!',
                                        }, {
                                            required: true, message: 'Please input your E-mail!',
                                        }],
                                        initialValue: initialValue.email,
                                    })(
                                        <Input />
                                    )}
                                </FormItem>

                                <FormItem
                                    {...formItemLayout}
                                    label="Password"
                                    hasFeedback
                                >
                                    {getFieldDecorator('password', {
                                        initialValue: '',
                                    })(
                                        <Input placeholder='Password must be at least 8 characters' />
                                    )}
                                </FormItem>

                                <FormItem
                                    {...formItemLayout}
                                    label="Phone"
                                    hasFeedback
                                >
                                    {getFieldDecorator('phone', {
                                        rules: [{
                                            required: false, message: 'Please input phone number!',
                                        }],
                                        initialValue: initialValue.phone,
                                    })(
                                        <Input />
                                    )}
                                </FormItem>



                                <FormItem
                                    {...formItemLayout}
                                    label="Role"
                                    hasFeedback
                                >
                                    {getFieldDecorator('role', {
                                        rules: [{
                                            required: true, message: 'Please choose a role',
                                        }],
                                        initialValue: initialValue.permissions,
                                    })(
                                        <Checkbox.Group options={this.state.plainOptions} onChange={onChange} />

                                    )}
                                </FormItem>

                                <FormItem {...tailFormItemLayout}>
                                    <Button
                                        icon="save"
                                        type="primary"
                                        htmlType="submit"
                                        className="btn-cta user-submit-btn"
                                        loading={saveUserLoading}
                                    >
                                        {saveUserLoading ? 'Saving' : 'Save'}
                                    </Button>
                                    <Link to="/app/user/list">
                                        <Button style={{marginBottom:30,marginLeft:'20px'}} onClick={this.handleCancel} type="default" htmlType="button" className="btn-cta">Cancel</Button>
                                    </Link>
                                </FormItem>
                                </div>
                            </Form>
                </div>
                        </section>
                    </div>
                </div>
            </div>
        );
    }
}

UserForm.propTypes = {
    actions: PropTypes.object,
    token: PropTypes.string,
    userList: PropTypes.array,
};

const mapStateToProps = (state, props) => {
    let userId = null;
    userId = (state.auth.meta && state.auth.meta.data && state.auth.meta.data._id ) ? state.auth.meta.data._id : null;

    return {
        token: state.auth.token,
        saveUserLoading: state.users.saveUserLoading,
        saveUserStatus: state.users.saveUserStatus,
        userList: getUserList(state),
        adminId:userId,
        levelsList: state.vendorReducer.levelsList


    }
};
// function matchDispatchToProps(dispatch) {
//     return bindActionCreators({ fetchLevelsList: fetchLevelsList,fetchUsersList,saveUser ,updateUser}, dispatch)
// }

function matchDispatchToProps (dispatch)   {
    return {
        actions: bindActionCreators({
            fetchUsersList,
            saveUser,
            updateUser,
            fetchLevelsList
        }, dispatch),
    }
};

const WrapperUserForm = Form.create()(UserForm);
export default compose(
    withRouter,
    connect(mapStateToProps, matchDispatchToProps),
)(WrapperUserForm);



function onChange(value, selectedOptions) {
    console.log(value, selectedOptions);
}

function filter(inputValue, path) {
    return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
}


