import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

// reducers
import authReducer from './authReducer';
import vRegisterReducer from './vRegisterReducer';
import vLoginReducer from './vLoginReducer';
import vendorReducer from './vendorReducer';
import userReducer from './usersReducer';

// root reducers
const rootReducer = combineReducers({
    auth: authReducer,
    register:vRegisterReducer,
    login:vLoginReducer,
    vendorReducer,
    users:userReducer
  });
  
  export default rootReducer;

