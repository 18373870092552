import React, { Component } from 'react'
import {
    Form,
    Input,
    Select,
    Upload,
    Button,
    Icon,
    AutoComplete,
    notification,
    DatePicker,
    Collapse
} from 'antd';
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { compose } from "recompose";
import { bindActionCreators } from 'redux';
import { saveItems } from '../../../actions/userAction'
const { Option } = Select;


class addItems extends Component {
    constructor(props) {
        super(props);
        if (this.props.location.item) {
            const { _id } = this.props.location.item;
            console.log('invoice meta', this.props.location.item)
            console.log('invoice id!!!!', _id);
        }
        this.state = {
            file: null,
            itemId: (this.props.location.item && this.props.location.item._id),
            itemName: (this.props.location.item && this.props.location.item.itemName),
            partNumber: (this.props.location.item && this.props.location.item.partNumber),
            price: (this.props.location.item && this.props.location.item.price),
            taxRate: (this.props.location.item && this.props.location.item.taxRate),
            hsmCode: (this.props.location.item && this.props.location.item.hsnCode),
        };

    }
    handleConfirmBlur = e => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };



    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            let token = this.props.token;

            if (!err) {
                console.log('Received values of form: ', values);

                this.props.saveItems({
                    itemId: this.state.itemId,
                    itemName: values.itemName,
                    partNumber: values.partNumber,
                    price: values.price,
                    taxRate: values.taxRate,
                    hsnCode: values.hsnCode,
                    token
                }
                )
                this.props.form.resetFields();


            }
        });
    };
    render() {
        const { getFieldDecorator } = this.props.form;
        console.log("selected item is", this.state.itemName
        )

        return (
            <div>
                <h3>{`${this.state.itemId ? 'Update' : 'Add'} Item`}</h3>

                <div style={{
                    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
                    transition: '2.5s',
                    width: '85%',
                    borderRadius: '15px',
                    alignItems: 'center',
                    margin: '0 auto 0 auto',
                    marginTop: '10px',
                    marginBottom: '10px',
                    backgroundColor: '#FFF'
                }}>
                    <Form onSubmit={this.handleSubmit}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 20, marginleft: '50%', padding: '21px' }}>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around' }}>
                                {/* Item Name */}
                                <div style={{ width: '80%', marginRight: '2%' }}>
                                    <Form.Item label="Item Name">
                                        {getFieldDecorator('itemName', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please input your Item Name',
                                                },
                                            ],
                                            initialValue: this.state.itemName,


                                        })(<Input placeholder="Please Insert Item Name" />)}
                                    </Form.Item>
                                </div>

                                {/* Part No. */}
                                <div style={{ width: '80%', marginRight: '2%' }}>
                                    <Form.Item label="Part Number">
                                        {getFieldDecorator('partNumber', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please input your part number',
                                                },
                                            ],
                                            initialValue: this.state.partNumber,

                                        })(<Input placeholder="Please Insert Item Name" />)}
                                    </Form.Item>
                                </div>

                            </div>




                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around' }}>
                                <div style={{ width: '80%', marginRight: '2%' }}>
                                    {/* Price */}
                                    <Form.Item label="Price">
                                        {getFieldDecorator('price', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please input your price',
                                                },
                                            ],
                                            initialValue: this.state.price,

                                        })(<Input placeholder="Please Insert price" />)}
                                    </Form.Item>
                                </div>
                                {/* Tax Rate */}
                                <div style={{ width: '80%', marginRight: '2%' }}>
                                    <Form.Item label="Tax Rate (%)">
                                        {getFieldDecorator('taxRate', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please input your tax rate',
                                                },
                                            ],
                                            initialValue: this.state.taxRate,

                                        })(<Select placeholder="Please select a tax rate">
                                            <Option value={5}>5</Option>
                                            <Option value={10}>10</Option>
                                            <Option value={18}>18</Option>
                                        </Select>)}
                                    </Form.Item>
                                </div>

                            </div>

                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around' }}>
                                <div style={{ width: '80%', marginRight: '2%' }}>
                                    {/* HSN/SAC Code */}
                                    <Form.Item label="HSN/SAC Code">
                                        {getFieldDecorator('hsnCode', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Please input your HSN/SAC Code',
                                                },
                                            ],
                                            initialValue: this.state.hsnCode,

                                        })(<Input placeholder="Please Insert HSN/SAC Code" />)}
                                    </Form.Item>
                                </div>
                                <div style={{ width: '80%', marginRight: '2%' }}>
                                    <Form.Item label="Comment">
                                        {getFieldDecorator('comment', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Please add your comments',
                                                },
                                            ],
                                            initialValue: this.state.hsnCode,

                                        })(<Input placeholder="Please add your comments" />)}
                                    </Form.Item>
                                </div>
                            </div>

                            {/* Save Button Division*/}
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                <div style={{ width: '80%', marginTop: '5%', marginRight: '2%' }}>
                                    <div>
                                        <Form.Item>
                                            <Button style={{ marginLeft: '55%' }} type="primary" htmlType="submit">
                                                Save
                                </Button>
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        token: state.auth.token,
        pendingVendorsList: state.vendorReducer.pendingVendorsList,
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({ saveItems: saveItems }, dispatch)
}


const WrappedAddPurchaseOrder = Form.create()(addItems)





export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)(WrappedAddPurchaseOrder);


