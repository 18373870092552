import * as types from '../constants/actionsTypes'

const initialSettings = {
    isRegister: false,
    loading: false,
    meta: {},
    authToken: null,
};

const settings = (state = initialSettings, action) => {
    switch (action.type) {
        case types.REGISTRARTION_VENDOR:
            return {
                ...state,
                isRegistration: false,
                loading: false,
                meta: {},
                authToken: null
            };

        case types.REGISTRATION_REQUEST_VENDOR:
            return {
                ...state,
                authToken: null,
                meta: {},
                loading: true,
                isRegistration: false
            };

        case types.REGISTRATION_RESPONSE_VENDOR:
            const token = action.data && action.data.data && action.data.data.token ? action.data.data.token : null;
            return {
                ...state,
                meta: action.data,
                isRegistration: !!token,
                authToken: token,
                loading: false
            };


        default:
            return state;
    }
};

export default settings
