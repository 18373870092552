import React, { Component, useEffect } from "react"
import { Select, Divider, Row, Col } from 'antd';
import { connect } from 'react-redux';
import { compose } from "recompose";
import { bindActionCreators } from 'redux';
import { getItemsList } from '../../../actions/userAction'
import { useDispatch, useSelector } from "react-redux";

const { Option } = Select;

// function onChange(value) {
//   console.log(`selected ${value}`);
// }


function onBlur() {
    console.log('blur');
}


function onFocus() {
    console.log('focus');
}

function onSearch(val) {
    console.log('search:', val);
}



function fetchItems() {
    this.props.getItemsList({

    })
}


class ItemsInput extends Component {

    render() {
        console.log('items in items container', this.props.itemsList);
        const children = [];
        if (this.props.itemsList) {
            this.props.itemsList.map((items => {
                console.log(".map in items container", items.itemName);
                children.push(<Option key={items._id}>{items.itemName}</Option>);
            }))
        }
        return (
            this.props.items.map((val, idx) => {
                let itemId = `itemName-${idx}`, partId = `partNo-${idx}`, priceId = `price-${idx}`, taxRateId = `taxRate-${idx}`, quantityId = `quantity-${idx}`, amountId = `amount-${idx}`, descriptionId = `description-${idx}`
                let taxRateValue = this.props.items.taxRate;
                // const username = meta && meta.data && meta.data.name ? meta.data.name : 'User';
                let itemsDropdown = this.props.items[idx] && this.props.items[idx].itemName && this.props.items[idx].itemName.itemName ? this.props.items[idx].itemName.itemName : 'No Item';
                console.log("itemsContainer item name", itemsDropdown)

                return (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 20, padding: '2px 16px' }}>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                            <div style={{ width: '80%', marginRight: '2%' }}>

                                <label htmlFor={itemId}>Item Name</label>
                                {!itemsDropdown &&
                                    <Select
                                        name={itemId}
                                        // defaultValue={this.props.items[idx].itemName.itemName}
                                        data-id={idx}
                                        className="itemName"
                                        id={itemId}
                                        style={{ width: 200 }}
                                        placeholder="Select Item Name"
                                        optionFilterProp="children"
                                        onChange={x => this.props.onChange(x, idx, 'itemName')}
                                        onFocus={onFocus}
                                        onBlur={onBlur}
                                        onSearch={onSearch}
                                        filterOption={(input, option) =>
                                            option.this.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {children}
                                    </Select>
                                }


                                {itemsDropdown &&
                                    <Select
                                        name={itemId}
                                        defaultValue={itemsDropdown}
                                        data-id={idx}
                                        className="itemName"
                                        id={itemId}
                                        style={{ width: 200 }}
                                        placeholder="Select Item Name"
                                        optionFilterProp="children"
                                        onChange={x => this.props.onChange(x, idx, 'itemName')}
                                        onFocus={onFocus}
                                        onBlur={onBlur}
                                        onSearch={onSearch}
                                        filterOption={(input, option) =>
                                            option.this.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {children}
                                    </Select>
                                }

                            </div>

                            <div style={{ width: '80%', marginRight: '2%' }}>
                                <label htmlFor={partId}>Part No.</label>
                                <div>
                                    <input
                                        type="text"
                                        name={partId}
                                        data-id={idx}
                                        id={partId}
                                        value={this.props.items[idx].partNumber}
                                        className="partNumber"
                                        placeholder="Enter Part Number"
                                        style={{ padding: '5px', borderRadius: '10px', border: '1px solid #7D7D7D', height: '30px', width: '190px' }}
                                    />
                                </div>
                            </div>

                            <div style={{ width: '80%', marginRight: '2%' }}>
                                <label htmlFor={priceId}>Price</label>
                                <div>
                                    <input
                                        type="text"
                                        name={priceId}
                                        data-id={idx}
                                        id={priceId}
                                        value={this.props.items[idx].price}
                                        onChange={x => this.props.onChange(x, idx, 'price')}
                                        className="price"
                                        placeholder="Enter Price"
                                        style={{ padding: '5px', borderRadius: '10px', border: '1px solid #7D7D7D', height: '30px', width: '190px' }}
                                    />
                                </div>
                            </div>
                        </div>




                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', padding: '10px' }}>
                            <div style={{ width: '90%', marginRight: '2%', right: '8%' }}>
                                <label htmlFor={taxRateId}>Tax Rate (%)</label>
                                <div>
                                    <input
                                        type="number"
                                        name={taxRateId}
                                        data-id={idx}
                                        id={taxRateId}
                                        onChange={x => this.props.onChange(x, idx, 'taxRate')}
                                        value={this.props.items[idx].taxRate}
                                        className="taxRate"
                                        style={{ padding: '5px', borderRadius: '10px', border: '1px solid #7D7D7D', height: '30px', width: '190px' }}
                                    />
                                </div>
                            </div>

                            <div style={{ width: '80%', marginRight: '2%' }}>
                                <label htmlFor={quantityId}>Quantity</label>
                                <input
                                    type="text"
                                    name={quantityId}
                                    data-id={idx}
                                    id={quantityId}
                                    onChange={x => this.props.onChange(x, idx, 'quantity')}
                                    value={this.props.items[idx].quantity}
                                    className="quantity"
                                    placeholder="Enter Quantity"
                                    style={{ padding: '5px', borderRadius: '10px', border: '1px solid #7D7D7D', height: '30px', width: '190px' }}
                                />
                            </div>

                            <div style={{ width: '80%', marginRight: '2%' }}>
                                <label htmlFor={amountId}>Amount</label>
                                <input
                                    type="text"
                                    name={amountId}
                                    data-id={idx}
                                    id={amountId}
                                    value={this.props.items[idx].amount}
                                    onChange={this.props.addAmount}
                                    className="amount"
                                    placeholder="Enter Amount"
                                    style={{ padding: '5px', borderRadius: '10px', border: '1px solid #7D7D7D', height: '30px', width: '190px' }}
                                />
                            </div>
                        </div>


                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', padding: '10px' }}>
                            <div style={{ width: '80%', marginRight: '2%' }}>
                                <label htmlFor={descriptionId}>Description</label>
                                <input
                                    type="text"
                                    name={descriptionId}
                                    data-id={idx}
                                    id={descriptionId}
                                    value={this.props.items[idx].description}
                                    className="description"
                                    placeholder="Enter Description"
                                    style={{ padding: '5px', borderRadius: '10px', border: '1px solid #7D7D7D', height: '30px', width: '190px' }}
                                />
                            </div>

                            <div style={{ width: '80%', marginRight: '2%' }}>
                            </div>

                            <div style={{ width: '80%', marginRight: '2%' }}>
                            </div>
                        </div>
                        <Divider style={{ width: '100%', left: '10%', color: 'red', border: '1px solid #D1D1D1' }} />


                    </div>

                )
            })
        )
    }
}

function mapStateToProps(state) {
    return {
        token: state.auth.token,
        itemsList: state.users.itemsList,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getItemsList: getItemsList }, dispatch)
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
)(ItemsInput);
