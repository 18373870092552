import { createSelector } from 'reselect';

const ticketSelector = state => state.ticket

export const getTickets = createSelector(
  [ticketSelector],
  ticket => ticket.ticketList,
);

export const getTicketById = createSelector(
  [ticketSelector],
  ({ ticketList, activeTicketId }) => ticketList.find(ticket => ticket._id === activeTicketId),
);

export const getActiveTicketId = createSelector(
  [ticketSelector],
  ticket => ticket.activeTicketId,
);

export const getTicketThreads = createSelector(
  [ticketSelector],
  ticket => ticket.ticketThreads,
);

export const getTicketStatus = createSelector(
  [ticketSelector],
  ticket => ticket.status,
);

export const getErrorData = createSelector(
  [ticketSelector],
  ticket => ticket.error,
);
