import { createSelector } from 'reselect';

const deviceSelector = state => state.devices

export const getDeviceList = createSelector(
  [deviceSelector],
  devices => devices.deviceList,
);

export const pendingResetDevice = createSelector(
  [deviceSelector],
  devices => devices.resetDeviceLoading,
);

export const getDeviceErrorData = createSelector(
  [deviceSelector],
  devices => devices.error,
);

export const pendingDeviceTransfer = createSelector(
  [deviceSelector],
  devices => devices.deviceTransferLoading,
);

export const pendingDeleteDevice = createSelector(
  [deviceSelector],
  devices => devices.deleteDeviceLoading,
);
