import * as types from '../constants/actionsTypes'

const initialSettings = {
    docsListLoading: false,
    docsInvoiceLoading:false,
    docsListLevelLoading:false,
    docsListUserLoading:false,

    // docsPaymentAdvoice
    docsPaymentAdvoiceLoading:false,

    // Purchase Order
    // docsPurchaseOrderLoading:false,

    levelsList:[],
    usersList:[],
    invoicesList:[],
    pendingVendorsList: [],
    approvedVendorsList: [],
    rejectedVendorsList: [],
    levelMeta : [],
    usersMeta: [],
    invoiceMeta: [],

    // vendor list
    vendorsList:[],

    // docsPaymentAdvoice
    paymentAdvoiceMeta:[],

    // docs purchase order
    // purchaseOrderMeta:[],
    // purchaseOrderList:[],

    // invoice status change
    statusChangeLoadingDocs:[],
    searchInvoicesList:[],

    // invoice search
    docsInvoiceSearchLoading:false,
};

const settings = (state = initialSettings, action) => {
    switch (action.type){
        case types.REQUEST_VENDOR_LIST:
            return {
                ...state,
                docsListLoading: true,
            };
        case types.ADD_REQUEST_LEVEL:
            return {
                ...state,
                docsListLoading: true,
            };

        case types.ADD_RESPONSE_LEVEL:
                        const levelMeta2 = action.data && action.data.data  ? action.data.data : null;
                        console.log('LEVEL',levelMeta2);
                        return {
                            ...state,
                            docsListLoading: false,
                            levelMeta: levelMeta2,
                        };
            

            case types.REQUEST_VENDOR_UPDATE:
            return {
                ...state,
                docsListLoading: true,
            };

        case types.RESPONSE_VENDOR_UPDATE:
            return {
                ...state,
                docsListLoading: false,
            };
        case types.REQUEST_INVOICE_EDIT:
            return {
                ...state,
                docsListLoading: true,
            };

        case types.RESPONSE_INVOICE_EDIT:
            return {
                ...state,
                docsListLoading: false,
            };

        case types.REQUEST_STATUS_UPDATE:
            return {
                ...state,
                docsListLoading: true,
            };

        case types.RESPONSE_STATUS_UPDATE:

            return {
                ...state,
                invoicesListDirty: action.data.error===false,
                docsListLoading: false,
            };
        case types.RESPONSE_PENDING_VENDOR_LIST:
            const pendingVendorsList = action.data && action.data.data  ? action.data.data : null;
            console.log('pendingVendorsList',pendingVendorsList);
            return {
                ...state,
                docsListLoading: false,

                pendingVendorsList: pendingVendorsList,
            };
        case types.RESPONSE_APPROVED_VENDOR_LIST:
            const approvedVendorsList = action.data && action.data.data  ? action.data.data : null;
            console.log('approvedVendorsList',approvedVendorsList);
            return {
                ...state,
                docsListLoading: false,
                approvedVendorsList: approvedVendorsList,
            };
        case types.RESPONSE_REJECTED_VENDOR_LIST:
            const rejectedVendorsList = action.data && action.data.data  ? action.data.data : null;
            console.log('rejectedVendorsList',rejectedVendorsList);
            return {
                ...state,
                docsListLoading: false,
                rejectedVendorsList: rejectedVendorsList,
            };

            //Fetch all vendors Reducers
            case types.RESPONSE_VENDOR_FETCH:
              const vendorsList = action.data && action.data.data  ? action.data.data : null;
              console.log('all Vendors List in vendor reducer',vendorsList);
              return {
                  ...state,
                  docsListLoading: false,
                  vendorsList: vendorsList,
              };

            // Fetch Levels Reducers
            case types.REQUEST_LEVEL_FETCH:
                    return {
                        ...state,
                        docsListLevelLoading: true,
                    };
            case types.RESPONSE_LEVEL_FETCH:
            const LevelsList = action.data && action.data.data  ? action.data.data : null;
            console.log('LevelsList',LevelsList);
            return {
                ...state,
                docsListLevelLoading: false,
                levelsList: LevelsList,
            };


            //       // Fetch PurchaseOrder Reducers
            //       case types.REQUEST_PURCHASE_ORDER_FETCH:
            //         return {
            //             ...state,
            //             docsPurchaseOrderLoading: true,
            //         };
            // case types.RESPONSE_PURCHASE_ORDER_FETCH:
            // const purchaseOrderList = action.data && action.data.data  ? action.data.data : null;
            // console.log('PurchaseOrderList',purchaseOrderList);
            // return {
            //     ...state,
            //     docsPurchaseOrderLoading: false,
            //     purchaseOrderList: purchaseOrderList,
            // };


                   // Fetch Invoices Reducers
                   case types.REQUEST_INVOICE_FETCH:
                    return {
                        ...state,
                        docsInvoiceLoading: true,
                    };
            case types.RESPONSE_INVOICE_FETCH:
            const InvoicesList = action.data && action.data.data  ? action.data.data : null;
            console.log('InvoiceList',InvoicesList);
            return {
                ...state,
                docsInvoiceLoading: false,
                invoicesList: InvoicesList,
                invoicesListDirty:false
            };


               // Fetch Users Reducers
               case types.REQUEST_USER_FETCH:
                return {
                    ...state,
                    docsListUserLoading: true,
                };
        case types.RESPONSE_USER_FETCH:
        const UsersList = action.data && action.data.data  ? action.data.data : null;
        console.log('UsersList',UsersList);
        return {
            ...state,
            docsListUserLoading: false,
            usersList: UsersList,
        };

            // Add Users Reducer
            case types.REQUEST_ADD_USER:
                    return {
                        ...state,
                        docsListLoading: true,
                    };
        
                case types.RESPONSE_ADD_USER:
                  const userMeta2 = action.data && action.data.data  ? action.data.data : null;
                  console.log('RESPONSE_ADD_USER',userMeta2.data);
                return {
                     ...state,
                    //`usersList: [...state.usersList, userMeta2.data],
                    docsListLoading: false,
                 };


            // Add Invoice Reducer
            case types.REQUEST_INVOICE_ADD:
                return {
                    ...state,
                    docsInvoiceLoading: true,
                };
    
            case types.RESPONSE_INVOICE_ADD:
              const invoiceMeta2 = action.data && action.data.data  ? action.data.data : null;
              console.log('INVOICE',invoiceMeta2);
            return {
                 ...state,
                 docsInvoiceLoading: false,
                 invoiceMeta: invoiceMeta2,
             };


             // Add Payment Advoice Reducer

             case types.REQUEST_PAYMENT_ADVOICE_ADD:
              return {
                  ...state,
                  docsPaymentAdvoiceLoading: true,
              };
  
          case types.RESPONSE_PAYMENT_ADVOICE_ADD:
            const paymentAdvoiceMeta2 = action.data && action.data.data  ? action.data.data : null;
            console.log('Payment Advoices',paymentAdvoiceMeta2);
          return {
               ...state,
               docsPaymentAdvoiceLoading: false,
               paymentAdvoiceMeta: paymentAdvoiceMeta2,
           };



                        // Add Purchase Order Reducer

                      //   case types.REQUEST_PURCHASE_ORDER_ADD:
                      //     return {
                      //         ...state,
                      //         docsPurchaseOrderLoading: true,
                      //     };
              
                      // case types.RESPONSE_PURCHASE_ORDER_ADD:
                      //   const purchaseOrderMeta2 = action.data && action.data.data  ? action.data.data : null;
                      //   console.log('Purchase Order',purchaseOrderMeta2);
                      // return {
                      //      ...state,
                      //      docsPurchaseOrderLoading: false,
                      //      purchaseOrderMeta: purchaseOrderMeta2,
                      //  };


                    // Search Levels Reducers
            case types.REQUEST_INVOICE_SEARCH:
              return {
                  ...state,
                  statusChangeLoadingDocs: true,
              };

              case types.RESPONSE_INVOICE_SEARCH:
              const searchInvoicesList2 = action.data && action.data.data  ? action.data.data : null;
              console.log('LevelsListSearch',searchInvoicesList2);
              return {
                  ...state,
                  statusChangeLoadingDocs: false,
                  searchInvoicesList: searchInvoicesList2,
              };

             //status Change Invoice Reducers
      case types.REQUEST_DOC_STATUS_UPDATE:
      let { success, workId, newStatus } = action.data;
      console.log("store: got action data:", action.data);
      const statusChangeLoadingDocs = [...state.statusChangeLoadingDocs];
      let _docsList = state.docsList.map((doc)=>{

        if(doc._id === workId) {
          console.log("found recordId: ", workId);
          doc.status = newStatus;
          let element = {};
          element.id = doc._id;
          element.loading = true;
          statusChangeLoadingDocs.push(element)
        } else{
          console.log("WorkId adn doc_id is not matched")
        }
        return doc;
      });

      return {
        ...state,
        docsList: _docsList,
        statusChangeLoadingDocs: statusChangeLoadingDocs
      };

    case types.RESPONSE_DOC_STATUS_UPDATE:
      console.log(action.data);
      const statusChangeLoadingDocs2 = [...state.statusChangeLoadingDocs];
      let newArray=[];
      let _docsList2 = state.docsList.map((doc)=>{
        if(doc._id === action.data.workId) {
          console.log("found recordId: ", action.data.workId);
          doc.status = action.data.newStatus;
          newArray = statusChangeLoadingDocs2
            .filter(function(element) {
              return element.id !== doc._id ;
            });
        }
        return doc;
      });
      return {
        ...state,
        docsList:_docsList2,
        statusChangeLoadingDocs: newArray
      };

    case types.CHANGE_DOC_META:
      let { docsList } = state;
      let { recordId, meta, record } = action.data;
      console.log("store: got action data:", action.data);
      console.log("store: updating record id with meta: ", recordId, meta, record);
      docsList = docsList.map((doc)=>{
        if(doc._id === recordId) {
          console.log("found recordId: ", recordId);
          doc.meta = meta;
          if(record && record._id){
            record.key = doc.key;
            return record;
          }
        }

        return doc;
      });
      console.log("updating now");
      return {
        ...state,
        docsList: docsList
      };

    case types.REQUEST_DOC_SAVE:
      return {
        ...state,
        saveDocLoading: true,
       saveDocStatus: null
      };

    case types.RESPONSE_DOC_SAVE:
      let saveDocStatus = { error: false };
      if(action.data.error){
        saveDocStatus = action.data
      }

      return {
        ...state,
        saveDocLoading: false,
        saveDocStatus: saveDocStatus
      };


        default:
            return state;
    }
};

export default settings
