import React,{Component} from 'react';
import 'antd/dist/antd.css';
import { connect } from 'react-redux';
import { vendorRegister } from '../../actions/vendorsAction';
import { bindActionCreators } from 'redux';
import '../registration/registrationVendor.css';
import './mainNavbar.css'
import {Link, NavLink} from 'react-router-dom';
// import './index.css';
import {
  Form,
  Input,
  Select,
  Upload,
  Button,
  Icon,
  AutoComplete,
  notification
} from 'antd';
import MainNavigation from "../Navigation/mainNavbar";
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message,
        description,
    });
};
const logoImage = require('../../../src/payflow-logo.png');
const { Option } = Select;
const bgImage = require('../../../src/bg.jpg');
const divStyle = {

    //   backgroundImage:`url(${bgImage})`,
      backgroundColor:'#d0edfb',
      height:'170vh',
      width:'100%',
      backgroundAttachment: 'fixed'
    };

class registrationVendor extends Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    file:null,
    count:0
  };




  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };
    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('vendorPassword')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    };
    //onClick form submit function
    handleSubmit = e => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
          if (!err) {
            console.log('Received values of form: ', values);
            let formData = new FormData();
            formData.append('companyName', values.companyName);
            formData.append('vendorEmail',values.vendorEmail);
            formData.append('vendorPassword',values.vendorPassword);
            formData.append('gstNo',values.gstNo);
          //  formData.append('gstCertificate',values.gstCertificate[0]?.originFileObj);
            formData.append('panNo',values.panNo);
            formData.append('phoneNo',values.phoneNo);
            formData.append('cancelCheque',values.cancelCheque[0]?.originFileObj);
            formData.append('accountName',values.accountName);
            formData.append('bank',values.bankName);
            formData.append('accountNo',values.accountNo);
            formData.append('ifscCode',values.ifscCode);
            formData.append('branch',values.branch);
            formData.append('services',values.selectService);
            formData.append('address',values.address);
            console.log(formData);
            this.props.vendorRegister(formData);
          }
        });
      };



  // validate password  function
  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  // upload file function
  normFile = e => {
    this.setState({file:e.fileList[0]});
    if (Array.isArray(e)) {
        return e;
    }
    return e && e.fileList;

};




  render() {
    const { getFieldDecorator } = this.props.form;
    const prefixSelector = getFieldDecorator('prefix', {
      initialValue: '91',
    })(
      <Select style={{ width: 70 }}>
        <Option value="91">+91</Option>
      </Select>,
    );

    let uploadSuccess = null;
    if(this.state.file){
        uploadSuccess = 'file uploaded successfully'
    }
    return (
        <div style={divStyle}>


                    <header className="main-navigation">
                            <div className="main-navigation__logo">
                            <img height="50" width="50" src={logoImage}/> &nbsp;&nbsp;&nbsp;<strong style={{fontFamily:'MyriadPro',color:'#1f3f7a',fontSize:'20px'}}>PayFlow</strong>
                            </div>
                            <Link to={'/landingPage'}>
                                        <Button id={'goToLogin'} style={{display:'none'}}>Login</Button>
                                    </Link>
                                    <Link to={'/vendorDashboard/manage-users'}>
                                        <Button id={'goToVendorDashboard'} style={{display:'none'}}> Login</Button>
                                    </Link>
                            <nav className="main-navigation__items">
                                <ul>
                                    <Link to="/landingPage"><button style={{borderRadius:'5px',backgroundColor:'#1F407A'}}>
                                    Login
                                    </button></Link>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <button style={{borderRadius:'5px'}}>
                                <NavLink to="/registrationVendor">Registration</NavLink>
                                </button>
                                {/* <li>
                                <NavLink to="/loginAdmin">Management</NavLink>
                                </li> */}
                                </ul>
                            </nav>
                        </header>



                        <div style={{boxShadow:'0 4px 8px 0 rgba(0,0,0,0.2)',
                transition: '2.5s',
                width:'60%',
                borderRadius:'15px',
                alignItems:'center',
                margin: '0 auto 0 auto',
                marginTop:'80px',
                marginBottom:'80px',
                backgroundColor:'#FFF'
            }}>

                <div style={{padding: '2px 16px'}} as='h2'>Vendor Registration</div>
                <Form  onSubmit={this.handleSubmit}>
                    <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginBottom:20, padding: '2px 16px'}}>
                        <div style={{display:'flex',width:'100%',justifyContent:'space-between'}}>
                            <div style={{width:'80%',marginRight:'2%'}}>
                                <div>
                                    {/* Company Name Field */}
                                    <Form.Item  label="Company Name">
                                        {getFieldDecorator('companyName', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please input your company name',
                                                },
                                            ],
                                        })(<Input placeholder="Please Insert Company Name" />)}
                                    </Form.Item>
                                </div>
                            </div>

                            <div style={{width:'80%',marginRight:'2%'}}>
                                <div>

                                    {/* Email Id Field */}
                                    <Form.Item label="E-mail">
                                        {getFieldDecorator('vendorEmail', {
                                            rules: [
                                                {
                                                    type: 'email',
                                                    message: 'The input is not valid E-mail!',
                                                },
                                                {
                                                    required: true,
                                                    message: 'Please input your E-mail!',
                                                },
                                            ],
                                        })(<Input placeholder="Please insert email ID" />)}
                                    </Form.Item>
                                </div>
                            </div>
                        </div>


                        <div style={{display:'flex',width:'100%',justifyContent:'space-between'}}>
                            <div style={{width:'80%',marginRight:'2%'}}>
                                <div>
                                    {/* Password Field */}
                                    <Form.Item label="Password" hasFeedback>
                                        {getFieldDecorator('vendorPassword', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please input your password!',
                                                },
                                                {
                                                    validator: this.validateToNextPassword,
                                                },
                                            ],
                                        })(<Input.Password placeholder="Please insert password" />)}
                                    </Form.Item>
                                </div>
                            </div>

                            <div style={{width:'80%',marginRight:'2%'}}>
                                <div>

                                    {/* Confirm Password Field */}
                                    <Form.Item label="Confirm Password" hasFeedback>
                                        {getFieldDecorator('confirm', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please confirm your password!',
                                                },
                                                {
                                                    validator: this.compareToFirstPassword,
                                                },
                                            ],
                                        })(<Input.Password placeholder="Please insert confirm password" onBlur={this.handleConfirmBlur} />)}
                                    </Form.Item>
                                </div>
                            </div>
                        </div>

                        <div style={{display:'flex',width:'100%',justifyContent:'space-between'}}>
                            <div style={{width:'80%',marginRight:'2%'}}>
                                <div>
                                    {/* GST No Field */}
                                    <Form.Item  label="GST NO">
                                        {getFieldDecorator('gstNo', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please input your GST No',
                                                },
                                            ],
                                        })(<Input placeholder="Please Insert GST NO" />)}
                                    </Form.Item>
                                </div>
                            </div>
{/* 
                            <div style={{width:'80%',marginRight:'2%'}}>
                                <div>

                                 
                                    <Form.Item label="GST Registration Certificate">
                                        {getFieldDecorator('gstCertificate', {
                                            rules: [
                                                { required: true, message: 'Please upload the Canceled cheque!' }
                                            ],
                                            valuePropName: 'fileList',
                                            getValueFromEvent: this.normFile,
                                        })(
                                            <Upload name="logo" accept="application/pdf" onChange={(res)=>{
                                                console.log("res===>",res)
                                            }}>
                                                <Button>
                                                    <Icon type="upload" /> Click to upload
                                                </Button>
                                                <div style={{color:'#388e3c'}}>
                                                    {uploadSuccess}
                                                </div>
                                            </Upload>,
                                        )}
                                    </Form.Item>
                                </div>
                            </div> */}
                        </div>

                        <div style={{display:'flex',width:'100%',justifyContent:'space-between'}}>
                            <div style={{width:'80%',marginRight:'2%'}}>
                                <div>
                                    {/* PAN No Field */}
                                    <Form.Item  label="PAN NO">
                                        {getFieldDecorator('panNo', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please input your PAN No',
                                                },
                                            ],
                                        })(<Input placeholder="Please Insert PAN NO" />)}
                                    </Form.Item>
                                </div>
                            </div>

                            <div style={{width:'80%',marginRight:'2%'}}>
                                <div>

                                    {/* Phone NO Field */}
                                    <Form.Item label="Phone Number">
                                        {getFieldDecorator('phoneNo', {
                                            rules: [
                                                { required: true, message: 'Please input your phone number!' }
                                            ],
                                        })(<Input placeholder="Please insert Phone No" addonBefore={prefixSelector} style={{ width: '100%' }} />)}
                                    </Form.Item>
                                </div>
                            </div>
                        </div>


                        <div style={{display:'flex',width:'100%',justifyContent:'space-between'}}>
                            <div style={{width:'80%',marginRight:'2%'}}>
                                <div>
                                    {/* cancel cheque Field */}
                                    <Form.Item label="Cancel Cheque">
                                        {getFieldDecorator('cancelCheque', {
                                            rules: [
                                                { required: true, message: 'Please upload the Canceled cheque!' }
                                            ],
                                            valuePropName: 'fileList',
                                            getValueFromEvent: this.normFile,
                                        })(
                                            <Upload name="logo" >
                                                <Button>
                                                    <Icon type="upload" /> Click to upload
                                                </Button>
                                                <div style={{color:'#388e3c'}}>
                                                    {uploadSuccess}
                                                </div>
                                            </Upload>,
                                        )}
                                    </Form.Item>
                                </div>
                            </div>

                            <div style={{width:'80%',marginRight:'2%'}}>
                                <div>

                                    {/* Account No Field */}
                                    <Form.Item  label="Account No">
                                        {getFieldDecorator('accountNo', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please input your Account No',
                                                },
                                            ],
                                        })(<Input placeholder="Please Insert Account No" />)}
                                    </Form.Item>
                                </div>
                            </div>
                        </div>



                        <div style={{display:'flex',width:'100%',justifyContent:'space-between'}}>
                            <div style={{width:'80%',marginRight:'2%'}}>
                                <div>
                                    {/* Bank Name Field */}
                                    <Form.Item  label="Bank Name">
                                        {getFieldDecorator('bankName', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please input your Bank Name',
                                                },
                                            ],
                                        })(<Input placeholder="Bank Name" />)}
                                    </Form.Item>
                                </div>
                            </div>

                            <div style={{width:'80%',marginRight:'2%'}}>
                                <div>

                                    {/* Account Name Field */}
                                    <Form.Item  label="Account Name">
                                        {getFieldDecorator('accountName', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please input your account name',
                                                },
                                            ],
                                        })(<Input placeholder="Please Insert Account Name" />)}
                                    </Form.Item>
                                </div>
                            </div>
                        </div>


                        <div style={{display:'flex',width:'100%',justifyContent:'space-between'}}>
                            <div style={{width:'80%',marginRight:'2%'}}>
                                <div>
                                    {/* IFSC Code Field */}
                                    <Form.Item  label="IFSC Code">
                                        {getFieldDecorator('ifscCode', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please input your IFSC Code',
                                                },
                                            ],
                                        })(<Input placeholder="IFSC Code" />)}
                                    </Form.Item>
                                </div>
                            </div>

                            <div style={{width:'80%',marginRight:'2%'}}>
                                <div>

                                    {/* Branch Field */}
                                    <Form.Item  label="Branch">
                                        {getFieldDecorator('branch', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please input your Branch',
                                                },
                                            ],
                                        })(<Input placeholder="Please Insert Branch" />)}
                                    </Form.Item>
                                </div>
                            </div>
                        </div>

                        <div style={{display:'flex',width:'100%',justifyContent:'space-between'}}>
                            <div style={{width:'80%',marginRight:'2%'}}>
                                <div>
                                    {/* Select Service Field */}
                                    <Form.Item label="Select services" hasFeedback>
                                        {getFieldDecorator('selectService', {
                                            rules: [{ required: true, message: 'Please select your services!' }],
                                        })(
                                            <Select placeholder="Please select a Service">
                                                <Option value="IT-Software">IT-Software</Option>
                                                <Option value="IT-Hardware">IT-Hardware</Option>
                                                <Option value="IT-Networking">IT-Networking</Option>
                                            </Select>,
                                        )}
                                    </Form.Item>
                                </div>
                            </div>

                            <div style={{width:'80%',marginRight:'2%'}}>
                                <div>

                                    {/* Address Field */}
                                    <Form.Item  label="Address">
                                        {getFieldDecorator('address', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please input your Address',
                                                },
                                            ],
                                        })(<Input placeholder="Please Insert Address" />)}
                                    </Form.Item>
                                </div>
                            </div>
                        </div>


                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Register
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </div>
            </div>

    );
  }
}

const WrappedLogin = Form.create()(registrationVendor)


function mapStateToProps(state) {
  return {
      register: state.register,
      isRegistration: state.register.isRegistration
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({ vendorRegister: vendorRegister }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(WrappedLogin)
          