import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import MainNavigation from '../../Navigation/mainNavbar'
import { BrowserRouter as Router, Link, Route } from "react-router-dom";

// import '../Navigation/mainNavbar.css';
import { connect } from 'react-redux';
import { adminLogin } from '../../../actions/adminAction';
import PendingVendors from "../../adminDashboard/PendingVendors";

import { bindActionCreators } from 'redux';

// import './index.css';
import {
    Form,
    Input,
    Select,
    Button,
    AutoComplete,
    Row, Col, Checkbox
} from 'antd';
const bgImage = require('../../../images/bg.jpg');
const logoImage = require('../../../payflow logo.png')

const divStyle = {

    backgroundImage: `url(${bgImage})`,
    backgroundSize: 'cover',
    height: '99vh',
    zIndex: 3
};



class LoginAdmin extends Component {
    state = {
        confirmDirty: false,
        autoCompleteResult: [],
    };

    handleConfirmBlur = e => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };

  //onClick form submit function
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
            console.log('Received values of form: ', values);
            this.props.adminLogin({
                email:values.email,
                password:values.password
            })
        }
    });
};



    // validate password  function
    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    };


    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <div>
            <div style={{
              backgroundColor: '#1F3F7A',
              height: '30px',
              width: '50%',
              marginLeft: '125px',
              borderRadius: '10px',
              marginTop: '20px'
    
            }}>
              <h4 style={{ textAlign: 'center', color: 'white',fontFamily:'MyriadPro', padding: '5px' }}>MANAGEMENT LOGIN</h4>
            </div>
            <Form onSubmit={this.handleSubmit}>
            <div style={{marginLeft:'45px',marginTop:'25px'}} className="gutter-example">
                    <Row gutter = {12}>
                            <Col style={{marginRight:'5px',height:'30px',marginTop:'11px'}} className="gutter-row" span={5}>
                              <div className="gutter-box">USERNAME:</div>
                            </Col>
                            <Col style={{height:'30px'}} className="gutter-row" span={15}>
                               <div className="gutter-box">
                             {/* Email Id Field */}
                             <Form.Item>
                                            {getFieldDecorator('email', {
                                                rules: [
                                                    {
                                                        type: 'email',
                                                        message: 'The input is not valid E-mail!',
                                                    },
                                                    {
                                                        required: true,
                                                        message: 'Please input your E-mail!',
                                                    },
                                                ],
                                            })(<Input style={{backgroundColor:'#d0edfb '}} placeholder="Please insert email ID" />)}
                                        </Form.Item>
                                 </div>
                            </Col>
                        </Row>

                        <Row style={{marginTop:'30px'}} gutter = {12}>
                            <Col style={{marginRight:'5px',marginTop:'11px',height:'30px'}} className="gutter-row" span={5}>
                              <div className="gutter-box">PASSWORD:</div>
                            </Col>
                            <Col style={{height:'30px'}} className="gutter-row" span={15}>
                               <div className="gutter-box">
                                            {/* Password Field */}

                                        {/* Password Field */}
                                        <Form.Item hasFeedback>
                                            {getFieldDecorator('password', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please input your password!',
                                                    },
                                                    {
                                                        validator: this.validateToNextPassword,
                                                    },
                                                ],
                                            })(<Input.Password style={{backgroundColor:'#d0edfb '}} placeholder="Please insert password" />)}
                                        </Form.Item>
                                 </div>
                            </Col>
                        </Row>
                    </div>

                    <Form.Item>
                <div style={{margin:'auto',marginTop:'20px',padding:'10px',textAlign:'center'}}>
                <Button style={{fontFamily:'MyriadPro',width:'30%',backgroundColor:'#1F3F7A',color:'white'}}  htmlType="submit">
                     SIGN IN
                </Button>
                <div>
                        <Row>
                          <Col span={8}>
                            <Form.Item>
                                {getFieldDecorator('remember',{
                                  valuePropName:'checked',
                                  initialValue: true,
                                })(<Checkbox>Remember me</Checkbox>)}
                            </Form.Item>
                            </Col>
                          <Col span={8} offset={8}>
                            FORGOT PASSWORD
                          </Col>

                          
                          <Link to="/registrationVendor">
                                <Col span={8} style={{ textDecoration: 'underline', textAlign: 'center',color:'#000'}}>
                                   REGISTER NOW!
                                </Col>
                          </Link>

                         </Row>
                     
  
                       </div>
                </div>
       
        </Form.Item>
            </Form>
    
          </div>
        );
    }
}

const FinalLoginAdmin = Form.create()(LoginAdmin);


function mapStateToProps(state) {
    return {
        login: state.login,
        isLogin: state.login.isRegistration
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ adminLogin: adminLogin }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(FinalLoginAdmin)
