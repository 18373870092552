import React,{Component} from 'react';
import {Button, Table} from 'antd';
import {fetchLevelsList} from "../../../actions/vendorsAction";
import {bindActionCreators} from "redux";
import {updateVendor} from "../../../actions/vendorsAction";
import {connect} from "react-redux";

class manageLevels extends Component{
    fetchLevelsList = ()=>{
        this.props.fetchLevelsList({
            token:this.props.token
        })
      };
      
       componentDidMount() {
           this.fetchLevelsList();
       }

    render(){
        console.log("Manage-level list",this.props.vendor)
        const columns = [
            { title: 'Level No', dataIndex: 'levelNo', key: 'levelNo' },
            { title: 'Level Name', dataIndex: 'levelName', key: 'levelName' },
            { title: 'Description', dataIndex: 'description', key: 'description' },
            {
              title: 'Action',
              dataIndex: '',
              key: 'x',
              render: (record) =>{
                  console.log('record',record);
                 return(
                     <div>
                         <Button type={'primary'} onClick={() => this.handleEdit()}>Edit</Button>&nbsp;&nbsp;&nbsp;
                         <Button type={'danger'} onClick={() => this.handleDelete()}>Delete</Button>
                     </div>
                 )
              }

            },
        ];


        return(
            <div>
                <Table
                    scroll={{ x: 'max-content' }}
                    columns={columns}
                    expandedRowRender={record => <p style={{ margin: 0 }}>{record.bank}</p>}
                    dataSource={this.props.vendorLevelsList}
                />,
            </div>
        )
    }

    handleEdit() {
        console.log('click to edit')
    }

    handleDelete() {
        console.log('click to delete')
    }
}

function mapStateToProps(state) {
    return {
        register: state.register,
        vendor: state.vendorReducer,
        token: state.auth.token,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ fetchLevelsList: fetchLevelsList }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(manageLevels)


