import React, {Component} from "react";
import {Button, Cascader, Icon, Table} from "antd";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import {editInvoice, invoicesList,updateInvoiveStatus,deleteInvoice} from "../../../actions/vendorsAction";
import {connect} from "react-redux";
import APPCONFIG from "../../../constants/appConfig";
class InvoicesList extends Component{
    viewInvoice = (record)=>{
        const url = `//${APPCONFIG.baseAPIUrl}/invoices/${record.uploadInvoiceCopy}`;
        window.open(url, '_blank');
    };

    handleDelete = (invoiceId) => {
        this.props.deleteInvoice({
            token:this.props.token,
            invoiceId:invoiceId,
        })
        console.log("invoices List Id in",invoiceId);
        this.props.updateMe()
        this.props.updateMe()

    };
    componentDidMount=()=> {

        const {token} = this.props;
        this.props.fetchInvoicesList({token})
    }

    render() {
        let options = [];
        console.log("Searched Invoice props",this.props);
        if(this.props.usersList){
            this.props.usersList.map((user=>{
                let opt = {
                    value: user._id,
                    label:user.name
                };
                options.push(opt)
            }))
        }

        const columns = [
            { title: 'Vendor Name', dataIndex: 'createdByVendor.companyName', key: 'createdByVendor' },
            { title: 'Invoice ID', dataIndex: '_id', key: 'invoiceId' },
            { title: 'Invoice No', dataIndex: 'invoiceNo', key: 'invoiceNo' },
            { title: 'Services Department', dataIndex: 'servicesDepartment', key: 'servicesDepartment' },
            { title: 'Total Services Cost', dataIndex: 'totalServicesCost', key: 'totalServicesCost' },
            { title: 'Total Cost', dataIndex: 'totalCost', key: 'totalCost' },
            { title: 'Total GST', dataIndex: 'totalGst', key: 'totalGst' },
            { title: 'Total IGST', dataIndex: 'totalIgst', key: 'totalIgst' },
            { title: 'Total Amount', dataIndex: 'totalAmount', key: 'totalAmount' },
            { title: 'TDS Applicable', dataIndex: 'tdsApplicable', key: 'tdsApplicable' },
            { title: 'TDS Percentage Input', dataIndex: 'tdsPercentageInput', key: 'tdsPercentageInput' },
            { title: 'Status', dataIndex: 'status', key: 'status' },
            { title: 'Assigned To', dataIndex: 'assignedTo.name', key: 'assignedTo.name'},
            { title: 'Invoice Copy', dataIndex: 'uploadInvoiceCopy', key: 'uploadInvoiceCopy' ,
                render: (code,record) =>
                    <div>
                        <Button type={'primary'} onClick={()=>this.viewInvoice(record)}>View</Button>
                    </div>
            },
            {
                title: 'Edit',
                dataIndex: '',
                key: 'x',
                render: (code,invoice) =>
                    <div>
                        {console.log("record,code",invoice,code)}
                        <span>
                              <Link to={{pathname : '/admin-dashboard/add-invoices',invoice:invoice}}>
                                    <Icon type="edit" theme="outlined" />
                                </Link>
                      </span>
                    </div>
            },
            {
                title: 'Delete',
                dataIndex: '',
                key: 'delete',
                render: (code,invoice) =>
                    <div>
                        {console.log("record,code",invoice,code)}
                        <span>
                              <Icon onClick={()=>this.handleDelete(invoice._id)} type="delete" theme="outlined" />

                      </span>
                    </div>
            },
            // {
            //     title: 'Action',
            //     dataIndex: '',
            //     key: 'y',
            //     render: (code,invoice,record) =>
            //         <div>
            //             {console.log("record,code",invoice,code)}
            //             {console.log("recordes invoice id",invoice._id)}
            //             {console.log("recordes Status",invoice.status)}

            //             <span>
            //              <Button  onClick={()=>this.handleApprove(invoice._id)} type={'primary'} style={{backgroundColor:'#388e3c'}}>Approve</Button>  &nbsp;&nbsp;&nbsp;
            //              <Button  onClick={()=>this.handleReject(invoice._id)} type={'danger'}>Reject</Button>
            //           </span>
            //         </div>
            // },
        ];
        return(
            <div>
                <Table
                    rowKey={record => record._id}
                    scroll={{ x: 'max-content' }}
                    columns={columns}
                    dataSource={this.props.searchInvoicesList}
                />,
            </div>
        )
    }

    handleReject=(invoiceId)=> {
        this.props.updateInvoiveStatus({
            token:this.props.token,
            status:'REJECTED',
            invoiceId:invoiceId,
        });
        this.componentDidMount()
    }

    handleApprove=(invoiceId)=> {
        this.props.updateInvoiveStatus({
            token:this.props.token,
            status:'APPROVED',
            invoiceId:invoiceId
        });
        this.componentDidMount()

    }
}


function mapStateToProps(state) {
    return {
        register: state.register,
        vendor: state.vendorReducer,
        searchInvoicesList:state.vendorReducer.searchInvoicesList,
        token: state.auth.token,
        usersList: state.users.usersList,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({deleteInvoice, fetchInvoicesList: invoicesList ,editInvoice:editInvoice,updateInvoiveStatus:updateInvoiveStatus}, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(InvoicesList)

