import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link, NavLink } from 'react-router-dom';

import 'antd/dist/antd.css';
import './login.modules.css'
// import MainNavigation from '../Navigation/mainNavbar'
// import '../Navigation/mainNavbar.css';
import { connect } from 'react-redux';
import { vendorLogin,resetPassword } from '../../../actions/vendorsAction';
import { userLogin } from '../../../actions/userAction';
import { adminLogin } from '../../../actions/adminAction';

import { bindActionCreators } from 'redux';

// import './index.css';
import {
  Form,
  Input,
  Select,
  Button,
  AutoComplete,
  Row, Col, Checkbox, Icon, Radio,Modal
} from 'antd';
const userLogo = require('../../../../src/user.jpg')

const { Option } = Select;
const AutoCompleteOption = AutoComplete.Option;


class LoginVendor extends Component {
  constructor(props) {
    super(props);
   this.state = {
      confirmDirty: false,
      autoCompleteResult: [],
      value: 1,
      visible: false,
      // vendoremail and vendorpassword for forgot password
      vendoremail: '',
      vendorpassword: '',
  
    };
  }

// handle change for forgot email
  handleChangeEmail = event => {
    this.setState({
      vendoremail: event.target.value
    });
  }

  // handle change for forgot password
  handleChangePassword = event => {
    this.setState({
      vendorpassword: event.target.value
    });
  }

  // Show forgot password modal
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  // Forgot password model handel OK method
  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  // Forgot password model handel cancel method
  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  onChange = e => {
    console.log('radio checked', e.target.value);
    this.setState({
      value: e.target.value,
    });
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  // onClick for reset password
  resetPassword = event => {
    console.log("values",this.state.vendoremail)
    console.log("values11",this.state.vendorpassword)

    this.props.resetPassword({
              vendorEmail: this.state.vendoremail,
              vendorPassword: this.state.vendorpassword
            })

  }

  //onClick for Vendor
  loginVendor = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);

        this.props.vendorLogin({
          vendorEmail: values.email,
          vendorPassword: values.password
        })
      }
    });
  };


  // onClick for departmental User
  loginDepartmentUser = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        this.props.userLogin({
          email: values.email,
          password: values.password
        })
      }
    })
  }

  // onClick for Management

  loginManagement = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        this.props.adminLogin({
          email: values.email,
          password: values.password
        })
      }
    });
  };

  // validate password  function
  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };


  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div className="div-curve">

        <div style={{ marginLeft: '200px', borderRadius: '50%', padding: '5px', height: '80px', width: '80px' }}>
          <img style={{ borderRadius: '50%', marginLeft: '5px' }} width='60px' height='60px' src={userLogo} />
        </div>
        {/* <div style={{padding:'5px',height:'30px',backgroundColor:'#4dd0e1'}}>
<h3 style={{textAlign:'center'}}>{`${this.state.value ? 'Login Vendor' : 'ADD'}`}</h3>
<h3 style={{textAlign:'center'}}>{`${this.state.value === 2 ? 'Login Department' : 'ADD'}`}</h3>
<h3 style={{textAlign:'center'}}>{`${this.state.value === 3 ? 'Login Management' : 'ADD'}`}</h3>
</div>  */}




        {this.state.value === 1 &&

          <div style={{ padding: '5px', height: '30px' }}>
            <h3 style={{ textAlign: 'center', color: '#FFF' }}>VENDOR LOGIN</h3>
          </div>
        }

        {this.state.value === 2 &&

          <div style={{ padding: '5px', height: '30px' }}>
            <h3 style={{ textAlign: 'center', color: '#FFF' }}>DEPARTMENTAL USER LOGIN</h3>
          </div>
        }

        {this.state.value === 3 &&

          <div style={{ padding: '5px', height: '30px', }}>
            <h3 style={{ textAlign: 'center', color: '#FFF' }}>MANAGEMENT LOGIN</h3>
          </div>
        }




        <div style={{ padding: '5px', height: '50%' }}>
          <Form>
            <div style={{ marginLeft: '45px', marginTop: '25px' }} className="gutter-example">
              <Row gutter={12}>
                <Col style={{ marginRight: '5px', height: '30px', marginTop: '11px' }} className="gutter-row" span={5}>
                  <div className="gutter-box">USERNAME:</div>
                </Col>
                <Col style={{ height: '30px' }} className="gutter-row" span={15}>
                  <div className="gutter-box">
                    {/* Email Id Field */}
                    <Form.Item>
                      {getFieldDecorator('email', {
                        rules: [
                          {
                            type: 'email',
                            message: 'The input is not valid E-mail!',
                          },
                          {
                            required: true,
                            message: 'Please input your E-mail!',
                          },
                        ],
                      })(<Input prefix={<Icon type="user" style={{ color: '#000' }} />} style={{}} placeholder="Please insert email ID" />)}
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <Row style={{ marginTop: '30px' }} gutter={12}>
                <Col style={{ marginRight: '5px', marginTop: '11px', height: '30px' }} className="gutter-row" span={5}>
                  <div className="gutter-box">PASSWORD:</div>
                </Col>
                <Col style={{ height: '30px' }} className="gutter-row" span={15}>
                  <div className="gutter-box">
                    {/* Password Field */}

                    {/* Password Field */}
                    <Form.Item hasFeedback>
                      {getFieldDecorator('password', {
                        rules: [
                          {
                            required: true,
                            message: 'Please input your password!',
                          },
                          {
                            validator: this.validateToNextPassword,
                          },
                        ],
                      })(<Input.Password prefix={<Icon type="lock" style={{ color: '#000' }} />} style={{ borderRadius: '50px' }} placeholder="Please insert password" />)}
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row style={{ paddingTop: '5%' }}>
                <Col>
                  <div style={{ textAlign: 'center', color: '#000e1a' }}>
                    <b><h4>LOGIN AS :</h4></b>
                  </div>
                  <div style={{ marginLeft: '10%', marginTop: '15px' }}>
                    <Radio.Group onChange={this.onChange} name="radiogroup" defaultValue={1}>
                      <Radio value={1}>Vendor</Radio>
                      <Radio value={2}>Departmental User</Radio>
                      <Radio value={3}>Management</Radio>
                    </Radio.Group>
                  </div>
                </Col>
              </Row>
            </div>

            <Modal
                title="Forgot Password"
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
              >
                <div>
                  <Form onSubmit={this.resetPassword} >
                  <Form.Item>
    <Input type="email" value={this.state.vendoremail} onChange={this.handleChangeEmail} prefix={<Icon type="user" style={{ color: '#000' }} />} style={{}} placeholder="Please insert email ID" required />
                    </Form.Item>

                    <Form.Item>
  <Input type={'password'} value={this.state.vendorpassword} onChange={this.handleChangePassword} prefix={<Icon type="lock" style={{ color: '#000' }} />} style={{ borderRadius: '50px' }} placeholder="Please insert password" required/>
                    </Form.Item>

                    <Button  style={{left:'35%', fontFamily: 'MyriadPro', width: '30%', backgroundColor: '#1F3F7A', color: 'white' }} htmlType="submit">
                    RESET
                  </Button>
                  </Form>
                </div>
        </Modal>

            <Form.Item>

              <div style={{ margin: 'auto', marginTop: '20px', padding: '10px', textAlign: 'center' }}>
                {this.state.value === 1 &&
                  <Button onClick={this.loginVendor} style={{ fontFamily: 'MyriadPro', width: '30%', backgroundColor: '#1F3F7A', color: 'white' }} htmlType="submit">
                    SIGN IN
                  </Button>
                }
                {this.state.value === 2 &&
                  <Button onClick={this.loginDepartmentUser} style={{ fontFamily: 'MyriadPro', width: '30%', backgroundColor: '#1F3F7A', color: 'white' }} htmlType="submit">
                    SIGN IN
                  </Button>
                }
                {this.state.value === 3 &&
                  <Button onClick={this.loginManagement} style={{ fontFamily: 'MyriadPro', width: '30%', backgroundColor: '#1F3F7A', color: 'white' }} htmlType="submit">
                    SIGN IN
                  </Button>
                }
                <div>

                  <Row style={{ bottom: '14%' }}>

                    <Col onClick={this.showModal} style={{visibility:`${this.state.value === 2 || this.state.value === 3 ? 'hidden' : ''}`}} span={10}>
                      FORGOT PASSWORD
                    </Col>
                    <Link to="/registrationVendor">
                      <Col style={{ left: '10%', textDecoration: 'underline' }}  span={12}>
                        GO TO VENDOR REGISTRATION
                      </Col>
                    </Link>
                  </Row>
                </div>
              </div>

            </Form.Item>
          </Form>
        </div>
      </div>

    );
  }
}

const FinalLoginVendor = Form.create()(LoginVendor);


function mapStateToProps(state) {
  return {
    login: state.login,
    isLogin: state.login.isRegistration
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({resetPassword:resetPassword, vendorLogin: vendorLogin, userLogin: userLogin, adminLogin: adminLogin }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(FinalLoginVendor)