import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Form,
    Input,
    Tooltip,
    Icon,
    Cascader,
    Select,
    Row,
    Col,
    Upload,
    Checkbox,
    Button,
    AutoComplete,
} from 'antd';
import {addDepartments} from '../../../actions/vendorsAction'
import {compose} from "recompose";
import {withRouter,Link} from "react-router-dom";

class AddDepartments extends React.Component {

    constructor(props) {
        super(props);
        if(this.props.location && this.props.location.department){
            const { _id } = this.props.location.department;
            console.log("departmentId",_id);
        }
        console.log(props)
        const { match: { params } } = this.props;
        this.state = {
            departmentId: (this.props.location.department && this.props.location.department._id),
            initialValue: {},
            confirmDirty: false,
            autoCompleteResult: [],
        };
    }

    normFile = e => {
        this.setState({file:e.fileList[0]});
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;

    };

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
          if (!err) {
            console.log('Received values of form: ', values);
            const {departmentId} = this.state;
            this.props.addDepartments(
             {
                 departmentId:departmentId,
                departmentName:values.departmentName,
                 numberOfLevels:values.numberOfLevels,
                token:this.props.token,
                 createdByAdmin:this.props.adminId
             } 
            )
            // this.props.history.push('/admin-dashboard/manage-levels')
            this.setState({
                initialValue: {},
            })
                        this.props.form.resetFields();
          }
        });
      };

    componentDidMount() {
        this.setInitialValue();
    }
    setInitialValue() {
        const { departmentId } = this.state;
        const {departmentsList} = this.props;
        console.log("levelId",departmentId);
        if (departmentId && departmentsList.length > 0) {
            let department = departmentsList.find(department => department._id === departmentId);
            if (department) {
                console.log("Initial Value department",department);
                this.setState({
                    initialValue: department,
                });
            }
        }
    }


    render() {
        const { getFieldDecorator } = this.props.form;
        console.log('level meta is',this.props.levelMeta);
        const formItemLayout = {
            labelCol: {
                xs: { span: 5 },
                sm: { span: 5 },
            },
            wrapperCol: {
                xs: { span: 5 },
                sm: { span: 15 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };

        const { initialValue, departmentId } = this.state;

// Changes in UI (change by: omkar)
        return (

            <div>
                <div className="box-header">
                    <h2>{`${departmentId ? 'Update' : 'Add'} Deapartment`}</h2>
                </div>
                <div style={{boxShadow:'0 4px 8px 0 rgba(0,0,0,0.2)',
                    transition: '2.5s',
                    width:'60%',
                    borderRadius:'15px',
                    margin: '0 auto 0 auto',
                    marginTop:'20px',
                    backgroundColor:'#FFF'

                }}>
                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                <div style={{alignItems:'center',paddingTop:20,paddingLeft:'20px'}}>
<Form.Item label="Department Name" hasFeedback>
    {getFieldDecorator('departmentName', {
        rules: [
            {
                required: true,
                message: 'Please input your Department Name!',
            },
        ],
        initialValue: initialValue.departmentName,

    })(<Input placeholder="Enter Department Name" style={{marginLeft:'10px'}}/>)}
</Form.Item>
    <Form.Item label="Number of Levels">
        {getFieldDecorator('numberOfLevels', {
            rules: [
                {
                    required: true,
                    message: 'Please input Number of Levels'
                },
            ],
            initialValue: initialValue.numberOfLevels,

        })(<Input placeholder="Enter Number Of Levels" style={{marginLeft:'10px'}}/>)}
    </Form.Item>


<Form.Item {...tailFormItemLayout}>
    <Button type="primary" htmlType="submit" style={{marginBottom:30,marginLeft:'20px'}}>
        {`${departmentId ? 'UPDATE' : 'ADD'}`}
    </Button>
</Form.Item>
</div>
</Form>
                </div>
            </div>
            
        );
    }
}



const WrappedLogin = Form.create()(AddDepartments);


function mapStateToProps(state) {
    let userId = null;
    userId = (state.auth.meta && state.auth.meta.data && state.auth.meta.data._id ) ? state.auth.meta.data._id : null;
  return {
      register: state.register,
      isRegistration: state.register.isRegistration,
      levelMeta : state.vendorReducer.levelMeta,
      token:state.auth.token,
      adminId:userId,
      departmentsList:state.vendorReducer.levelsList
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({ addDepartments: addDepartments }, dispatch)
}
export default compose(
    withRouter,
    connect(mapStateToProps, matchDispatchToProps),
)(WrappedLogin);
