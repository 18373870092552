import * as types from '../constants/actionsTypes'

const initialSettings = {
    userListLoading: false,
    usersList: [],
    saveUserLoading: false,
    saveUserStatus: null,
    authToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1YjcxY2VhYjIwZmMwYzY3NWI0NjdhYzUiLCJuYW1lIjoiUG9wZXllIEQgU2FpbG9yIiwicGhvbmUiOm51bGwsImVtYWlsIjoicG9wZXllQGdtYWlsLmNvbSIsInBlcm1pc3Npb25zIjpbIioiXSwiaWF0IjoxNTM0MTg1Mjk3LCJleHAiOjE1MzQ3OTAwOTd9.dWAqJkz7msPSbF9lMb3L7HZIViR3ryhoZJq4vV7qrnI',

        // docs purchase order
        purchaseOrderMeta:[],
        purchaseOrderList:[],
        docsPurchaseOrderLoading:false,

        // docs Add Items
        itemsMeta:[],
        itemsList:[],
        docsItemsLoading:false,
    purchaseOrderNumber:null

};

const settings = (state = initialSettings, action) => {
    switch (action.type){
        case types.CHANGE_USER_LIST:
            return {
                ...state,
                userListLoading: false
            };

        case types.REQUEST_USER_LIST:
            return {
                ...state,
                userListLoading: true
            };

        case types.RESPONSE_USER_LIST:
            console.log("users reducer ",action.data);
            return {
                ...state,
                usersList: action.data.usersList,
                userListLoading: false
            };

        case types.REQUEST_USER_SAVE:
            return {
                ...state,
                saveUserLoading: true,
                saveUserStatus: null
            };

        case types.RESPONSE_USER_SAVE:
            let saveUserStatus = { error: false };
            if(action.data.error){
                saveUserStatus = action.data
            }

            return {
                ...state,
                saveUserLoading: false,
                saveUserStatus: saveUserStatus
            };



                    // Fetch PurchaseOrder Reducers
                    case types.REQUEST_PURCHASE_ORDER_FETCH:
                        return {
                            ...state,
                            docsPurchaseOrderLoading: true,
                        };
                case types.RESPONSE_PURCHASE_ORDER_FETCH:
                const purchaseOrderList = action.data && action.data.data  ? action.data.data : null;
                console.log('PurchaseOrderList',purchaseOrderList);
                return {
                    ...state,
                    docsPurchaseOrderLoading: false,
                    purchaseOrderList: purchaseOrderList,
                };

        case types.REQUEST_PO_NUMBER:
            return {
                ...state,
                docsPurchaseOrderLoading: true,
            };
        case types.RESPONSE_PO_NUMBER:
            const purchaseOrderNumber = action.data && action.data.data ? action.data.data : null;
            console.log('purchaseOrderNumber',purchaseOrderNumber);
            return {
                ...state,
                docsPurchaseOrderLoading: false,
                purchaseOrderNumber: purchaseOrderNumber,
            };
                // Add Purchase Order Reducer

                case types.REQUEST_PURCHASE_ORDER_ADD:
                    return {
                        ...state,
                        docsPurchaseOrderLoading: true,
                    };
        
                case types.RESPONSE_PURCHASE_ORDER_ADD:
                  const purchaseOrderMeta2 = action.data && action.data.data  ? action.data.data : null;
                  console.log('Purchase Order',purchaseOrderMeta2);
                return {
                     ...state,
                     docsPurchaseOrderLoading: false,
                     purchaseOrderMeta: purchaseOrderMeta2,
                 };

                // Update Purchase Order Status
                        case types.REQUEST_PURCHASE_ORDER_UPDATE:
                            return {
                                ...state,
                                docsPurchaseOrderLoading: true,
                            };

                                  // Fetch Items Reducers
                          case types.REQUEST_ITEMS_FETCH:
                              return {
                                     ...state,
                                      docsItemsLoading: true,
                                 };
                            case types.RESPONSE_ITEMS_FETCH:
                            const itemsList = action.data && action.data.data  ? action.data.data : null;
                            console.log('ItemsList',itemsList);
                            return {
                                ...state,
                                docsItemsLoading: false,
                                itemsList: itemsList,
                            };


                     // Add Items Reducer

                case types.REQUEST_ITEMS_ADD:
                    return {
                        ...state,
                        docsItemsLoading: true,
                    };
        
                case types.RESPONSE_ITEMS_ADD:
                  const itemsMeta2 = action.data && action.data.data  ? action.data.data : null;
                  console.log('Purchase Order',itemsMeta2);
                return {
                     ...state,
                     docsItemsLoading: false,
                     itemsMeta: itemsMeta2,
                 };


                         // Fetch Items Reducers
            case types.REQUEST_ITEMS_FETCH:
                return {
                    ...state,
                    docsItemsLoading: true,
                };
        case types.RESPONSE_ITEMS_FETCH:
        const ItemsList = action.data && action.data.data  ? action.data.data : null;
        console.log('ItemsList',ItemsList);
        return {
            ...state,
            docsItemsLoading: false,
            itemsList: ItemsList,
        };

        default:
            return state;
    }
};

export default settings
