import * as types from '../constants/actionsTypes'

const initialSettings = {
    isLoggedIn: false,
    loading: false,
    meta: {},
    token: null,
    permissions:null,
    status:null
};

const settings = (state = initialSettings, action) => {
    switch (action.type){
        case types.AUTH_DO_LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                loading: false,
                meta: {},
                authToken: null
            };

        case types.AUTH_REQUEST_LOGIN:
            return {
                ...state,
                authToken: null,
                meta: {},
                loading: true,
                isLoggedIn: false
            };

        case types.AUTH_RESPONSE_LOGIN:
            console.log('Action',action)
            const token = action.data && action.data.data && action.data.data.token ? action.data.data.token : null;
            const permissions = action.data && action.data.data && action.data.data.permissions ? action.data.data.permissions : null;
            const status = action.data && action.data.data && action.data.data.status ? action.data.data.status : null;

            console.log('token',token);
            return {
                ...state,
                meta: action.data,
                isLoggedIn: !!token,
                token: token,
                loading: false,
                permissions: permissions,
                status: status
            };


        default:
            return state;
    }
};

export default settings
