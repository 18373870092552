import React, { Component, useRef  } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {compose} from "recompose";
import ReactToPrint from "react-to-print";
import Pdf from "react-to-pdf";
import moment from 'moment'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import $ from 'jquery'
import {Button,Table, List, Icon, Col, Pagination, Row, Cascader} from 'antd';
import Example from './onPrint'
import './purchaseOrder.modules.css'
// import './orderPrint.css'
const userLogo = require('../../../../src/BDOLogo.png')
const ref = React.createRef();
const options = {
    orientation: 'portrait',
    unit: 'cm',
    format: [1000.0 ,1050.7]
};

class PrintOrder extends Component{
    
    constructor(props) {
        super(props);
        if(this.props.location && this.props.location.state){
            // const { _id } = this.props.location.state.purchaseOrderId;
            console.log("PurchaseOrder",this.props.location.state.purchaseOrder);
        }
        console.log(props)
        const { match: { params } } = this.props;
        this.state = {
            purchaseOrder: (this.props.location.state && this.props.location.state.purchaseOrder),
            initialValue: {},
            confirmDirty: false,
            autoCompleteResult: [],
            purchaseOrderNo:'',
            pagination: {},
            page : 1,

        };
    }

    printOrder = () => {
      //old Code
       // var mywindow = window.open('','_blank', 'PRINT', 'height=autoCompleteResult,width=100%');  
       // const printableElements = document.getElementById('printme');
       // mywindow.document.write('<html><head><title></title><link rel="stylesheet" href="src/orderPrint.css" type="text/css"/>');
       // mywindow.document.write('</head><body style="font-size:10px;font-family:bold;">');
       // mywindow.document.write(printableElements.innerHTML);
       // mywindow.document.write('</body></html>');
       // mywindow.document.close();
       // mywindow.focus();
       // mywindow.print();
       //mywindow.close();

     //new update
      var sOption="toolbar=yes,location=no,directories=yes,menubar=yes,scrollbars=yes,width=900,height=300,left=100,top=25";
      var sWinHTML = document.getElementById('printme').innerHTML;
      var winprint=window.open("","",sOption);
      winprint.document.open();
      winprint.document.write('<html><head><link href="https://fonts.googleapis.com/css?family=Roboto&display=swap" rel="stylesheet"><link rel="stylesheet" href="orderPrint.css"/><style type="text/css">');
      winprint.document.write('@media print{@page {size: A4 landscape;margin: 0.5cm;} .pagebreak{clear:both;page-break-after:left;}}');      
      winprint.document.write('.my-ant-table{-webkit-box-sizing: border-box;box-sizing: border-box;margin: 0;padding: 0;color: rgba(0, 0, 0);font-size: 14px;position: relative;z-index: 0;clear: both;background: #fff;}');
      winprint.document.write('.my-ant-table .ant-table table{width: 100%;border-collapse: collapse;text-align: left;border-radius: 2px 2px 0 0;}');
      winprint.document.write('.my-ant-table .ant-table td, th {border: 1px solid black;}');
      winprint.document.write('.my-ant-table .ant-table thead tr th{background-color: #ececec;font-weight: 500;text-align: left;padding: 16px 16px;overflow-wrap: break-word;}')
      winprint.document.write('.my-ant-table .ant-table tbody tr td {position: relative;padding: 10px 10px;overflow-wrap: break-word;}');
      winprint.document.write('.vendor-details p{line-height: 1 !important;}.title-bold p span{font-weight: bold;} table{width: 100%;}table>tr>td{padding: 0px;}');
      winprint.document.write('table tr td div{padding:8px;}.print-header{background-color: #315dfb !important;} .head{color:#FFF !important;padding:5px;font-size:20px;} #printme table{width:100%;}#printme .innerTable{border:0px !important;}#printme .innerTable tr td{font-weight: 400;}tr.border_bottom td {border-bottom:1pt solid black;}');
      winprint.document.write('.title-bold p{margin:0px;}.bottom-pr p{margin-bottom: 0px;}');
      winprint.document.write('.img-logo img{padding-right:20px;}');
      winprint.document.write('p{font-size: 14px;margin: 0 0 1em;line-height: 1.4285em;}');
      winprint.document.write('</style></head><body style="font-family: Roboto, sans-serif;" onload="window.print();">');      winprint.document.write(sWinHTML);
      winprint.document.write('</body></html>');
      winprint.document.close();
      winprint.focus();
   }

    render(){
        let purchaseOrderNoOptions = [];
        let shippingAddressOptions = [];
        let deliveryAddressOptions = [];
        let totalAmountOptions = [];
        let createdDateOptions = [];
        let purchaseItemsOptions = [];
        let vendorCompanyNameOptions = [];
        let vendorAddressOptions = [];
        let vendorEmailIdOptions = [];
        let vendorMobileOptions = [];
        let createdByUserOptions = [];
        let approvedByOptions = [];
        let PoStatusOptions = [];
        let columns = [
            {
                title: 'S.No',
                key: 'index',
                // width: '7%',
                render :(text, record, index) => index+1
            },
            {
                title: 'Item Name',
                dataIndex: 'itemName',
                // width: '15%',
            },
            {
                title: 'Description',
                dataIndex: 'description',
                // width: '15%',
            },

            {
                title: 'Part No',
                dataIndex: 'partNumber',
                // width: '10%',
            },

            { title: 'Price(Rs)', 
            dataIndex: 'price',
            // width: '8%',
            render:(record)=>{
                console.log("Price in record",record)
                let newPrice = parseFloat(record).toFixed(2);
                console.log("newPrice",newPrice);
                return(
                    <div>
                        {newPrice}
                    </div>
                )
            }
            },
            { title: 'Tax Rate(%)', 
            dataIndex: 'taxRate',
            // width: '9%',
            },
            { title: 'Quantity', 
            dataIndex: 'quantity',
            // width: '10%',
            },
            { title: 'Amount', 
            dataIndex: 'amount',
            // width: '10%',
            render:(record)=>{
                console.log("Amount in record",record)
                let newAmount = parseFloat(record).toFixed(2);
                console.log("newAmount",newAmount);
                return(
                    <div>
                        {newAmount}
                    </div>
                )
            }
            },
        ];

        console.log("purchase order in render",this.state.purchaseOrder)
        console.log("UserName in printtPurchase Order",this.props.userName)
        const purchaseOrder = this.props.location && this.props.location.state && this.state.purchaseOrder
        if(purchaseOrder){
            console.log("PurchaseOrder in If Condition",purchaseOrder.itemsDetails);
            let purchaseOrderNo = purchaseOrder.purchaseOrderNo
            console.log("purchase order no after if",purchaseOrderNo)
            purchaseOrderNoOptions.push(purchaseOrderNo)
            // this.setState({
            //     purchaseOrderNo:purchaseOrderNo
            // })
        }
        if(purchaseOrder){
            console.log("created by user in if",purchaseOrder.createdByUser.name)
            createdByUserOptions.push(purchaseOrder.createdByUser.name)
        }
        const approved = purchaseOrder && purchaseOrder.approvedBy && purchaseOrder.approvedBy.name ? purchaseOrder.approvedBy.name : 'Not yet approved'
        console.log("ApprovedBy",approved)
        approvedByOptions.push(approved)
        if(purchaseOrder){
            // console.log("PurchaseOrder in If Condition",purchaseOrder.shippingAddress);
            let shippingAddress = purchaseOrder.shippingAddress
            console.log("purchase order no after if",shippingAddress)
            shippingAddressOptions.push(shippingAddress)
        }
        if(purchaseOrder){
            console.log("PurchaseOrder in If Condition",purchaseOrder.deliveryAddress);
            let shippingAddress = purchaseOrder.deliveryAddress
            console.log("purchase order no after if",shippingAddress)
            deliveryAddressOptions.push(shippingAddress)
        }


        if(purchaseOrder){
            console.log("created date in If Condition",purchaseOrder.createdDate);
            let createdDate = new Date(purchaseOrder.createdDate)
            console.log("created date after if",createdDate)
            // createdDateOptions.push(createdDate.toISOString().split('T')[0])
            let dateFormat = createdDate.toLocaleDateString("en-US")
            console.log("dateFormat",dateFormat)
            let convertedDate = moment(dateFormat).format('DD-MM-YYYY');
            createdDateOptions.push(convertedDate)
            console.log("convertedDate",convertedDate)
        }
        if(purchaseOrder){
            console.log("totalAmount in If Condition",purchaseOrder.totalAmount);
            let totalAmount = purchaseOrder.totalAmount
            let newTotalAmount = parseFloat(totalAmount).toFixed(2)
            console.log("purchase order no after if",newTotalAmount)
            totalAmountOptions.push(newTotalAmount)
        }
        if(purchaseOrder){
            console.log("Status is",purchaseOrder.status)
            let status = purchaseOrder.status
            PoStatusOptions.push(status)

        }

        // vendor details
        if(purchaseOrder){
            console.log("vendor details company name",purchaseOrder.vendor.companyName);
            let vendorCompanyName = purchaseOrder.vendor.companyName
            vendorCompanyNameOptions.push(vendorCompanyName)
        }
        if(purchaseOrder){
            console.log("vendor details email",purchaseOrder.vendor.vendorEmail);
            let vendorEmailId = purchaseOrder.vendor.vendorEmail
            vendorEmailIdOptions.push(vendorEmailId)
        }
        console.log("vendorEMail",vendorEmailIdOptions)
        if(purchaseOrder){
            console.log('purchaseOrder.././',purchaseOrder)
            console.log("vendor details",purchaseOrder.vendor);
            let vendorAddress = purchaseOrder.vendor.address
            vendorAddressOptions.push(vendorAddress)
        }
        console.log("vendorAddressOptions",vendorAddressOptions)
        if(purchaseOrder){
            console.log("vendor details",purchaseOrder.vendor.phoneNo);
            let vendorNo = purchaseOrder.vendor.phoneNo
            vendorMobileOptions.push(vendorNo)
        }

        if(purchaseOrder){
            console.log("purchase items in if",purchaseOrder.itemsDetails)
            if(purchaseOrder.itemsDetails){
                purchaseOrder.itemsDetails.map((items=>{
                    console.log('.map purchaseOrder in 2nd if order',items.partNumber)
                    let opt = {
                        itemName: items.itemName.itemName,
                        partNumber:items.partNumber,
                        price:items.price,
                        quantity:items.quantity,
                        taxRate:items.taxRate,
                        amount:items.amount,
                        description:items.description
                    }
                    purchaseItemsOptions.push(opt)
                }))
                console.log('Purchase Order in push....',purchaseItemsOptions)

            }
        }
        


        return(
<div>
      <Button type={'primary'} style={{marginBottom:'5px',left:'88%'}} onClick={() => this.printOrder()}>Print</Button>
      <div id="printme">    
        <div style={{width:'98%',margin:'auto'}}>   
          <div  style={{backgroundColor:'#FFF',border:'1px solid black'}}>

          <table style={{width:'100%'}}>
          <col width="15%"/><col width="*"/><col width="25%"/>
          {/*  1 row Header of print */}
          <tr className="print-header" style={{backgroundColor: '#315dfb'}}>
            <td colspan="3">
                <div className="print-header">
                    <h3 className="head" style={{color:'#fff',padding:'6px 0px ', fontSize:'20px', margin:'0px'}}>Purchase Order</h3>
              </div>
            </td>
          </tr>

          {/* 2 row of print */}
          <tr>

            {/* 2.1 Logo of print */}
            <td valign = "top">
              <div className="img-logo">
                  <img height="auto" width="150px"  src={userLogo}/>
              </div>
            </td>

            {/* 2.2 row of print */}
            <td valign = "top">
            
              {/* Add deails of print */}
              <table className="innerTable">

                {/* Add of print */}
                <tr>
                  <td>
                    <div>
                        <strong>BDO India LLP,</strong>
                        <p>
                        The Ruby, Level 9, NW Wing <br/>
                        Senapati Bapat Marg, Dadar West,<br/>
                        Mumbai - 400028, Maharashtra,<br/>
                        INDIA.
                        </p>
                    </div>
                  </td>
                </tr>

                {/* Contact deails of print */}
                <tr>
                  <td>
                    <div>
                        <span className="title-bold">
                            <p style={{lineHeight: '1.4285em',}}><span>Phone: </span>022-33321646</p>
                            <p style={{lineHeight: '1.4285em',}}><span>Fax: </span>022-33321700</p>
                            <p style={{lineHeight: '1.4285em',}}><span>Email: </span>arundwivedi@bdo.in</p>
                        </span>
                    </div>
                  </td>
                </tr>

              </table>
            </td>

            {/* 2.3 row of print */}
            <td valign = "top">
            
              {/* Order details of print */}
              <table className="innerTable">
                <tr>
                  <td>
                    <div><strong>Purchase Order</strong></div>
                    <div>
                        <p>
                        <span style={{fontWeight:'bold'}}>Order No:</span> {purchaseOrderNoOptions}
                        </p>
                        <p><span style={{fontWeight:'bold'}}>Created Date:</span>{createdDateOptions}</p>
                    </div>
                  </td>
                </tr>   

              </table>
            </td>

          </tr>
        </table>

        <table style={{width:'100%',borderTop:'1px solid #333'}}>
        <col width="33.3%"/><col width="33.3%"/><col width="33.3%"/>
          {/* 3 row of print */}
          <tr>

            {/* 3.1 Logo of print */}
            <td valign = "top">
            
              {/* Vender deails of print */}
              <table className="innerTable">

                {/* Vender add of print */}
                <tr>
                  <td>
                    <div><strong>Vendor Details</strong></div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="vendor-details">
                        <p><strong>Company Name:</strong> &nbsp;&nbsp; {vendorCompanyNameOptions}</p>
                      <p><strong>Address:</strong> &nbsp;&nbsp; {vendorAddressOptions}</p>
                        <p><strong>Email Id:</strong> &nbsp;&nbsp; {vendorEmailIdOptions}</p>
                        <p><strong>Mobile No:</strong> &nbsp;&nbsp; {vendorMobileOptions}</p>

                    </div>
                  </td>
                </tr>
              </table>
            </td>

            {/* 3.2 row of print */}
            <td valign = "top">
            
              {/* Shipping deails of print */}
              <table className="innerTable">

                {/* Shipping Add Opions of print */}
                <tr>
                  <td>
                    <div><strong>Shipping Address</strong></div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <p>
                      {shippingAddressOptions}
                      </p>
                    </div>
                  </td>
                </tr>

              </table>
            </td>

            {/* 3.3 row of print */}
            <td valign = "top">
            
              {/* Bill details of print */}
              <table className="innerTable">
                <tr>
                  <td>
                    <div><strong>Billing Address</strong></div>
                  </td>
                </tr>
                <tr>
                  <td>
                  <div>
                    <p>
                    {deliveryAddressOptions}
                    </p>
                  </div>
                  </td>
                </tr>

              </table>
            </td>

          </tr>
        </table>


        {/* <hr/> */}

                  {/* List of Items Table */}
                      <Row style={{padding:'15px 5px',borderTop:'1px solid #111',borderBottom:'1px solid #111'}}>
                          <Col className="gutter-row" span={24}>
                            <div className="gutter-box">
                              <Table className="my-ant-table"
                                  bordered
                                  rowKey={record => record._id}
                                  columns={columns}
                                  dataSource={purchaseItemsOptions}
                                  pagination={false}           
                              />
                            </div>
                          </Col>

                          {/* Total Amount Total Section */}

                          <Col style={{width:'100%' ,textAlign:'right', paddingRight:'10px',marginTop:'10px'}} className="gutter-row" span={6}>
                          <table style={{width:'100%'}}>
                              <col width="88.5%"/><col width="*"/>
                                <tr>
                                  <td valign="top" style={{fontWeight:'bold',textAlign:'right'}}>Total Amount&nbsp;:</td>
                                  <td valign="top"  style={{textAlign:'left'}}>&nbsp;{totalAmountOptions}</td>
                                </tr>
                              </table>
                            </Col>
                      </Row>
                  {/* <hr/> */}
                  {/* <div className="pagebreak"></div> */}
                        <div style={{flex:1}}>
                        <Row style={{padding:'5px'}}>
                                        <Col className="gutter-row" span={24}>
                                          <div className="gutter-box" style={{padding: '8px',margin: '0px 10px'}}>
                                              <p style={{fontWeight:'bold'}}>Terms & Conditions:-</p>
                                              
                                                  <p>
                                                  1) Rates are Inclusive of Loading at your end. Rates shall remain fixed till complete supply and Installation.
                                                  </p>
                                                  <p>
                                                  2) Payment Terms: 90 days against delivery 
                                                  </p>
                                                  <p>
                                                  3) Contact Person - Arun Dwivedi- (arundwivedi@bdo.in)
                                                  </p>
                                                  <p>
                                                  4) Installation shall be at free of cost. You shall provide a warranty for One year or applicable period.
                                              </p>
                                              <p>
                                              5) Transportation is included in the above price.
                                              </p>
                                            
                                          </div>
                                        
                                        </Col>
                  </Row>


                  <Row style={{padding:'5px'}}>
                            <Col className="gutter-row" span={24}>
                              <div className="gutter-box" style={{padding: '8px',margin: '0px 10px'}}>
                                <p><span style={{fontWeight:'bold'}}>Note :-</span> This is system generated purchase order hence signed are not required.</p>
                              </div>
                            </Col>
                  </Row>
                  <hr/>
                  <Row style={{padding:'5px',marginTop:'10px'}}>
                      <Col className="gutter-row" span={24}>
                         <div className="gutter-box" style={{padding: '8px',margin: '0px 10px'}}>
                            <p><span style={{fontWeight:'bold'}}>PO Status:-</span>&nbsp;&nbsp;&nbsp;&nbsp;{PoStatusOptions}</p>

                            <p><span style={{fontWeight:'bold'}}>Created By:-</span>&nbsp;&nbsp;&nbsp;&nbsp;{createdByUserOptions}</p>
                            
                                <p><span style={{fontWeight:'bold'}}>Approved By:-</span>&nbsp;&nbsp;&nbsp;&nbsp;{approvedByOptions}</p>
                          </div>
                      </Col>
                  </Row>
              </div>
              
          </div>
          </div>  
          </div>   

    </div>
        
        )
    }
}


// const PrintPO = () => {
//     const componentRef = useRef();
//     return (
//         <div>
//             <PrintOrder ref={componentRef} />
//             <ReactToPrint
//                 trigger={() => <Button style={{marginLeft:450,marginTop:100}} type={'primary'}>Print this out!</Button>}
//                 content={() => componentRef.current}
//             />
//         </div>
//     );
// };


function mapStateToProps(state) {
    let userId = null;
    userId = (state.auth.meta && state.auth.meta.data && state.auth.meta.data._id ) ? state.auth.meta.data._id : null;
  return {
      register: state.register,
      isRegistration: state.register.isRegistration,
      InvoicesList:state.vendorReducer.invoicesList,
      token:state.auth.token,
      user:state.auth.meta,
      vendorId:userId,
      levelsList: state.vendorReducer.levelsList
  };
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({ }, dispatch)
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)(PrintOrder);



