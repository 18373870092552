import React, { Component } from 'react'
import { Table, Form, Col, Button, Icon } from 'antd';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose } from "recompose";
import { updateRFQQuotation } from "../../actions/userAction";
import moment from "moment";

class RFQQuotation extends Component {

    state = {// Check here to configure the default column
        loading: false,
        pagination: {},
        items: [],
    };

    constructor(props) {
        super(props);
        const itemArray = ['Keyboard', 'MachineDril', 'Monitor', 'Mouse', 'Papers', 'GrindingMachine'];
        const percentages = [10, 18, 15, 20];
        for (let i = 0; i < 6; i++) {
            const actionState = Math.random();
            let quantity = Math.round((actionState * 10));
            while (quantity <= 0) {
                quantity = Math.round((Math.random() * 10))
            }
            const amount = (Math.random() * 1000);
            const fee = percentages[Math.round(actionState * 10) % 4];
            const percentageValue = (fee * amount) / 100;
            this.state.items.push({
                key: i,
                delivery: this.randomDate(new Date(2020, 0, 1), new Date(), 0, 24),
                item: itemArray[i],
                quantity: quantity,
                amount: (amount * quantity).toFixed(2),
                value: ((amount * quantity) + percentageValue).toFixed(2),
                t: fee,
                unitRate: amount.toFixed(2),
            });
        }
    }

    generateComparative = () => {
        this.setState({ loading: true });
        // ajax request after empty completing
        setTimeout(() => {
            this.setState({
                selectedRowKeys: [],
                loading: false,
            });
        }, 1000);
    };

    click = (e) => {
        console.log("data is", e);
    }

    randomDate(start, end, startHour, endHour) {
        var date = new Date(+start + Math.random() * (end - start));
        var hour = startHour + Math.random() * (endHour - startHour) | 0;
        date.setHours(hour);
        return date;
    }

    onSelectChange = selectedRowKeys => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };

    render() {
        // const [page, setPage] = React.useState(1);
        const columns = [
            {
                title: '#',
                dataIndex: 'key',
                width: '5%',
                render: (key) => key + 1,
            },
            {
                title: 'Item',
                dataIndex: 'item',
                // sorter: (a, b) => b.item - a.item,
                // render: text => <Link to={'/user-dashboard/rfqQuotation'}>{text}</Link>
                // with: '20%',
            },
            {
                title: 'Quantity',
                dataIndex: 'quantity',
                sorter: (a, b) => b.quantity - a.quantity,
                // with: '30%',
            },
            {
                title: 'Unit Rate',
                dataIndex: 'unitRate',
                sorter: (a, b) => b.amount - a.amount,
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                // defaultSortOrder: 'descend',
                sorter: (a, b) => b.amount - a.amount,
                // with: '20%',
            },
            {
                title: 'T %',
                dataIndex: 't',
                sorter: (a, b) => b.t - a.t,
                render: (record) => { return `${record}%` }
            },
            {
                title: 'Value',
                dataIndex: 'value',
                sorter: (a, b) => b.value - a.value
            },
            {
                title: 'Delivery',
                dataIndex: 'delivery',
                sorter: (a, b) => b.delivery - a.delivery,
                key: 'x',
                // with: '30%',
                render: (record) => {
                    console.log("created date record", record)
                    let newDate = new Date(record)
                    // console.log("newDate",newDate.toISOString().split('T')[0])
                    let dateFormat = newDate.toLocaleDateString("en-US")
                    console.log("dateFormat", dateFormat)
                    let convertedDate = moment(dateFormat).format('DD-MM-YYYY');
                    return (
                        <div>
                            {convertedDate}
                        </div>
                    )
                }
            },
        ];



        // const hasSelected = selectedRowKeys.length > 0;
        return (
            <div>
                <div style={{ textAlign: 'center', width: '100%' }}>
                    <h1>View Quotation </h1>
                </div>
                <div style={{ margin: '5px', fontSize: '18px' }} >
                    <strong>Vendor Id:</strong> xxxx
                </div>
                <div style={{ margin: '5px', fontSize: '18px' }}>
                    <strong>Vendor Name:</strong> xxxx
                </div>
                <div style={{ margin: '5px', fontSize: '18px' }}>
                    <strong>Vendor Address:</strong> xxx,xxx - xxxx
                </div>
                <div style={{ margin: '15px 0px', }}>
                    <h2>RFQ Response</h2>
                </div>
                <Table columns={columns} dataSource={this.state.items} pagination={this.state.pagination} />

                <div style={{ float: 'left' }}>
                    <Col style={{ left: '7%' }} className="gutter-row" span={8}>
                        <div className="gutter-box">
                            <Button style={{ backgroundColor: '#549914', color: '#FFF' }}>
                                <Icon type="check" />Approve</Button>
                        </div>
                    </Col>
                    <Col style={{ left: '30%' }} className="gutter-row" span={8}>
                        <div className="gutter-box">
                            <Button type={'danger'} style={{ color: '#FFF' }}>
                                <Icon type="close" />Reject</Button>
                        </div>
                    </Col>
                </div>

            </div>
        );
    }
}

function mapStateToProps(state) {
    let userId = null;
    userId = (state.auth.meta && state.auth.meta.data && state.auth.meta.data._id) ? state.auth.meta.data._id : null;
    return {
        register: state.register,
        isRegistration: state.register.isRegistration,
        InvoicesList: state.vendorReducer.invoicesList,
        token: state.auth.token,
        user: state.auth.meta,
        vendorId: userId,
        levelsList: state.vendorReducer.levelsList
    };
}

const wrappedPurchaseOrder = Form.create()(RFQQuotation)


function mapDispatchToProps(dispatch) {
    return bindActionCreators({ updateRFQQuotation: updateRFQQuotation }, dispatch)
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)(wrappedPurchaseOrder);
