import * as types from '../constants/actionsTypes';
import axios from 'axios';
import AppConfig from '../constants/appConfig';
import {notification,Icon} from 'antd';
// import demoData for invoice search
import demoData from '../constants/demoData.js'


const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message,
        description,
    });
};

// Go to dashboard after login success
function goToDashboard() {
    document.getElementById('goToVendorDashboard').click()
}

function goToLogin(){
    document.getElementById('goToLogin').click()
}


// fetch all users list
export function fetchUsersList(params={}){
    console.log(params);
    return(dispatch=>{
        dispatch({type:types.REQUEST_USER_FETCH,data:{}});
        const config = {
            headers:{
                'content-type':'application/json',
                'Authorization':params.token,
            }
        };
        axios.get(AppConfig.baseAPIUrl+'/api/user/get/list',config)
        .then((result)=>{
            const data = result.data && result.data.data ? result.data.data : {};
            console.log("userActions: fetch: fetch user result success: ", data);
            dispatch({ type: types.RESPONSE_USER_FETCH, data: { error: false, data, remember: params.remember } });
           return data
        })
        .catch((error) => {
            let data = { error: true };
            try {
                data['errors'] = error.response.data.errors;
            }
            catch (ex) {
                data['errors'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
            }
            dispatch({ type: types.RESPONSE_USER_FETCH, data: {} });
            return data

        });
    })
}


// fetch all levels list
export function fetchLevelsList(params={},cb){
    console.log(params);
    return(dispatch=>{
        dispatch({type:types.REQUEST_LEVEL_FETCH,data:{}});
        const config = {
            headers: {
                'content-type': 'application/json',
                'Authorization': params.token,
            }
        };
        axios.get(AppConfig.baseAPIUrl+'/api/level/level',config)
        .then((result)=>{
            const data = result.data && result.data.data ? result.data.data : {};
                  console.log("levelActions: fetch: fetch level result success: ", data);
                  dispatch({ type: types.RESPONSE_LEVEL_FETCH, data: { error: false, data, remember: params.remember } });
                 return data
        })
        .catch((error) => {
            let data = { error: true };
            try {
                data['errors'] = error.response.data.errors;
            }
            catch (ex) {
                data['errors'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
            }
            dispatch({ type: types.RESPONSE_LEVEL_FETCH, data: {} });
            return data

        });
    })
}

export function updateInvoiveStatus(params={}){
    return(dispatch=>{
        dispatch({type:types.REQUEST_STATUS_UPDATE,data:{}});
        const config = {
            headers: {
                'content-type': 'application/json',
                'Authorization': params.token,
            }
        };
        console.log('Edit updateInvoiveStatus Params',params);
        axios.post(AppConfig.baseAPIUrl+'/api/invoice/invoice/edit/status',
            {
                status:params.status,
                invoiceId:params.invoiceId
            },
            config)
            .then((result)=>{
                const data = result.data && result.data.data && result.data.data._id ? result.data.data : {};
                console.log("invoice Actions: Invoice: invoice Edit result success: ", data);
                dispatch({ type: types.RESPONSE_STATUS_UPDATE, data: { error: false, data } });
            })
            .catch((error) => {
                let data = { error: true };
                try {
                    data['errors'] = error.response.data.errors;
                    console.log("Edit Invoice: Edit Invoice result failed: ", error);
                    console.log("Error response: ", error.response);
                    console.log("Error response body: ", error.response.body);
                }
                catch (ex) {
                    data['errors'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
                }
                dispatch({ type: types.RESPONSE_INVOICE_EDIT, data: data });
            });
    })
}

export function updateInvoiceStatusByUser(params={}){
    return(dispatch=>{
        dispatch({type:types.REQUEST_STATUS_UPDATE,data:{}});
        const config = {
            headers: {
                'content-type': 'application/json',
                'Authorization': params.token,
            }
        };
        console.log('Edit updateInvoiveStatus Params',params);
        axios.post(AppConfig.baseAPIUrl+'/api/invoice/invoice/edit/status',
            {
                status:params.newStatus,
                invoiceId:params.invoiceId
            },
            config)
            .then((result)=>{
                const data = result.data && result.data.data && result.data.data._id ? result.data.data : {};
                console.log("invoice Actions: Invoice: invoice Edit result success: ", data);
                dispatch({ type: types.RESPONSE_STATUS_UPDATE, data: { error: false, data } });
                openNotificationWithIcon('success', 'Updated Invoice Status!', `Successfully updated status to ${params.newStatus} !`);

            })
            .catch((error) => {
                let data = { error: true };
                try {
                    data['errors'] = error.response.data.errors;
                    console.log("Edit Invoice: Edit Invoice result failed: ", error);
                    console.log("Error response: ", error.response);
                    console.log("Error response body: ", error.response.body);
                }
                catch (ex) {
                    data['errors'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
                }
                dispatch({ type: types.RESPONSE_STATUS_UPDATE, data: data });

            });
    })
}


// RESET Vendor Password

export function resetPassword(params={}){
    return(dispatch=>{
        dispatch({type:types.REQUEST_RESET_PASSWORD,data:{}});
        const config = {
            headers: {
                'content-type': 'application/json',
            }
        };
        console.log('RESET Password Params',params);
        axios.post(AppConfig.baseAPIUrl+'/api/auth/reset-password',
            {
                vendorEmail:params.vendorEmail,
                vendorPassword:params.vendorPassword
            },
            config)
            .then((result)=>{
                const data = result.data && result.data.data && result.data.data._id ? result.data.data : {};
                console.log("forgot password Actions: password: password reset result success: ", data);
                dispatch({ type: types.RESPONSE_RESET_PASSWORD, data: { error: false, data } });
                openNotificationWithIcon('success', 'Updated Password', `Successfully updated password`);

            })
            .catch((error) => {
                let data = { error: true };
                try {
                    data['errors'] = error.response.data.errors;
                    console.log("RESET Password: Reset password result failed: ", error);
                    console.log("Error response: ", error.response);
                    console.log("Error response body: ", error.response.body);
                }
                catch (ex) {
                    data['errors'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
                }
                let options = [];
                console.log("errr",data.errors)
                if(data.errors.map((err=>{
                    console.log(".map err",err.msg);
                    openNotificationWithIcon('error',`${err.msg}`)

                    // options.push(opt)
                })))

                dispatch({ type: types.RESPONSE_RESET_PASSWORD, data: data });

            });
    })
}



export function editInvoice(formData,token){
    console.log('Edit Invoice Params',formData,token);
    return(dispatch=>{
        dispatch({type:types.REQUEST_INVOICE_EDIT,data:{}});
        const config = {
            headers:{
                'content-type': 'multipart/form-data',
                'Authorization':token,
            }
        };
        console.log('add invoice params',formData,token);
        axios.post(AppConfig.baseAPIUrl+'/api/invoice/invoice/edit',
            formData,
            config)
            .then((result)=>{
                const data = result.data && result.data.data && result.data.data._id ? result.data.data : {};
                console.log("invoice Actions: Invoice: invoice Edit result success: ", data);
                dispatch({ type: types.RESPONSE_INVOICE_ADD, data: { error: false, data } });
            })
            .catch((error) => {
                let data = { error: true };
                try {
                    data['errors'] = error.response.data.errors;
                    console.log("Edit Invoice: Edit Invoice result failed: ", error);
                    console.log("Error response: ", error.response);
                    console.log("Error response body: ", error.response.body);
                }
                catch (ex) {
                    data['errors'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
                }
                dispatch({ type: types.RESPONSE_INVOICE_EDIT, data: data });
            });
    })
}

export function getPoNumber(params={}){
    console.log("Fetching number of POs in User Action");
    console.log("invoicesList",params);
    return(dispatch=>{
        dispatch({type:types.REQUEST_PO_NUMBER,data:{}});
        const config = {
            headers: {
                'content-type': 'application/json',
                'Authorization': params.token,
            }
        };
        axios.get(AppConfig.baseAPIUrl+'/api/purchaseOrder/purchaseOrders/getNumber',config)
            .then((result)=>{
                const data = result.data && result.data.numberOfPos ? result.data.numberOfPos : null;
                console.log("GetNumberPO:  result success: ", data);
                dispatch({ type: types.RESPONSE_PO_NUMBER, data: { error: false, data, remember: params.remember } });
                return data
            })
            .catch((error) => {
                let data = { error: true };
                try {
                    data['errors'] = error.response.data.errors;
                }
                catch (ex) {
                    data['errors'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
                }
                dispatch({ type: types.RESPONSE_PO_NUMBER, data: {} });
                return data

            });
    })
}

// fetch all Invoices list
export function invoicesList(params={}){
    console.log("Fetching invoices in Vendor Action");
    console.log("invoicesList",params);
    return(dispatch=>{
        dispatch({type:types.REQUEST_INVOICE_FETCH,data:{}});
        const config = {
            headers: {
                'content-type': 'application/json',
                'Authorization': params.token,
            }
        };
        axios.get(AppConfig.baseAPIUrl+'/api/invoice/invoice',config)
        .then((result)=>{
            const data = result.data && result.data.data ? result.data.data : {};
                  console.log("invoiceActions: fetch: fetch invoice result success: ", data);
                  dispatch({ type: types.RESPONSE_INVOICE_FETCH, data: { error: false, data, remember: params.remember } });
                 return data
        })
        .catch((error) => {
            let data = { error: true };
            try {
                data['errors'] = error.response.data.errors;
            }
            catch (ex) {
                data['errors'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
            }
            dispatch({ type: types.RESPONSE_INVOICE_FETCH, data: {} });
            return data

        });
    })
}

// fetch all vendor lists with status
export function vendorsList(params={}){
    console.log(params);
    return(dispatch=>{
        dispatch({type:types.REQUEST_VENDOR_LIST,data:{}});
        let apiParams = {
            status: params.status
        };
        const config = {
            params: apiParams,
            headers: {
                'content-type': 'application/json',
                'Authorization': params.token,
                invoiceId:params.invoiceId2
            }
        };
      if(params.status==='pending'){
          axios.get(AppConfig.baseAPIUrl+'/api/user/get/vendor/list',  config)
              .then((result) => {
                  const data = result.data && result.data.data ? result.data.data : {};
                  console.log("authActions: loginUser: Login user result success: ", data);
                  dispatch({ type: types.RESPONSE_PENDING_VENDOR_LIST, data: { error: false, data, remember: params.remember } });
                  return data
              })
              .catch((error) => {
                  let data = { error: true };
                  try {
                      data['errors'] = error.response.data.errors;
                  }
                  catch (ex) {
                      data['errors'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
                  }
                  dispatch({ type: types.RESPONSE_VENDOR_LIST, data: {} });
                  return data

              });
      }
      else if(params.status==='approved'){
          axios.get(AppConfig.baseAPIUrl+'/api/user/get/vendor/list',  config)
              .then((result) => {
                  const data = result.data && result.data.data ? result.data.data : {};
                  console.log("authActions: loginUser: Login user result success: ", data);
                  dispatch({ type: types.RESPONSE_APPROVED_VENDOR_LIST, data: { error: false, data, remember: params.remember } });
                  return data
              })
              .catch((error) => {
                  let data = { error: true };
                  try {
                      data['errors'] = error.response.data.errors;
                  }
                  catch (ex) {
                      data['errors'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
                  }
                  dispatch({ type: types.RESPONSE_VENDOR_LIST, data: {} });
                  return data

              });
      }else{
          axios.get(AppConfig.baseAPIUrl+'/api/user/get/vendor/list',  config)
              .then((result) => {
                  const data = result.data && result.data.data ? result.data.data : {};
                  console.log("authActions: loginUser: Login user result success: ", data);
                  dispatch({ type: types.RESPONSE_REJECTED_VENDOR_LIST, data: { error: false, data, remember: params.remember } });
                  return data
              })
              .catch((error) => {
                  let data = { error: true };
                  try {
                      data['errors'] = error.response.data.errors;
                  }
                  catch (ex) {
                      data['errors'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
                  }
                  dispatch({ type: types.RESPONSE_VENDOR_LIST, data: {} });
                  return data

              });
              
      }
    })
}



// Fetch all vendors

export function allVendorsList(params={}){
    console.log("Fetching vendors in Vendor Action");
    console.log("vendorsList in all vendors list",params);
    return(dispatch=>{
        dispatch({type:types.RESPONSE_VENDOR_FETCH,data:{}});
        const config = {
            headers: {
                'content-type': 'application/json',
                'Authorization': params.token,
            }
        };
        axios.get(AppConfig.baseAPIUrl+'/api/user/get/vendor/allList',  config)
        .then((result) => {
            const data = result.data && result.data.data ? result.data.data : {};
            console.log("authActions: loginUser: Login user result success: ", data);
            dispatch({ type: types.RESPONSE_VENDOR_FETCH, data: { error: false, data, remember: params.remember } });
            return data
        })
        .catch((error) => {
            let data = { error: true };
            try {
                data['errors'] = error.response.data.errors;
            }
            catch (ex) {
                data['errors'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
            }
            dispatch({ type: types.RESPONSE_VENDOR_FETCH, data: {} });
            return data

        });
    })
}


//search invoices API

export function invoiceSearch(params = {}) {
    return dispatch => {
      dispatch({ type: types.REQUEST_INVOICE_SEARCH, data: {} });
      let apiParams;
      if (demoData.q){
        apiParams = {
          q:demoData.q,
        //   searchTerm:params.searchTerm
        };
        axios
          .get(AppConfig.baseAPIUrl + '/api/invoice/invoice/search', {
            params: apiParams,
            headers: {
              Authorization: params.token,
            },
          })
          .then((result)=>{
            const data = result.data && result.data.data ? result.data.data : {};
                  console.log("searchActions: fetch: search invoice result success: ", data);
                  dispatch({ type: types.RESPONSE_INVOICE_SEARCH, data: { error: false, data, remember: params.remember } });
                 return data
        })
        .catch((error) => {
            let data = { error: true };
            try {
                data['errors'] = error.response.data.errors;
            }
            catch (ex) {
                data['errors'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
            }
            dispatch({ type: types.REQUEST_INVOICE_SEARCH, data: {} });
            return data

        });
      }
    };
  }





// vendor login
export function vendorLogin(params={}){
    console.log('params',params);
    return(dispatch=>{
        dispatch({type:types.AUTH_REQUEST_LOGIN,data:{}});
        const config = {
            headers: {
                'content-type': 'application/json'
            }
        };
        axios.post(AppConfig.baseAPIUrl+'/api/auth/vendor/signin', {
        vendorEmail: params.vendorEmail, 
        vendorPassword: params.vendorPassword,
    }, config)
    .then((result) => {
        const data = result.data && result.data.data && result.data.data._id ? result.data.data : {};
        console.log("authActions: loginUser: Login user result success: ", data);
        saveProfileToLocalStore(data);
        dispatch({ type: types.AUTH_RESPONSE_LOGIN, data: { error: false, data, remember: params.remember } });
    })
    .catch((error) => {
        let data = { error: true };
        try {
            data['errors'] = error.response.data.errors;
            // console.log("authActions: tryLogin: sign-in User result failed: ", error);
            // console.log("Error response: ", error.response);
            // console.log("Error response body: ", error.response.body);
        }
        catch (ex) {
            data['errors'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
        }
        openNotificationWithIcon('error', 'Login Failed!', 'Please try again!');

        dispatch({ type: types.LOGIN_VENDOR, data: data });
    });
    })
}



export function tryAlreadyLoggedInProfile(){
    console.log("trying already logIn")
    return (dispatch => {
      let data = loadProfileFromLocalStore();
      if(data && data.length){
        try {
          data = JSON.parse(atob(data));
          return dispatch({type: types.AUTH_RESPONSE_LOGIN, data: { error: false, data: data }});
        }
        catch(ex){
          console.log("Error loading profile: ", ex, data);
        }
      }

    })
}



function loadProfileFromLocalStore(){
    //const authToken = localStorage.getItem("thtt");
    const data =  localStorage.getItem('thup');
    return data;
  }
  
  function saveProfileToLocalStore(data){
    localStorage.setItem("thup", btoa(JSON.stringify(data)));
    //localStorage.setItem("thtt", btoa(data.token));
  }


export function logout(){
  return (dispatch => {
    deleteProfileFromLocalStore();
    return dispatch({type: types.AUTH_DO_LOGOUT, data: { error: false, data: {} } });
  })
}

function deleteProfileFromLocalStore(){
  return localStorage.removeItem('thup');
}


// Add Payment Advoice
export function paymentAdvoiceAdd(formData,token){
    console.log('Payment Advoice add',formData,token);
    return(dispatch=>{
        dispatch({type:types.REQUEST_PAYMENT_ADVOICE_ADD,data:{}});
        const config = {
            headers:{
                'content-type': 'multipart/form-data',
                'Authorization':token,
            }
        };
        console.log('add payment advoice data is:',formData,token);
        axios.post(AppConfig.baseAPIUrl+'/api/invoice/payment/advoice',
                formData,
                config)
                .then((result)=>{
                    const data = result.data && result.data.data && result.data.data._id ? result.data.data : {};
                    console.log("PaymentAdvoiceAction: Advoice: Advoice ADD result success: ", data);
                    dispatch({ type: types.RESPONSE_PAYMENT_ADVOICE_ADD, data: { error: false, data, remember: formData.remember } });
                    openNotificationWithIcon('success', 'Add Advoice!', 'Successfully Added Advoice');

                })
                .catch((error) => {
                    let data = { error: true };
                    try {
                        data['errors'] = error.response.data.errors;
                        console.log("ADD ADVOICE: tryADVOCIE: ADD ADVOICE result failed: ", error);
                        console.log("Error response: ", error.response);
                        console.log("Error response body: ", error.response.body);
                    }
                    catch (ex) {
                        data['errors'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
                    }
                    openNotificationWithIcon('error', 'Delete Invoice Failed!', 'Failed delete invoice');
                    dispatch({ type: types.RESPONSE_PAYMENT_ADVOICE_ADD, data: data });
                });
    })  
}



//Add invoices
export function invoiceAdd(formData,token,invoiceId){
    console.log('invoice add',token,invoiceId);
    return(dispatch=>{
        dispatch({type:types.REQUEST_INVOICE_ADD,data:{}});
        const config = {
            headers:{
                'content-type': 'multipart/form-data',
                'Authorization':token,
            }
        };
        console.log('add invoice params',formData,token);
        if(invoiceId){
            console.log("Editing");
            axios.post(AppConfig.baseAPIUrl+'/api/invoice/invoice/edit',
                formData,
                config)
                .then((result)=>{
                    const data = result.data && result.data.data && result.data.data._id ? result.data.data : {};
                    console.log("invoiceActions: Invoice: invoice ADD result success: ", data);
                    dispatch({ type: types.RESPONSE_INVOICE_ADD, data: { error: false, data, remember: formData.remember } });
                })
                .catch((error) => {
                    let data = { error: true };
                    try {
                        data['errors'] = error.response.data.errors;
                        console.log("ADDUSER: tryADDUSER: ADD USER result failed: ", error);
                        console.log("Error response: ", error.response);
                        console.log("Error response body: ", error.response.body);
                    }
                    catch (ex) {
                        data['errors'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
                    }
                    dispatch({ type: types.RESPONSE_INVOICE_ADD, data: data });
                });
        }else{
            console.log("Adding");
            axios.post(AppConfig.baseAPIUrl+'/api/invoice/invoice/create',
                formData,
                config)
                .then((result)=>{
                    const data = result.data && result.data.data && result.data.data._id ? result.data.data : {};
                    console.log("invoiceActions: Invoice: invoice ADD result success: ", data);
                    dispatch({ type: types.RESPONSE_INVOICE_ADD, data: { error: false, data, remember: formData.remember } });
                })
                .catch((error) => {
                    let data = { error: true };
                    try {
                        data['errors'] = error.response.data.errors;
                        console.log("ADDUSER: tryADDUSER: ADD USER result failed: ", error);
                        console.log("Error response: ", error.response);
                        console.log("Error response body: ", error.response.body);
                    }
                    catch (ex) {
                        data['errors'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
                    }
                    dispatch({ type: types.RESPONSE_INVOICE_ADD, data: data });
                });
        }
    })
}


// Delete invoices

export function deleteInvoice(params = {}) {
    // console.log(params);
    return (dispatch => {
        dispatch({ type: types.REQUEST_INVOICE_DELETE, data: {} });
        const config = {
            headers: {
                'content-Type': 'application/json',
                Authorization: params.token
            }
        };
        axios.post(AppConfig.baseAPIUrl+'/api/invoice/invoice/delete', {
            invoiceId: params.invoiceId,
        }, config)
            .then((result) => {
                const data = result.data && result.data.data && result.data.data._id ? result.data.data : {};
                // console.log("FormAction: Form: Delete Form result success: ", data);
                dispatch({ type: types.RESPONSE_INVOICE_DELETE, data: { error: false, data, remember: params.remember } });
                openNotificationWithIcon('success', 'Delete Invoice!', 'Successfully delete invoice');
                console.log("deleted Invoice ",data);
            })
            .catch((error) => {
                let data = { error: true };
                try {
                    data['errors'] = error.response.data.errors;
                    // console.log("formAction: tryFormDeletion: form delete result failed: ", error);
                    // console.log("Error response: ", error.response);
                    // console.log("Error response body: ", error.response.body);
                }
                catch (ex) {
                    data['errors'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
                }
                openNotificationWithIcon('error', 'Delete Invoice Failed!', 'Failed delete invoice');
                dispatch({ type: types.RESPONSE_INVOICE_DELETE, data: data });
            });
    })
}


// vendor register
export function vendorRegister(params){
    console.log(params);
    return(dispatch=>{
        dispatch({type:types.REGISTRARTION_VENDOR,data:{}});
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        console.log('params',params);
        axios.post(AppConfig.baseAPIUrl+'/api/auth/vendor/signup',
        params
    , config)
    .then((result) => {
        const data = result.data && result.data.data && result.data.data._id ? result.data.data : {};
        console.log("authActions: registerVendor: Register vendor result success: ", data);
        dispatch({ type: types.REGISTRARTION_VENDOR, data: { error: false, data, remember: params.remember } });
        openNotificationWithIcon('success', 'Saved Vendor!', 'Successfully saved the vendor!');
        this.props.form.resetFields();
        goToLogin()
    })
     .catch((error) => {
        let data = { error: true };
        try {
            data['errors'] = error.response.data.errors;
            console.log("authActions: tryRegistration: sign-up User result failed: ", error);
            console.log("Error response: ", error.response);
            console.log("Error response body: ", error.response.body);
            // openNotificationWithIcon('Failed', 'Saved Vendor!', 'Unsuccessfully saved the vendor!');
            // this.props.form.resetFields();
        }
        catch (ex) {
            data['errors'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
            // openNotificationWithIcon('Failed', 'Saved Vendor!', 'Unsuccessfully saved the vendor!');
            // this.props.form.resetFields();
        }
        // openNotificationWithIcon('error', 'Vendor: Failed!', 'Please try again!');
        dispatch({ type: types.REGISTRARTION_VENDOR, data: data });
        document.getElementById('goToLogin').click()
     });
    })

}


// Add Users
export function addUsers(params={}){
    console.log("Add users params",params);
    return(dispatch=>{
        dispatch({type:types.REQUEST_ADD_USER,data:{}});
        const config = {
            headers:{
                'content-type': 'application/json',
                'Authorization':params.token
            }
        };
        console.log('params',params);
        axios.post(AppConfig.baseAPIUrl+'/api/auth/signup',{
            name:params.name,
            email:params.email,
            password:params.password,
            phone:params.phone
        },config)
        .then((result)=>{
            const data = result.data && result.data.data && result.data.data._id ? result.data.data : {};
        console.log("ADDUser: ADDUSERS: ADD USER result success: ", data);
        dispatch({ type: types.RESPONSE_ADD_USER, data: { error: false, data} });
        //  openNotificationWithIcon('success', 'Saved User!', 'Successfully saved the user details');
        })
        .catch((error) => {
            let data = { error: true };
            try {
                data['errors'] = error.response.data.errors;
                console.log("ADDUSER: tryADDUSER: ADD USER result failed: ", error);
                console.log("Error response: ", error.response);
                console.log("Error response body: ", error.response.body);
            }
            catch (ex) {
                data['errors'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
            }
        openNotificationWithIcon('error', 'Failed User!', 'Failed saved the user details');
            dispatch({ type: types.RESPONSE_ADD_USER, data: data });
         });
    })
}



// add Departments
export function addDepartments(params={}){
    console.log("add levels params",params);
    return(dispatch=>{
        dispatch({type:types.ADD_REQUEST_LEVEL,data:{}});
        const config = {
            headers:{
                'content-type': 'application/json',
                'Authorization':params.token
            }
        };
        console.log('params',params);
        if(params.departmentId){
            axios.post(AppConfig.baseAPIUrl+'/api/level/level/edit', {
                departmentId:params.departmentId,
                numberOfLevels: params.numberOfLevels,
                departmentName: params.departmentName,
            }, config)
                .then((result)=>{
                    const data = result.data && result.data.data && result.data.data._id ? result.data.data : {};
                    console.log("authActions: ADDLEVELS: ADD LEVEL result success: ", data);
                    dispatch({ type: types.ADD_RESPONSE_LEVEL, data: { error: false, data, remember: params.remember } });
                    openNotificationWithIcon('success', 'Saved Department!', 'Successfully saved the department!');
                })
                .catch((error) => {
                    let data = { error: true };
                    try {
                        data['errors'] = error.response.data.errors;
                        console.log("authActions: tryADDLEVEL: ADD LEVEL result failed: ", error);
                        console.log("Error response: ", error.response);
                        console.log("Error response body: ", error.response.body);
                    }
                    catch (ex) {
                        data['errors'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
                    }
                    openNotificationWithIcon('error', 'Department: Failed!', 'Please try again!');
                    dispatch({ type: types.ADD_LEVEL, data: data });
                });
        }else{
            axios.post(AppConfig.baseAPIUrl+'/api/level/level/create', {
                numberOfLevels: params.numberOfLevels,
                departmentName: params.departmentName,
                createdByAdmin:params.createdByAdmin,
            }, config)
                .then((result)=>{
                    const data = result.data && result.data.data && result.data.data._id ? result.data.data : {};
                    console.log("authActions: ADDLEVELS: ADD LEVEL result success: ", data);
                    dispatch({ type: types.ADD_RESPONSE_LEVEL, data: { error: false, data, remember: params.remember } });
                    openNotificationWithIcon('success', 'Saved Department!', 'Successfully saved the department!');

                })
                .catch((error) => {
                    let data = { error: true };
                    try {
                        data['errors'] = error.response.data.errors;
                        console.log("authActions: tryADDLEVEL: ADD LEVEL result failed: ", error);
                        console.log("Error response: ", error.response);
                        console.log("Error response body: ", error.response.body);
                    }
                    catch (ex) {
                        data['errors'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
                    }
                    openNotificationWithIcon('error', 'Add Department Failed!', 'Please try again!');
                    dispatch({ type: types.ADD_LEVEL, data: data });
                });
        }

    }) 
}

// update vendor
export function updateVendor(params={}){
    console.log(params);
    return(dispatch=>{
        dispatch({type:types.REQUEST_VENDOR_UPDATE,data:{}});
        const config = {
            headers: {
                'content-type': 'application/json',
                'Authorization': params.token,
            }
        };
        axios.post(AppConfig.baseAPIUrl+'/api/user/vendor/update', params, config)
            .then((result) => {
                const data = result.data && result.data.data ? result.data.data : {};
                console.log("authActions: loginUser: Login user result success: ", data);
                dispatch({ type: types.RESPONSE_VENDOR_UPDATE, data});
                return data
            })
            .catch((error) => {
                let data = { error: true };
                try {
                    data['errors'] = error.response.data.errors;
                }
                catch (ex) {
                    data['errors'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
                }
                dispatch({ type: types.RESPONSE_VENDOR_LIST, data: {} });
                return data

            });
    })
}



// Invoice Status Change all actions

export function handleDocStatusUpdate(params) {
    return dispatch => {
      dispatch({ type: types.REQUEST_DOC_STATUS_UPDATE, data: params });
      const config = {
        headers: {
          Authorization: params.authToken,
          'Content-Type': 'application/json',
        },
      };

      const body = {
        invoiceId : params.invoiceId,
        status: params.newStatus,
        remarks: params.remarks,
      };

      axios
        .post(AppConfig.baseAPIUrl + '/api/invoice/invoice/update/status', body, config)
        .then(result => {
          console.log('success changing document status: ', result);
          if(!result.data || !result.data.data || result.data.data.status !== params.newStatus) {
             openNotificationWithIcon(
              'error', `Failed to change status of ${params.code}`,
            );
            dispatch({ type: types.RESPONSE_DOC_STATUS_UPDATE, data: {error:true,errorMsg:'Failed to change the status of document!'} });
            return;
          }
          let newStaus = params.newStatus;
          if(params.newStatus==="PROCESSED"){
            newStaus="PARKED"
          }else if(params.newStatus==="PARKED"){
            newStaus="ON-HOLD"
          }
          openNotificationWithIcon(
            'success', `Status is updated to ${newStaus}`,
          );

          dispatch({
            type: types.RESPONSE_DOC_STATUS_UPDATE,
            data: {
              error: false,
              success: true,
              workId: params.workId,
              newStatus: params.newStatus
            }
          });
        })
        .catch(error => {
          console.log('error changing document status: ', error);
          openNotificationWithIcon(
            'error',
            'Error updating status. Please try again!',
            `${error}.`
          );
          let data = { error: true };
          try {
            data['errorMsg'] = error.response.data.errors;
          } catch (ex) {
            data['errorMsg'] = [
              {
                param: 'Network Error',
                msg: 'Unable to connect. Please ensure your network is connected properly.',
              },
            ];
          }
          dispatch({ type: types.RESPONSE_DOC_STATUS_UPDATE, data: data });
        });
    };
  }


  export function handleDocStatusUpdate2(params) {
    return dispatch => {
      dispatch({ type: types.REQUEST_DOC_STATUS_UPDATE, data: {} });
      const config = {
        headers: {
          Authorization: params.authToken,
          'Content-Type': 'application/json',
        },
      };

      const body = {
        workId: params.workId,
        status: params.newStatus,
        remarks: params.remarks,
      };

      axios
        .post(AppConfig.baseAPIUrl + '/api/workflow/work/update/supervisor/status', body, config)
        .then(result => {
          console.log('success changing document status: ', result);
          dispatch({
            type: types.RESPONSE_DOC_STATUS_UPDATE,
            data: {
              error: false,
              success: true,
              workId: params.workId,
              newStatus: params.newStatus
            }
          });
        })
        .catch(error => {
          console.log('error changing document status: ', error);
          let data = { error: true };
          try {
            data['errorMsg'] = error.response.data.errors;
          } catch (ex) {
            data['errorMsg'] = [
              {
                param: 'Network Error',
                msg: 'Unable to connect. Please ensure your network is connected properly.',
              },
            ];
          }
          dispatch({ type: types.RESPONSE_DOC_STATUS_UPDATE, data: data });
        });
    };
  }
