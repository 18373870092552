import React from "react";
import {
    Form,
    Input,
    Tooltip,
    Icon,
    Cascader,
    Select,
    Row,
    Col,
    Radio,
    Upload,
    Checkbox,
    Button,
    AutoComplete, notification,
} from 'antd';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {paymentAdvoiceAdd, invoiceAdd,invoicesList ,fetchLevelsList} from '../../../actions/vendorsAction';
import { fetchUsersList } from '../../../actions/userAction';
import PropTypes from "prop-types";
import {compose} from "recompose";
import { withRouter, Link } from "react-router-dom";
import ESelect from '../../../elements/ESelect'
import { DatePicker } from 'antd';
import moment from "moment";

const { MonthPicker, RangePicker, WeekPicker } = DatePicker;


const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message,
        description,
    });
};
class addPaymentAdvoice extends React.Component {
    constructor(props) {
        super(props);
        if(this.props.location.invoice){
            const { _id } = this.props.location.invoice;
            console.log('invoice meta',this.props.location.invoice)
            console.log('invoice id!!!!',_id);
        }
        this.state = {
            file:null,
            invoiceNo: (this.props.location.invoice && this.props.location.invoice.invoiceNo),

        };
    
    }

    onChange(date, dateString) {
        console.log(date, dateString);
    }
    openNotificationWithIcon = (type, message, description) => {
        notification[type]({
            message,
            description,
        });
    };

    normFile = e => {
        this.setState({file:e.fileList[0]});
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;

    };


    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
       
            if(!err){
                console.log('Received values of form: ', values);
                let formData = new FormData();
                let token = this.props.token;
                let invoiceID = this.props.location.invoice._id
                console.log('Auth Token',token);
                console.log('invoice ID',invoiceID)
                formData.append('invoiceID',invoiceID);
                formData.append('invoiceNo',values.invoiceNo);
                formData.append('totalPaymentAmount',values.totalPaymentAmount);
                formData.append('paymentDate',values.paymentDate.startOf('day').valueOf());
                formData.append('uploadAdvoiceCopy',values.upload && values.upload[0] ? values.upload[0].originFileObj : null);
                console.log('form data and auth token',formData,token);
                this.props.paymentAdvoiceAdd(
                    formData,
                    token,
                )
                this.props.form.resetFields();

            }
        });
    };


    render() {
        console.log('invoice no is!!!',this.state.invoiceNo)
        const { form, saveUserLoading } = this.props;
        const { getFieldDecorator } = form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 5 },
                sm: { span: 5 },
            },
            wrapperCol: {
                xs: { span: 5 },
                sm: { span: 15 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };

        let uploadSuccess = null;
        if(this.state.file){
            uploadSuccess = 'file upload success'
        }


// Changes in UI (change by: omkar)

        return (
        <div>
            <div className="box-header">
                <h2>Add Payment Advoice</h2>
            </div>
            <div style={{boxShadow:'0 4px 8px 0 rgba(0,0,0,0.2)',
                    transition: '2.5s',
                    width:'70%',
                    borderRadius:'15px',
                    margin: '0 auto 0 auto',
                    marginTop:'20px',
                    backgroundColor:'#FFF'

                }}>
            <Form {...formItemLayout} onSubmit={this.handleSubmit}>
            <div style={{alignItems:'center',paddingTop:20,paddingLeft:'20px'}}>
                
            <Form.Item style={{width:'115%'}} label="Invoice No">
                    {getFieldDecorator('invoiceNo', {
                        rules: [{ required: true, message: 'Please input Invoice No', }],
                        initialValue: this.state.invoiceNo,
                    })(<Input  placeholder="Enter Invoice Number" style={{marginLeft:'10px',width:'45%'}} />)}
             </Form.Item>

                <Form.Item style={{width:'115%'}} label="Total Payment Amount">
                    {getFieldDecorator('totalPaymentAmount', {
                        rules: [{ required: true, message: 'Please input Total Payment Amount' }],
                    })(<Input placeholder="Enter Total Payment Amount" style={{marginLeft:'10px',width:'45%'}} />)}
                </Form.Item>

                <Form.Item style={{width:'115%'}} label="Payment Date">
                    {getFieldDecorator('paymentDate', {
                        rules: [
                            {
                                required: true,
                                message: 'Please input the payment date!',
                            },
                        ],
                    })(    <DatePicker style={{marginLeft:'10px'}} onChange={onChange} />
                    )}
                </Form.Item>

                <Form.Item style={{width:'115%'}} label="Upload Advoice Copy">
                    {getFieldDecorator('upload', {
                        valuePropName: 'fileList',
                        getValueFromEvent: this.normFile,
                    })(
                        <Upload name="logo" style={{marginLeft:'10px'}} >
                            <Button>
                                <Icon type="upload" /> Click to upload
                            </Button>
                            <div style={{color:'#388e3c'}}>
                             {uploadSuccess}
                            </div> 
                        </Upload>,
                    )}
                </Form.Item>
    
                <Form.Item {...tailFormItemLayout}>
                    <Button style={{marginBottom:30,marginLeft:'20px'}} type="primary" htmlType="submit">
                        Add 
                    </Button>
                </Form.Item>
                </div>
            </Form>
            </div>
        </div>
        );
    }
}

const WrappedLogin = Form.create()(addPaymentAdvoice)


function mapStateToProps(state) {
    let userId = null;
    userId = (state.auth.meta && state.auth.meta.data && state.auth.meta.data._id ) ? state.auth.meta.data._id : null;
  return {
      register: state.register,
      isRegistration: state.register.isRegistration,
      InvoicesList:state.vendorReducer.invoicesList,
      token:state.auth.token,
      user:state.auth.meta,
      vendorId:userId,
      levelsList: state.vendorReducer.levelsList
  };
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({paymentAdvoiceAdd:paymentAdvoiceAdd, invoiceAdd: invoiceAdd ,invoicesList:invoicesList,fetchLevelsList:fetchLevelsList}, dispatch)
}

export default compose(withRouter,connect(mapStateToProps, mapDispatchToProps),)(WrappedLogin);

function onChange(value, selectedOptions) {
    console.log(value, selectedOptions);
}


