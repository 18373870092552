import React, { Component } from 'react';
import { Layout, Menu, Icon, Switch, Button, Row, Col, Input, Dropdown } from 'antd';
import '../../components/vendorDashboard/vendorDashboard.modules.css';
import { logout, tryAlreadyLoggedInProfile } from "../../actions/userAction";

import { invoicesList, handleDocStatusUpdate, handleDocStatusUpdate2, updateInvoiceStatusByUser, getPoNumber, invoiceSearch, allVendorsList, } from "../../actions/vendorsAction";
import { getPurchaseOrder } from '../../actions/userAction'
import { getItemsList } from '../../actions/userAction'
import { BrowserRouter as Router, Link, Route } from "react-router-dom";
import PendingInvoice from "./pendingInvoices";
import SearchInvoice from "./searchInvoices";

import ProcessedInvoice from "./processedInvoices";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { HashRouter } from 'react-router-dom';
import demoData from "../../constants/demoData";
import AddInvoices from "../vendorDashboard/invoices/addInvoices";

//import purchase order
import AddPurchaseOrder from './purchaseOrder/addPurchaseOrder'
import ViewPurchaseOrder from './purchaseOrder/viewPurchaseOrder'
import PrintPurchaseOrder from './purchaseOrder/printPurchaseOrder'
import AddItems from './purchaseOrder/addItems'
import AddRFQ from '../rfqResponse/addRFQ'
import ViewRFQ from '../rfqResponse/viewRFQ'
import RFQQuotationList from '../rfqResponse/viewRFQQuotationList';
import RFQQuotation from '../rfqResponse/viewRFQQuotation';
import ViewItems from './purchaseOrder/viewItems'

// import Payflow Logo
const logoImage = require('../../../src/payflow-logo.png');
const { Content, Sider } = Layout;
const { SubMenu } = Menu;
const Search = Input.Search;

class userDashboard extends Component {

    state = {
        pendingInvoicesList: [],
        processedInvoicesList: [],
        vendorsList: [],
        purchaseOrderList: [],
        ItemsList: [],
        totalNumberOfPos: null
    };

    // Fetch pending invoices lists
    fetchPendingInvoicesList = async () => {
        console.log('fetchVendorsPendingList', this.props.token)
        console.log("invoices list fetchdocs", this.props)
        console.log("user meta in userdashbard", this.props.userId);
        let data = await this.props.invoicesList2({
            token: this.props.token,
        });
        this.setState({
            pendingInvoicesList: data
        })
    };
    // fetch vendors approved lists
    fetchProcessedInvoicesList = async () => {
        let data = await this.props.invoicesList2({
            token: this.props.token,
        });
        this.setState({
            processedInvoicesList: data
        })
    };

    fetchPoNumbers = async () => {
        let data = await this.props.getPoNumber({
            token: this.props.token,
        });
        console.log("data", data, this.state)
        this.setState({
            totalNumberOfPos: data
        })
    };


    onSearchHandler = (value) => {
        console.log("Searched Value", value)
        demoData.q = value.trim();
        console.log("location props pathName is", this.props.location.pathname);
        document.getElementById("myBtn").click();
        console.log("path name after onclick", this.props.location.pathname);
        // let invoiceNo = [];
        // if(this.props.invoicesList!==null){
        //     this.props.invoicesList.map((invoice)=>{
        //         console.log("invoice No in map is",invoice.invoiceNo)
        //         invoiceNo.push(invoice.invoiceNo);
        //         console.log("invoice NO after map",invoiceNo)
        //     })
        // }
        console.log("demo data after props click", demoData.q)

        this.props.invoiceSearch({
            searchTerm: demoData.q,
            token: this.props.token
        })
    };
    // fetch all Invoices
    fetchDocs = () => {
        console.log("calling fetch docs")
        this.props.invoicesList2({
            token: this.props.token,
        })
    }

    // fetch all vendors
    fetchVendors = () => {
        console.log("calling fetch vendors")
        console.log("Token in user Dashboard for all fetch vendors", this.props.token)
        let data = this.props.allVendorsList({
            token: this.props.token
        })
        this.setState({
            vendorsList: data
        })
        console.log('vendors in function', this.state.vendorsList)
    }


    // fetch Purchase Order
    fetchPurchaseOrder = () => {
        console.log("calling fetch Purchase Order")
        console.log("Token in user Dashboard for all fetch vendors", this.props.token)
        let data = this.props.getPurchaseOrder({
            token: this.props.token
        })
        this.setState({
            purchaseOrderList: data
        })
        console.log('Purchase Order in function', this.state.purchaseOrderList)
    }

    // fetch Purchase Order
    fetchItems = () => {
        console.log("calling fetch Items")
        console.log("Token in user Dashboard for all fetch vendors", this.props.token)
        this.props.getItemsList({
            // token:this.props.token
        })
        // this.setState({
        //     purchaseOrderList:data
        // })
        console.log('Purchase Order in function', this.state.purchaseOrderList)
    }



    // handle docs status update

    handleDocStatusUpdate = (invoiceId, newStatus) => {
        console.log("Editing docs meta")
        this.props.updateInvoiceStatusByUser({
            token: this.props.token,
            invoiceId,
            newStatus,
        })

    }

    // handle docs status update 2

    handleDocStatusUpdate2 = (invoiceId, newStatus) => {
        console.log('Editing doc meta: ', invoiceId, newStatus);
        this.props.handleDocStatusUpdate2({
            token: this.props.token,
            invoiceId,
            newStatus,
        });
    };


    onCollapse = collapsed => {
        console.log(collapsed);
        this.setState({ collapsed });
    };

    componentDidMount = () => {
        this.props.tryAlreadyLoggedInProfile()
        this.refreshDocumentsList();
        this.fetchPoNumbers();
        this.fetchVendors();
        this.fetchPurchaseOrder();
        this.fetchItems();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log("Fetching updated invoices : Inside componentDidUpdate :", this.props.invoicesListDirty, prevProps.invoicesListDirty)
        if (this.props.invoicesListDirty === true && prevProps.invoicesListDirty !== true) {
            console.log("Fetching updated invoices : Inside componentDidUpdate")
            this.refreshDocumentsList()

        }
    }
    refreshDocumentsList = () => {
        this.fetchPendingInvoicesList();
        this.fetchProcessedInvoicesList();
        // this.fetchDocs();
        console.log('change status is', this.props.statusChangeLoadingDocs);
        this.fetchDocs(1, { status: this.props.fetchStatus || 'ASSIGNED' });
    }

    render() {

        console.log("State UserDashboard", this.state);
        console.log("get items...", this.props)
        const menu = (
            <Menu style={{ padding: '10px', margin: '15px' }}>
                <Menu.Item key="0">
                    <h5 onClick={this.props.logout}>LOGOUT</h5>
                </Menu.Item>
            </Menu>
        );
        let link = null;
        if (this.props.location.pathname !== "/user-dashboard/searched") {
            link = <Link to="/user-dashboard/searched" >
                <Button id={'myBtn'} type={'primary'} style={{ visibility: 'hidden' }} >Search</Button>
            </Link>
        } else {
            link = <Link to="/user-dashboard/searched2" >
                <Button id={'myBtn'} type={'primary'} style={{ visibility: 'hidden' }} >Search</Button>
            </Link>
        }



        // console.log('Table Data',this.state.tableData);
        // console.log('Auth  Reducer',this.props.auth);
        const meta = this.props.meta;
        const username = meta && meta.data && meta.data.name ? meta.data.name : 'User';
        const userPermission = 'user'
        console.log("invoices List in userDashboard ", this.props.invoicesList);
        console.log("Status change Loading", this.props.statusChangeLoadingDocs);
        console.log("purchase order.....", this.props.purchaseOrderList);
        console.log("Items List...", this.props.itemsList);
        console.log("UserName", username)

        // if(this.state.pendingInvoicesList){
        //     console.log("pending invoice count in user dashboard is",this.state.pendingInvoicesList.length)
        //     console.log("invoices.....",this.state.pendingInvoicesList)

        // }
        return (
            <HashRouter>
                <div>
                    <Layout style={{ height: '120vh', zIndex: 2 }}>
                        <Sider
                            width={250}
                            trigger={null}
                            breakpoint="lg"
                            collapsedWidth="0"
                            onBreakpoint={broken => {
                                console.log(broken);
                            }}
                            onCollapse={(collapsed, type) => {
                                console.log(collapsed, type);
                            }}
                        >
                            <div className="logo">
                                <img style={{ marginLeft: '10%', marginTop: '8%' }} src={logoImage} height="75" width="75" />
                                <span style={{ color: '#FFF', marginLeft: '5%', fontSize: '22px', marginTop: '5%' }}>PayFlow</span>
                            </div>
                            <Menu style={{ padding: '15px' }} theme="dark" mode="inline" defaultSelectedKeys={['Users']}>
                                {/* Pending Invoice side-nav */}
                                <SubMenu
                                    key="invoices"
                                    title={
                                        <span>
                                            <Icon type="copy" theme="filled" />
                                            <span>Invoices</span>
                                        </span>
                                    }
                                >
                                    <Menu.Item key="1">
                                        <Link to={'/user-dashboard/pending'}>
                                            Pending Invoice
            </Link>
                                    </Menu.Item>
                                    {/* Processed Invoice side-nav */}
                                    <Menu.Item key="2">
                                        <Link to={'/user-dashboard/processed'}>
                                            Processed Invoices
            </Link>
                                    </Menu.Item>
                                </SubMenu>

                                {/* Purchase Order Menu */}
                                <SubMenu
                                    key="po"
                                    title={
                                        <span>
                                            <Icon type="video-camera" />
                                            <span >Purchase Order</span>
                                        </span>
                                    }
                                >
                                    {/* Add Purchase Order */}
                                    <Menu.Item key="3">
                                        <Link to={'/user-dashboard/addItems'}>
                                            <span>Add Items</span>
                                        </Link>
                                    </Menu.Item>

                                    {/* View Items */}
                                    <Menu.Item key="4">
                                        <Link to={'/user-dashboard/viewItems'}>
                                            <span>View Items</span>
                                        </Link>
                                    </Menu.Item>

                                    <Menu.Item key="5">
                                        <Link to={'/user-dashboard/addPurchaseOrder'}>
                                            <span>Add Purchase Order</span>
                                        </Link>
                                    </Menu.Item>
                                    {/* view purchase order side-nav */}
                                    <Menu.Item key="6">
                                        <Link to={'/user-dashboard/viewPurchaseOrder'}>
                                            <span>View Purchase Order</span>
                                        </Link>
                                    </Menu.Item>
                                </SubMenu>
                                {/*RFQ/RFQ Response*/}
                                <SubMenu
                                    key="rfq"
                                    title={
                                        <span>
                                            <Icon type="video-camera" />
                                            <span>Intenting System</span>
                                        </span>
                                    }
                                >
                                    <Menu.Item key="7">
                                        <Link to={'/user-dashboard/addRFQ'}>
                                            <span>Add RFQ</span>
                                        </Link>
                                    </Menu.Item>

                                    <Menu.Item key="8">
                                        <Link to={'/user-dashboard/manageRFQ'} >
                                            <span>Manage RFQs</span>
                                        </Link>
                                    </Menu.Item>

                                    {/* <Menu.Item key="9">
                                        <Link >
                                            <span>Sourcing</span>
                                        </Link>
                                    </Menu.Item>

                                    <Menu.Item key="10">
                                        <Link >
                                            <span>Intent</span>
                                        </Link>
                                    </Menu.Item>

                                    <Menu.Item key="11">
                                        <Link >
                                            <span>Comparative</span>
                                        </Link>
                                    </Menu.Item> */}
                                </SubMenu>

                            </Menu>
                        </Sider>
                        <Layout>

                            <div style={{ backgroundColor: '#002140', height: '60px', padding: '8px' }} className="gutter-example" >
                                {link}
                                <Row style={{ float: 'right', marginTop: '5px' }} gutter={20}>
                                    {/* <Col style={{margin:'2px',right:'600px'}} className="gutter-row" span={7}>
                                    <div>
                                      <h3 style={{color:'#FFF'}} >{username}</h3>
                                    </div>
                                  </Col> */}
                                    <Col style={{ margin: '2px', right: '180px' }} className="gutter-row" span={20}>
                                        <div className="gutter-box">
                                            <Search
                                                placeholder="search"
                                                onSearch={this.onSearchHandler}
                                            // style={{ width: 200 }}
                                            />
                                        </div>

                                    </Col>


                                    <Col style={{ margin: '2px', left: '65px', bottom: '36px' }} span={4}>
                                        <Icon style={{ color: '#FFF', fontSize: '30px' }} type="user" />
                                    </Col>
                                    <Col style={{ margin: '2px', left: '65px', bottom: '32px' }}>
                                        <Dropdown overlay={menu} trigger={['click']}>
                                            <a className="ant-dropdown-link" href="#">
                                                <span style={{ color: '#FFF', fontWeight: 'bold', fontSize: '20px' }}>{username}</span><Icon style={{ color: '#FFF' }} type="down" />
                                            </a>
                                        </Dropdown>
                                    </Col>
                                    {/* <Col style={{margin:'2px'}} className="gutter-row" span={1}>
                                    <div className="gutter-box">
                                    <Button type={'primary'}  onClick={this.props.logout}>LOGOUT</Button>
                                     </div>
                                  </Col> */}


                                </Row>
                            </div>
                            <Content style={{ margin: '24px 16px 0' }}>
                                <div style={{ padding: 24, background: '#fff', minHeight: '110vh' }}>
                                    {/* Pending Invoice */}
                                    <Route path='/user-dashboard/pending'>
                                        <PendingInvoice
                                            token={this.props.token}
                                            invoicesList={this.props.invoicesList}
                                            fetchDocs={this.fetchDocs}
                                            handleDocStatusUpdate={this.handleDocStatusUpdate}
                                            handleDocStatusUpdate2={this.handleDocStatusUpdate2}
                                            statusChangeLoadingDocs={this.props.statusChangeLoadingDocs}
                                            onClick={this.componentDidMount}
                                            userPermission={this.props.userPermission}
                                        />
                                    </Route>
                                    <Route path='/user-dashboard/searched'>
                                        <SearchInvoice
                                            token={this.props.token}
                                            invoicesList={this.props.invoicesList}
                                            fetchDocs={this.fetchDocs}
                                            handleDocStatusUpdate={this.handleDocStatusUpdate}
                                            handleDocStatusUpdate2={this.handleDocStatusUpdate2}
                                            onClick={this.componentDidMount}

                                        />
                                    </Route>
                                    <Route path='/user-dashboard/searched2'>
                                        <SearchInvoice
                                            token={this.props.token}
                                            invoicesList={this.props.invoicesList}
                                            fetchDocs={this.fetchDocs}
                                            handleDocStatusUpdate={this.handleDocStatusUpdate}
                                            handleDocStatusUpdate2={this.handleDocStatusUpdate2}
                                            onClick={this.componentDidMount}

                                        />
                                    </Route>
                                    {/* Processed Invoice */}
                                    <Route path='/user-dashboard/processed'>
                                        <ProcessedInvoice
                                            token={this.props.token}
                                            invoicesList={this.props.invoicesList}
                                            fetchDocs={this.fetchDocs}
                                            handleDocStatusUpdate={this.handleDocStatusUpdate}
                                            handleDocStatusUpdate2={this.handleDocStatusUpdate2}
                                            statusChangeLoadingDocs={this.props.statusChangeLoadingDocs}
                                            onClick={this.componentDidMount}
                                            userPermission={this.props.userPermission}
                                        />
                                    </Route>
                                    <Route path="/user-dashboard/add-invoices">
                                        <AddInvoices />
                                    </Route>

                                    <Route path="/user-dashboard/addPurchaseOrder">
                                        <AddPurchaseOrder purchaseOrderNumber={this.props.purchaseOrderNumber} updateMe={this.componentDidMount} itemsList={this.props.itemsList} allVendorData={this.props.vendorsList} />
                                    </Route>

                                    <Route path="/user-dashboard/viewPurchaseOrder">
                                        <ViewPurchaseOrder permission={userPermission} path={"/user-dashboard/printPurchaseOrder"} updateMe={this.componentDidMount} purchaseOrderList={this.props.purchaseOrderList} />
                                    </Route>

                                    <Route path="/user-dashboard/printPurchaseOrder">
                                        <PrintPurchaseOrder userName={username} />
                                    </Route>

                                    <Route path="/user-dashboard/addItems">
                                        <AddItems />
                                    </Route>

                                    <Route path="/user-dashboard/addRFQ">
                                        <AddRFQ />
                                    </Route>

                                    <Route path="/user-dashboard/manageRFQ">
                                        <ViewRFQ />
                                    </Route>

                                    <Route path="/user-dashboard/rfqQuotationList">
                                        <RFQQuotationList />
                                    </Route>

                                    <Route path="/user-dashboard/rfqQuotation">
                                        <RFQQuotation />
                                    </Route>

                                    <Route path="/user-dashboard/viewItems">
                                        <ViewItems itemsList={this.props.itemsList} updateMe={this.componentDidMount} />
                                    </Route>

                                    <Route path="/user-dashboard/viewRFQ">
                                        <ViewRFQ />
                                    </Route>
                                    {/* <Route path ="/user-dashboard/onPrint">
                    <OnPrint/>
                </Route> */}
                                    {/* Rejected Vendors */}
                                    {/* <Route path='/admin-dashboard/rejected'>
            <RejectedVendors tableData={this.props.rejectedVendorsList}/>
            </Route> */}
                                </div>
                            </Content>
                        </Layout>
                    </Layout>
                </div>
            </HashRouter>

        )
    }
}



function mapStateToProps(state) {
    let userPermission = [];
    userPermission = (state.auth.meta && state.auth.meta.data && state.auth.meta.data.permissions) ? state.auth.meta.data.permissions : null;
    return {
        register: state.register,
        vendor: state.vendorReducer,
        token: state.auth.token,
        auth: state.auth,
        meta: state.auth.meta,
        invoicesList: state.vendorReducer.invoicesList,
        vendorsList: state.vendorReducer.vendorsList,
        purchaseOrderList: state.users.purchaseOrderList,
        itemsList: state.users.itemsList,
        userId: state.auth.meta,
        statusChangeLoadingDocs: state.vendorReducer.statusChangeLoadingDocs,
        userPermission: userPermission,
        invoicesListDirty: state.vendorReducer.invoicesListDirty || false,
        purchaseOrderNumber: state.users.purchaseOrderNumber
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ getItemsList: getItemsList, getPurchaseOrder: getPurchaseOrder, getPoNumber, allVendorsList: allVendorsList, invoicesList2: invoicesList, invoiceSearch, updateInvoiceStatusByUser, handleDocStatusUpdate, handleDocStatusUpdate2, logout: logout, tryAlreadyLoggedInProfile: tryAlreadyLoggedInProfile }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(userDashboard)