import React, { Component } from 'react';
import { Layout, Menu, Icon, Switch, Button, Row, Col, Input, Dropdown } from 'antd';
import './vendorDashboard.modules.css'
import { HashRouter } from 'react-router-dom';

import { BrowserRouter as Router, Link, Route } from "react-router-dom";
import AddUser from "../vendorDashboard/users/addUser";
import ManageUsers from "../vendorDashboard/users/manageUser";
import Invoices from "../vendorDashboard/invoices/addInvoices";
import ManageInvoices from "../vendorDashboard/invoices/manageInvoices";
import SearchInvoices from "../vendorDashboard/search/manageInvoices";

// manage payments
import AddPaymentadvoice from '../vendorDashboard/managePayments/addPaymentAdvoice'
import InvoicesList from '../vendorDashboard/managePayments/invoicesList'

// view Purchase Order
import ViewPurchaseOrder from '../vendorDashboard/purchaseOrder/viewPurchaseOrder'
import PrintPurchaseOrder from '../userDashboard/purchaseOrder/printPurchaseOrder'
import { getPurchaseOrder } from '../../actions/userAction'


import Customize from "../Customize";
import MyProfile from "../vendorDashboard/myProfile/myProfile";
import AddLevels from "../vendorDashboard/levels/addLevels";
import ManageLevels from "../vendorDashboard/levels/manageLevels";
import Dashboard from '../vendorDashboard/dashboard/dashboard'

import VendorRFQ from '../rfqResponse/vendorRFQ'
// import ScrollToTop
import ScrollToTop from '../scrollToTop/index'
import { bindActionCreators } from "redux";
// import {vendorRegister} from "../actions/vendorsAction";
import { connect } from "react-redux";
import { vendorsList, fetchLevelsList, fetchUsersList, invoicesList, logout, invoiceSearch } from "../../actions/vendorsAction";
import demoData from "../../constants/demoData";
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const Search = Input.Search;




class VendorDashboard extends Component {
    state = {
        vendorsUsersList: [],
        vendorsInvoicesList: [],
        vendorsLevelsList: [],
        purchaseOrderList: []
    };

    fetchLevels = (cb) => {
        this.props.fetchLevelsList({ token: this.props.token });
    }
    fetchUsers = () => {
        this.props.fetchUsersList({ token: this.props.token });
    }
    fetchInvoices = () => {
        this.props.fetchInvoicesList({ token: this.props.token });
    }

    onCollapse = collapsed => {
        console.log(collapsed);
        this.setState({ collapsed });
    };

    // fetch Purchase Order
    fetchPurchaseOrder = () => {
        console.log("calling fetch Purchase Order")
        console.log("Token in user Dashboard for all fetch vendors", this.props.token)
        let data = this.props.getPurchaseOrder({
            token: this.props.token
        })
        this.setState({
            purchaseOrderList: data
        })
        console.log('Purchase Order in function', this.state.purchaseOrderList)
    }

    componentDidMount = () => {
        console.log("Invoices: Component mounted Vendor Dashboard");

        this.fetchInvoices();
        this.fetchLevels();
        this.fetchUsers();
        this.fetchPurchaseOrder()

    };

    onSearchHandler = (value) => {
        console.log("Searched Value", value)
        demoData.q = value.trim();
        console.log("location props pathName is", this.props.location.pathname);
        document.getElementById("myBtn").click();
        console.log("path name after onclick", this.props.location.pathname);
        console.log("demo data after props click", demoData.q)

        this.props.invoiceSearch({
            searchTerm: demoData.q,
            token: this.props.token
        })
    };



    render() {
        console.log("purchase order.....", this.props.purchaseOrderList)

        const menu = (
            <Menu style={{ padding: '10px', margin: '15px' }}>
                <Menu.Item key="0">
                    <h5 onClick={this.props.logout}>LOGOUT</h5>
                </Menu.Item>
            </Menu>
        );
        console.log("Vendor Main Props", this.props);
        console.log("Purchase order in Vendor Dashboard", this.props.purchaseOrderList)
        const meta = this.props.meta;
        const username = meta && meta.data && meta.data.accountName ? meta.data.accountName : 'User';
        console.log("UserName", username)
        let users = [];
        //  if(this.props.usersList ){
        //         let usersListLength = Object.keys(this.props.usersList).length;
        //         if(usersListLength>0){
        //             this.props.usersList.map((user)=>{
        //                 if(user.createdByVendor._id===this.props.userId){
        //                     users.push(user)
        //                 }
        //             });
        //         }
        //         console.log("Users for Vendors",users);
        //  }
        let levels = [];
        // if(this.props.levelsList ){
        //     let levelsListLength = Object.keys(this.props.levelsList).length;
        //     if(levelsListLength>0){
        //         this.props.levelsList.map((level)=>{
        //             if(level.createdByVendor._id===this.props.userId){
        //                 levels.push(level)
        //             }
        //         });
        //     }
        //     console.log("Levels for Vendors",levels);
        // }
        let invoices = [];
        let searchedInvoices = [];


        if (this.props.invoicesList) {
            let invoicesListLength = Object.keys(this.props.invoicesList).length;
            if (invoicesListLength > 0) {
                this.props.invoicesList.map((invoice) => {
                    if (invoice.createdByVendor._id === this.props.userId) {
                        invoices.push(invoice)
                    }
                });
            }

            if (this.props.searchInvoicesList) {
                let searchInvoicesListLength = Object.keys(this.props.searchInvoicesList).length;
                if (searchInvoicesListLength > 0) {
                    this.props.searchInvoicesList.map((invoice) => {
                        if (invoice.createdByVendor._id === this.props.userId) {
                            searchedInvoices.push(invoice)
                        }
                    });
                }
            }

            console.log("Invoices for Vendors", invoices);


        }

        console.log("Vendor Main State", users, levels, invoices);
        let link = null;
        if (this.props.location.pathname !== "/vendorDashboard/searched") {
            link = <Link to="/vendorDashboard/searched" >
                <Button id={'myBtn'} type={'primary'} style={{ visibility: 'hidden' }} >Search</Button>
            </Link>
        } else {
            link = <Link to="/vendorDashboard/searched2" >
                <Button id={'myBtn'} type={'primary'} style={{ visibility: 'hidden' }} >Search</Button>
            </Link>
        }

        return (
            <HashRouter>
                <div>
                    <Layout style={{ height: '120vh', zIndex: 2 }}>
                        <Sider
                            width={250}
                            height={250}
                            trigger={null}
                            breakpoint="lg"
                            collapsedWidth="0"
                            onBreakpoint={broken => {
                                console.log(broken);
                            }}
                            onCollapse={(collapsed, type) => {
                                console.log(collapsed, type);
                            }}
                        >
                            <div className="logo" />
                            <Menu style={{ padding: '15px' }} theme="dark" mode="inline" defaultSelectedKeys={['Users']}>
                                <Menu.Item key="1">
                                    <Icon type="dashboard" />
                                    <span >
                                        <Link to={'/vendorDashboard/dashboard'} style={{ color: 'white' }} >
                                            Dashboard
                        </Link>
                                    </span>
                                </Menu.Item>
                                <SubMenu
                                    key="invoices"
                                    title={
                                        <span>
                                            <Icon type="copy" theme="filled" />
                                            <span>Invoices</span>
                                        </span>
                                    }
                                >
                                    <Menu.Item key="2">
                                        <Link to={'/vendorDashboard/add-invoices'} style={{ color: 'white' }}>
                                            Add Invoice
                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="3">
                                        <Link to={'/vendorDashboard/manage-invoices'} style={{ color: 'white' }}>
                                            Manage Invoices
                        </Link>
                                    </Menu.Item>
                                </SubMenu>

                                <Menu.Item key="4">
                                    <Icon type="copy" />
                                    <span >
                                        <Link to={'/vendorDashboard/view-purchaseOrder'} style={{ color: 'white' }} >
                                            Purchase Order
                        </Link>
                                    </span>
                                </Menu.Item>

                                <Menu.Item key="5">
                                    <Icon type="user" />
                                    <span >
                                        <Link to={'/vendorDashboard/my-profile'} style={{ color: 'white' }}>
                                            Visit Profile
                        </Link>
                                    </span>
                                </Menu.Item>

                                <Menu.Item key="6">
                                    <Link to={'/vendorDashboard/vendorRFQ'} style={{ color: 'white' }}>
                                        View RFQs
                        </Link>
                                </Menu.Item>

                            </Menu>
                        </Sider>
                        <Layout>
                            <div style={{ backgroundColor: '#002140', height: '60px', padding: '8px' }} className="gutter-example" >
                                {link}
                                <Row style={{ float: 'right', marginTop: '5px' }} gutter={20}>
                                    {/* <Col style={{margin:'2px',right:'600px'}} className="gutter-row" span={7}>
                                    <div>
                                      <h3 style={{color:'#FFF'}} >{username}</h3>
                                    </div>
                                  </Col> */}
                                    <Col style={{ margin: '2px', right: '180px' }} className="gutter-row" span={20}>
                                        <div className="gutter-box">
                                            <Search
                                                placeholder="search"
                                                onSearch={this.onSearchHandler}
                                            // style={{ width: 200 }}
                                            />
                                        </div>

                                    </Col>


                                    <Col style={{ margin: '2px', left: '65px', bottom: '36px' }} span={4}>
                                        <Icon style={{ color: '#FFF', fontSize: '30px' }} type="user" />
                                    </Col>
                                    <Col style={{ margin: '2px', left: '65px', bottom: '32px' }}>
                                        <Dropdown overlay={menu} trigger={['click']}>
                                            <a className="ant-dropdown-link" href="#">
                                                <span style={{ paddingLeft: '40', color: '#FFF', fontWeight: 'bold', fontSize: '20px' }}>{username}</span><Icon style={{ color: '#FFF' }} type="down" />
                                            </a>
                                        </Dropdown>
                                    </Col>
                                    {/* <Col style={{margin:'2px'}} className="gutter-row" span={1}>
                                    <div className="gutter-box">
                                    <Button type={'primary'}  onClick={this.props.logout}>LOGOUT</Button>
                                     </div>
                                  </Col> */}


                                </Row>
                            </div>


                            <ScrollToTop>

                                <Content style={{ margin: '24px 16px 0' }}>
                                    <div style={{ padding: 24, background: '#fff', height: '110vh' }}>
                                        <Route path="/vendorDashboard/dashboard">
                                            <Dashboard usersCount={users.length} levelsCount={levels.length} invoicesCount={invoices.length} />
                                        </Route>
                                        <Route path="/vendorDashboard/add-users">
                                            <AddUser vendorLevels={levels} />
                                        </Route>
                                        <Route path="/vendorDashboard/manage-users">
                                            <ManageUsers vendorUsersList={users} />
                                        </Route>
                                        <Route path="/vendorDashboard/add-invoices">
                                            <Invoices vendorUsersList={users} />
                                        </Route>
                                        <Route path="/vendorDashboard/searched">
                                            <SearchInvoices vendorInvoicesList={searchedInvoices} />
                                        </Route>
                                        <Route path="/vendorDashboard/searched2">
                                            <SearchInvoices vendorInvoicesList={searchedInvoices} />
                                        </Route>
                                        <Route path="/vendorDashboard/manage-invoices">
                                            <ManageInvoices vendorInvoicesList={invoices} />
                                        </Route>
                                        <Route path="/vendorDashboard/customize">
                                            <Customize />
                                        </Route>
                                        <Route path="/vendorDashboard/my-profile">
                                            <MyProfile />
                                        </Route>
                                        <Route path="/vendorDashboard/add-levels">
                                            <AddLevels />
                                        </Route>
                                        <Route path="/vendorDashboard/manage-levels">
                                            <ManageLevels vendorLevelsList={levels} />
                                        </Route>

                                        {/* Manage Payments */}
                                        <Route path="/vendorDashboard/fetch-invoices">
                                            <InvoicesList vendorInvoicesList={invoices} />
                                        </Route>
                                        <Route path="/vendorDashboard/add-payment-Advoice">
                                            <AddPaymentadvoice />
                                        </Route>

                                        {/* Purchase Order */}
                                        <Route path="/vendorDashboard/view-purchaseOrder">
                                            <ViewPurchaseOrder path="/vendorDashboard/printPurchaseOrder" purchaseOrderList={this.props.purchaseOrderList} />
                                        </Route>

                                        <Route path="/vendorDashboard/printPurchaseOrder">
                                            <PrintPurchaseOrder />
                                        </Route>

                                        <Route path="/vendorDashboard/vendorRFQ">
                                            <VendorRFQ />
                                        </Route>


                                    </div>
                                </Content>


                            </ScrollToTop>
                        </Layout>
                    </Layout>
                </div>

            </HashRouter>


        )
    }
}


function mapStateToProps(state) {
    let userId = null;
    userId = (state.auth.meta && state.auth.meta.data && state.auth.meta.data._id) ? state.auth.meta.data._id : null;
    return {
        register: state.register,
        vendor: state.vendorReducer,
        token: state.auth.token,
        meta: state.auth.meta,
        levelsList: state.vendorReducer.levelsList,
        usersList: state.users.usersList,
        purchaseOrderList: state.users.purchaseOrderList,
        invoicesList: state.vendorReducer.invoicesList,
        userId: userId,
        searchInvoicesList: state.vendorReducer.searchInvoicesList,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchVendorsList: vendorsList,
            fetchLevelsList: fetchLevelsList,
            fetchUsersList: fetchUsersList,
            fetchInvoicesList: invoicesList,
            invoiceSearch,
            getPurchaseOrder: getPurchaseOrder,
            logout: logout
        }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(VendorDashboard)
