import React, {Component} from "react";

class Customize extends Component{
    render() {
        return(
            <div>
                Customize
            </div>
        )
    }
}

export  default Customize
