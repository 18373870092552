import React, {Component} from 'react';
import {Button, Cascader, Icon, Table} from "antd";
import {invoicesList} from "../../../actions/vendorsAction";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import APPCONFIG from "../../../constants/appConfig";


class paymentAdvoicesList extends Component{
    // fetchInvoicesList = ()=>{
    //     this.props.invoicesList({
    //         token:this.props.token
    //     })
    //   };

    viewAdvoice = (record)=>{
        const url = `//${APPCONFIG.baseAPIUrl}/advoices/${record.uploadAdvoiceCopy}`;
        window.open(url, '_blank');
    };
    render(){
        let advoicesList = [];
        console.log('invoice list in payment advoice list in admin dash',this.props.paymentAdvoiceList)
        if(this.props.paymentAdvoiceList){
            this.props.paymentAdvoiceList.map((advoice=>{
                console.log('.map advoice',advoice.paymentStatus)
                if(advoice.paymentStatus === 'true'){
                    advoicesList.push(advoice)
                }
            }))
            console.log('advoice in.... ',advoicesList)
        }


        const columns = [
            { title: 'Invoice No', dataIndex: 'invoiceNo', key: 'invoiceNo' },
            { title: 'Total Payment Amount', dataIndex: 'totalPaymentAmount', key: 'totalPaymentAmount' },
            { title: 'Payment Date', dataIndex: 'paymentDate', key: 'paymentDate' },
            { title: 'Advoice Copy', dataIndex: 'uploadAdvoiceCopy', key: 'uploadAdvoiceCopy' ,
            render: (code,record) =>
                <div>
                    <Button type={'primary'} onClick={()=>this.viewAdvoice(record)}>View</Button>
                </div>
        },
        ]
        return(
            <div>
                <div>
                    <h3>Payment Advoices</h3>
                </div>
            <Table
                rowKey={record => record._id}
                scroll={{ x: 'max-content' }}
                columns={columns}
                dataSource={advoicesList}
            />,
        </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        register: state.register,
        vendor: state.vendorReducer,
        token: state.auth.token,
        usersList: state.users.usersList,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ fetchInvoicesList: invoicesList}, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(paymentAdvoicesList)