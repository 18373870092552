import { Select } from 'antd';
import React,{Component} from "react";
const { Option } = Select;



export default class ESelect extends Component{
    render() {
        console.log("Props for ESelect",this.props)
        let options = null;
       if(this.props.optionsList){
           options = this.props.optionsList.map((option)=>{
               return(
                   <Option value={option.value} label={option.label} key={option.value}>
                       {option.label}
                   </Option>
               )
           });
       }
       console.log("options",options)
        console.log("ESelect Default Values",this.props.value);
        // let ids = [];
        // if(this.props.value){
        //     this.props.value.map((value)=>{
        //         ids.push(value.label);
        //     })
        // }
        // console.log("ESelect Default Values IDS",ids);

        return(
            <div>
                    <Select
                        // mode="multiple"
                        style={{ width: '100%' }}
                        //value={this.props.value}
                        placeholder="select one option"
                        onChange={this.props.handleChange}
                        optionLabelProp="label"
                    >
                        {options}
                    </Select>
            </div>
        )
    }
}
