import * as types from '../constants/actionsTypes';
import axios from 'axios';
import { notification } from 'antd';
import AppConfig from '../constants/appConfig';
const querystring = require('querystring');

// Go to dashboard after login success
function goToDashboard() {
    document.getElementById('goToVendorDashboard').click()
}
export function changeUsersList(options = {}) {
    return (dispatch) => {
        return dispatch({ type: types.CHANGE_USER_LIST, data: options });
    }
}


// Add Purchase Order
export function savePurchaseOrder(params = {}) {
    console.log("add Purchase Order params", params);
    return (dispatch => {
        dispatch({ type: types.REQUEST_PURCHASE_ORDER_ADD, data: {} });
        const config = {
            headers: {
                'content-type': 'application/json',
                'Authorization': params.token
            }
        };
        console.log('params', params);
        if (params.purchaseOrderId) {
            axios.post(AppConfig.baseAPIUrl + '/api/purchaseOrder/purchaseOrders/edit', {
                purchaseOrderId: params.purchaseOrderId,
                vendorName: params.vendorName,
                // adminId:params.adminId,
                purchaseOrderNo: params.purchaseOrderNo,
                shippingAddress: params.shippingAddress,
                deliveryAddress: params.deliveryAddress,
                createdDate: params.createdDate,
                itemDetails: params.itemDetails,
                quantity: params.quantity,
                totalAmount: params.totalAmount,
                price: params.price,
                gstRate: params.gstRate,
                itemsDetails: params.itemsDetails,
                // status:params.status
            }, config)
                .then((result) => {
                    const data = result.data && result.data.data && result.data.data._id ? result.data.data : {};
                    console.log("UserActions: ADDPURCHASEORDER: ADD PURCHASE ORDER result success: ", data);
                    dispatch({ type: types.RESPONSE_PURCHASE_ORDER_ADD, data: { error: false, data, remember: params.remember } });
                    openNotificationWithIcon('success', 'Saved Purchase Order!', 'Successfully saved the Purchase Order!');

                })
                .catch((error) => {
                    let data = { error: true };
                    try {
                        data['errors'] = error.response.data.errors;
                        console.log("UserActions: tryADDPURCHASEORDER: ADD PURCHASE ORDER result failed: ", error);
                        console.log("Error response: ", error.response);
                        console.log("Error response body: ", error.response.body);
                    }
                    catch (ex) {
                        data['errors'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
                    }
                    openNotificationWithIcon('error', 'Add Purchase Order Failed!', 'Please try again!');
                    dispatch({ type: types.RESPONSE_PURCHASE_ORDER_ADD, data: data });
                });
        } else {
            axios.post(AppConfig.baseAPIUrl + '/api/purchaseOrder/purchaseOrders/create', {
                vendorName: params.vendorName,
                // adminId:params.adminId,
                purchaseOrderNo: params.purchaseOrderNo,
                shippingAddress: params.shippingAddress,
                deliveryAddress: params.deliveryAddress,
                createdDate: params.createdDate,
                itemDetails: params.itemDetails,
                quantity: params.quantity,
                totalAmount: params.totalAmount,
                price: params.price,
                gstRate: params.gstRate,
                itemsDetails: params.itemsDetails,
                status: params.status
            }, config)
                .then((result) => {
                    const data = result.data && result.data.data && result.data.data._id ? result.data.data : {};
                    console.log("UserActions: ADDPURCHASEORDER: ADD PURCHASE ORDER result success: ", data);
                    dispatch({ type: types.RESPONSE_PURCHASE_ORDER_ADD, data: { error: false, data, remember: params.remember } });
                    openNotificationWithIcon('success', 'Saved Purchase Order!', 'Successfully saved the Purchase Order!');

                })
                .catch((error) => {
                    let data = { error: true };
                    try {
                        data['errors'] = error.response.data.errors;
                        console.log("UserActions: tryADDPURCHASEORDER: ADD PURCHASE ORDER result failed: ", error);
                        console.log("Error response: ", error.response);
                        console.log("Error response body: ", error.response.body);
                    }
                    catch (ex) {
                        data['errors'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
                    }
                    openNotificationWithIcon('error', 'Add Purchase Order Failed!', 'Please try again!');
                    dispatch({ type: types.RESPONSE_PURCHASE_ORDER_ADD, data: data });
                });
        }





    })
}

// update RFQ Quotation Status 
export function updateRFQQuotation(params = {}) {


}



// update PurchaseOrder Status
export function updatePurchaseOrder(params = {}) {
    console.log("params", params);
    return (dispatch => {
        dispatch({ type: types.REQUEST_PURCHASE_ORDER_UPDATE, data: {} });
        const config = {
            headers: {
                'content-type': 'application/json',
                'Authorization': params.token,
            }
        };
        axios.post(AppConfig.baseAPIUrl + '/api/purchaseOrder/purchaseOrders/update', params, config)
            .then((result) => {
                const data = result.data && result.data.data ? result.data.data : {};
                console.log("UserAction: PO: Purchase Order result success: ", data);
                dispatch({ type: types.RESPONSE_PURCHASE_ORDER_UPDATE, data });
                return data
            })
            .catch((error) => {
                let data = { error: true };
                try {
                    data['errors'] = error.response.data.errors;
                }
                catch (ex) {
                    data['errors'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
                }
                dispatch({ type: types.RESPONSE_PURCHASE_ORDER_UPDATE, data: {} });
                return data
            });
    })
}



// Add Items 
export function saveItems(params = {}) {
    console.log("add Purchase Order params", params);
    return (dispatch => {
        dispatch({ type: types.REQUEST_ITEMS_ADD, data: {} });
        const config = {
            headers: {
                'content-type': 'application/json',
                'Authorization': params.token
            }
        };
        console.log('params', params);
        if (params.itemId) {
            console.log("start Editing...");
            axios.post(AppConfig.baseAPIUrl + '/api/item/edit', {
                itemId: params.itemId,
                itemName: params.itemName,
                partNumber: params.partNumber,
                price: params.price,
                taxRate: params.taxRate
            }, config)
                .then((result) => {
                    const data = result.data && result.data.data && result.data.data._id ? result.data.data : {};
                    console.log("UserActions: ADDITEMS: ADD ITEMS result success: ", data);
                    dispatch({ type: types.RESPONSE_ITEMS_ADD, data: { error: false, data, remember: params.remember } });
                    openNotificationWithIcon('success', 'Saved Items!', 'Successfully saved the Items!');

                })
                .catch((error) => {
                    let data = { error: true };
                    try {
                        data['errors'] = error.response.data.errors;
                        console.log("UserActions: tryADDITEMS: ADD ITEMS result failed: ", error);
                        console.log("Error response: ", error.response);
                        console.log("Error response body: ", error.response.body);
                    }
                    catch (ex) {
                        data['errors'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
                    }
                    openNotificationWithIcon('error', 'Add Items Failed!', 'Please try again!');
                    dispatch({ type: types.RESPONSE_ITEMS_ADD, data: data });
                });
        }
        else {
            axios.post(AppConfig.baseAPIUrl + '/api/item/create', {
                itemName: params.itemName,
                partNumber: params.partNumber,
                price: params.price,
                taxRate: params.taxRate
            }, config)
                .then((result) => {
                    const data = result.data && result.data.data && result.data.data._id ? result.data.data : {};
                    console.log("UserActions: ADDITEMS: ADD ITEMS result success: ", data);
                    dispatch({ type: types.RESPONSE_ITEMS_ADD, data: { error: false, data, remember: params.remember } });
                    openNotificationWithIcon('success', 'Saved Items!', 'Successfully saved the Items!');

                })
                .catch((error) => {
                    let data = { error: true };
                    try {
                        data['errors'] = error.response.data.errors;
                        console.log("UserActions: tryADDITEMS: ADD ITEMS result failed: ", error);
                        console.log("Error response: ", error.response);
                        console.log("Error response body: ", error.response.body);
                    }
                    catch (ex) {
                        data['errors'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
                    }
                    openNotificationWithIcon('error', 'Add Items Failed!', 'Please try again!');
                    dispatch({ type: types.RESPONSE_ITEMS_ADD, data: data });
                });
        }





    })
}










// Delete Item
export function deleteItem(params = {}) {
    console.log(params);
    return (dispatch => {
        dispatch({ type: types.REQUEST_ITEM_DELETE, data: {} });
        const config = {
            headers: {
                'content-Type': 'application/json',
                // Authorization: params.token
            }
        };
        axios.post(AppConfig.baseAPIUrl + '/api/item/delete', {
            itemId: params.itemId,
        }, config)
            .then((result) => {
                const data = result.data && result.data.data && result.data.data._id ? result.data.data : {};
                // console.log("FormAction: Form: Delete Form result success: ", data);
                dispatch({ type: types.RESPONSE_ITEM_DELETE, data: { error: false, data, remember: params.remember } });
                openNotificationWithIcon('success', 'Delete Item!', 'Successfully delete item');
                console.log("deleted Item ", data);
            })
            .catch((error) => {
                let data = { error: true };
                try {
                    data['errors'] = error.response.data.errors;
                    // console.log("formAction: tryFormDeletion: form delete result failed: ", error);
                    // console.log("Error response: ", error.response);
                    // console.log("Error response body: ", error.response.body);
                }
                catch (ex) {
                    data['errors'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
                }
                openNotificationWithIcon('error', 'Delete Item Failed!', 'Failed delete item');
                dispatch({ type: types.RESPONSE_ITEM_DELETE, data: data });
            });
    })
}



// Delete Purchase Order
export function deletePurchaseOrder(params = {}) {
    console.log("auth token in user action...", params.token);
    return (dispatch => {
        dispatch({ type: types.REQUEST_PURCHASE_ORDER_DELETE, data: {} });
        const config = {
            headers: {
                'content-Type': 'application/json',
                Authorization: params.token
            }
        };
        axios.post(AppConfig.baseAPIUrl + '/api/purchaseOrder/purchaseOrders/delete', {
            purchaseOrderId: params.purchaseOrderId,
        }, config)
            .then((result) => {
                const data = result.data && result.data.data && result.data.data._id ? result.data.data : {};
                // console.log("FormAction: Form: Delete Form result success: ", data);
                dispatch({ type: types.RESPONSE_PURCHASE_ORDER_DELETE, data: { error: false, data, remember: params.remember } });
                openNotificationWithIcon('success', 'Delete Purchase Order!', 'Successfully delete purchase order');
                console.log("deleted purchase order ", data);
            })
            .catch((error) => {
                let data = { error: true };
                try {
                    data['errors'] = error.response.data.errors;
                    // console.log("formAction: tryFormDeletion: form delete result failed: ", error);
                    // console.log("Error response: ", error.response);
                    // console.log("Error response body: ", error.response.body);
                }
                catch (ex) {
                    data['errors'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
                }
                openNotificationWithIcon('error', 'Delete purchase order Failed!', 'Failed delete purchase order');
                dispatch({ type: types.RESPONSE_PURCHASE_ORDER_DELETE, data: data });
            });
    })
}



export function fetchUsersList(params = {}) {
    return (dispatch) => {
        dispatch({ type: types.REQUEST_USER_LIST, data: {} });
        axios.get(AppConfig.baseAPIUrl + '/api/user/get/list', {
            headers: {
                Authorization: params.token
            }
        })
            .then((result) => {
                const usersList = result.data && result.data.data ? result.data.data : [];
                dispatch({ type: types.RESPONSE_USER_LIST, data: { error: false, usersList: usersList } });
            })
            .catch((error) => {
                dispatch({ type: types.RESPONSE_USER_LIST, data: { error: true, errorMsg: error.message, usersList: [] } });
            });

    }
}


// // Fetch Items list
// export function fetchItemsList(params={}) {
//     return (dispatch) => {
//         dispatch({ type: types.REQUEST_ITEMS_FETCH, data: {} });
//         axios.get(AppConfig.baseAPIUrl+'/api/user/get/list', {
//             headers: {
//                 Authorization: params.token
//             }
//         })
//             .then((result) => {
//                 const itemsList = result.data && result.data.data ? result.data.data : [];
//                 dispatch({ type: types.RESPONSE_ITEMS_FETCH, data: { error: false, itemsList: itemsList } });
//             })
//             .catch((error)=>{
//                 dispatch({ type: types.RESPONSE_ITEMS_FETCH, data: {error: true, errorMsg: error.message, itemsList: [] } });
//             });

//     }
// }



// Fetch all purchase order lists
export function getPurchaseOrder(params = {}) {
    console.log("Fetching Purchase Order in User Action");
    console.log("purchase order", params);
    return (dispatch => {
        dispatch({ type: types.RESPONSE_PURCHASE_ORDER_FETCH, data: {} });
        const config = {
            headers: {
                'content-type': 'application/json',
                'Authorization': params.token,
            }
        };
        axios.get(AppConfig.baseAPIUrl + '/api/purchaseOrder/purchaseOrders', config)
            .then((result) => {
                const data = result.data && result.data.data ? result.data.data : {};
                console.log("UserActions: FetchPurchaseOrder: Purchase order result success: ", data);
                dispatch({ type: types.RESPONSE_PURCHASE_ORDER_FETCH, data: { error: false, data, remember: params.remember } });
                return data
            })
            .catch((error) => {
                let data = { error: true };
                try {
                    data['errors'] = error.response.data.errors;
                }
                catch (ex) {
                    data['errors'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
                }
                dispatch({ type: types.RESPONSE_PURCHASE_ORDER_FETCH, data: {} });
                return data

            });
    })
}




// Fetch all Items lists
export function getItemsList(params = {}) {
    console.log("Fetching Items List in User Action");
    console.log("Items", params);
    return (dispatch => {
        dispatch({ type: types.RESPONSE_ITEMS_FETCH, data: {} });
        const config = {
            headers: {
                'content-type': 'application/json',
                'Authorization': params.token,
            }
        };
        axios.get(AppConfig.baseAPIUrl + '/api/item/list', config)
            .then((result) => {
                const data = result.data && result.data.data ? result.data.data : {};
                console.log("UserActions: FetchItemsList: Items List result success: ", data);
                dispatch({ type: types.RESPONSE_ITEMS_FETCH, data: { error: false, data, remember: params.remember } });
                return data
            })
            .catch((error) => {
                let data = { error: true };
                try {
                    data['errors'] = error.response.data.errors;
                }
                catch (ex) {
                    data['errors'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
                }
                dispatch({ type: types.RESPONSE_ITEMS_FETCH, data: {} });
                return data

            });
    })
}







// update user action creator

// update user action creator
export const updateUser = (params = {}, userId) => {
    console.log('addUser', params, userId);
    return (dispatch => {
        dispatch({ type: types.REQUEST_ADD_USER, data: {} });
        const config = {
            headers: {
                Authorization: params.token,
                'Content-Type': 'application/json'
            }
        };

        const body = params.formData;
        console.log("body", body);
        if (userId) {
            return axios.post(AppConfig.baseAPIUrl + `/api/auth/edit`, body, config)
                .then((result) => {
                    const data = result.data && result.data.data && result.data.data._id ? result.data.data : {};
                    console.log('user save result', data);
                    dispatch({ type: types.RESPONSE_ADD_USER, data: { error: false, data: data } });
                    openNotificationWithIcon('success', 'Saved User!', 'Successfully saved the user details');

                    //return {error:false,data:result.data};
                })
                .catch((error) => {
                    let data = { error: true };
                    try {
                        data['errorMsg'] = error.response.data.errors;
                    }
                    catch (ex) {
                        data['errorMsg'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
                    }
                    //  openNotificationWithIcon('error', 'Failed User!', 'Please try again');
                    dispatch({ type: types.RESPONSE_ADD_USER, data: { error: false, data } });
                    return data

                    //return {error:true,data:data};//dispatch({ type: types.RESPONSE_USER_SAVE, data: data });
                });
        } else {
            return axios.post(AppConfig.baseAPIUrl + `/api/auth/signup`, body, config)
                .then((result) => {
                    const data = result.data && result.data.data && result.data.data._id ? result.data.data : {};

                    console.log('user save result', data);
                    dispatch({ type: types.RESPONSE_ADD_USER, data: { error: false, data: data } });
                    openNotificationWithIcon('success', 'Saved User!', 'Successfully saved the user details');

                    return data

                    //                    return {error:false,data:result.data};
                })
                .catch((error) => {
                    let data = { error: true };
                    try {
                        data['errorMsg'] = error.response.data.errors;
                    }
                    catch (ex) {
                        data['errorMsg'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
                    }
                    dispatch({ type: types.RESPONSE_ADD_USER, data: { error: false, data } });
                    return data

                    //                  return {error:true,data:data};//dispatch({ type: types.RESPONSE_USER_SAVE, data: data });
                });
        }

    })
};



export function saveUser(params) {
    return (dispatch => {
        dispatch({ type: types.REQUEST_USER_SAVE, data: {} });
        const config = {
            headers: {
                Authorization: params.token,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };

        const body = querystring.stringify(params.formData);
        axios.post(AppConfig.baseAPIUrl + '/api/user/edit/basics', body, config)
            .then((result) => {
                dispatch({ type: types.RESPONSE_USER_SAVE, data: { error: false, success: true } });
            })
            .catch((error) => {
                let data = { error: true };
                try {
                    data['errorMsg'] = error.response.data.errors;
                }
                catch (ex) {
                    data['errorMsg'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
                }
                dispatch({ type: types.RESPONSE_USER_SAVE, data: data });
            });
    })

}



// export function adminLogin(params={}){
//     console.log(params);
//     return(dispatch=>{
//         dispatch({type:types.AUTH_REQUEST_LOGIN,data:{}});
//         const config = {
//             headers: {
//                 'content-type': 'application/json'
//             }
//         };
//         axios.post(AppConfig.baseAPIUrl+'/api/auth/admin/signin', {
//             email: params.email,
//             password: params.password,
//         }, config)
//             .then((result) => {
//                 const data = result.data && result.data.data && result.data.data._id ? result.data.data : {};
//                 console.log("authActions: loginUser: Login user result success: ", data);
//                 dispatch({ type: types.AUTH_RESPONSE_LOGIN, data: { error: false, data, remember: params.remember } });
//                 goToDashboard()
//             })
//             .catch((error) => {
//                 let data = { error: true };
//                 try {
//                     data['errors'] = error.response.data.errors;
//                     // console.log("authActions: tryLogin: sign-in User result failed: ", error);
//                     // console.log("Error response: ", error.response);
//                     // console.log("Error response body: ", error.response.body);
//                 }
//                 catch (ex) {
//                     data['errors'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
//                 }
//                 dispatch({ type: types.LOGIN_VENDOR, data: data });
//             });
//     })
// }


export function userLogin(params = {}) {
    console.log(params);
    return (dispatch => {
        dispatch({ type: types.AUTH_REQUEST_LOGIN, data: {} });
        const config = {
            headers: {
                'content-type': 'application/json'
            }
        };
        axios.post(AppConfig.baseAPIUrl + '/api/auth/signin', {
            email: params.email,
            password: params.password,
        }, config)
            .then((result) => {
                const data = result.data && result.data.data && result.data.data._id ? result.data.data : {};
                console.log("authActions: loginUser: Login user result success: ", data);
                saveProfileToLocalStore(data);
                dispatch({ type: types.AUTH_RESPONSE_LOGIN, data: { error: false, data, remember: params.remember } });
                goToDashboard()
            })
            .catch((error) => {
                let data = { error: true };
                try {
                    data['errors'] = error.response.data.errors;
                    // console.log("authActions: tryLogin: sign-in User result failed: ", error);
                    // console.log("Error response: ", error.response);
                    // console.log("Error response body: ", error.response.body);
                    openNotificationWithIcon('error', 'Login Failed!', 'Please try again!');
                }
                catch (ex) {
                    data['errors'] = [{ param: 'Network Error', msg: 'Unable to connect. Please ensure your network is connected properly.' }]
                }
                dispatch({ type: types.LOGIN_VENDOR, data: data });
            });
    })
}
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message,
        description,
    });
};


export function tryAlreadyLoggedInProfile() {
    console.log("trying already logIn")
    return (dispatch => {
        let data = loadProfileFromLocalStore();
        if (data && data.length) {
            try {
                data = JSON.parse(atob(data));
                return dispatch({ type: types.AUTH_RESPONSE_LOGIN, data: { error: false, data: data } });
            }
            catch (ex) {
                console.log("Error loading profile: ", ex, data);
            }
        }

    })
}



function loadProfileFromLocalStore() {
    //const authToken = localStorage.getItem("thtt");
    const data = localStorage.getItem('thup');
    return data;
}

function saveProfileToLocalStore(data) {
    localStorage.setItem("thup", btoa(JSON.stringify(data)));
    //localStorage.setItem("thtt", btoa(data.token));
}


export function logout() {
    return (dispatch => {
        deleteProfileFromLocalStore();
        return dispatch({ type: types.AUTH_DO_LOGOUT, data: { error: false, data: {} } });
    })
}

function deleteProfileFromLocalStore() {
    return localStorage.removeItem('thup');
}