export let REQUEST_STATUS_UPDATE = "REQUEST_STATUS_UPDATE";
export let RESPONSE_STATUS_UPDATE = "RESPONSE_STATUS_UPDATE";
export let RESPONSE_INVOICE_EDIT = "RESPONSE_INVOICE_EDIT";
export let REQUEST_INVOICE_EDIT = "REQUEST_INVOICE_EDIT";
export let CHANGE_USER_LIST = "CHANGE_USER_LIST";
export let REQUEST_USER_SAVE = "REQUEST_USER_SAVE";
export let RESPONSE_USER_SAVE = "RESPONSE_USER_SAVE";
export let RESPONSE_USER_LIST = "RESPONSE_USER_LIST";
export let REQUEST_USER_LIST = "REQUEST_USER_LIST";
export let RESPONSE_VENDOR_UPDATE = 'RESPONSE_VENDOR_UPDATE';
export let REQUEST_VENDOR_UPDATE = 'REQUEST_VENDOR_UPDATE';
export let RESPONSE_REJECTED_VENDOR_LIST = 'RESPONSE_REJECTED_VENDOR_LIST';
export let RESPONSE_APPROVED_VENDOR_LIST = 'RESPONSE_APPROVED_VENDOR_LIST';
export let RESPONSE_PENDING_VENDOR_LIST = 'RESPONSE_PENDING_VENDOR_LIST';
export let RESPONSE_VENDOR_LIST = 'RESPONSE_VENDOR_LIST';
export let REQUEST_VENDOR_LIST = 'REQUEST_VENDOR_LIST';


// Fetch all vendors list
export let RESPONSE_VENDOR_FETCH = 'RESPONSE_VENDOR_FETCH';
export let REQUEST_VENDOR_FETCH = 'REQUEST_VENDOR_FETCH';



//Auth related constants
export const AUTH_RESPONSE_LOGIN = 'AUTH_RESPONSE_LOGIN';
export const AUTH_REQUEST_LOGIN = 'AUTH_REQUEST_LOGIN';
export const AUTH_DO_LOGOUT = 'AUTH_DO_LOGOUT';

//Registration vendor constants
export const REGISTRARTION_VENDOR = 'Registration_VENDOR';
export const REGISTRATION_RESPONSE_VENDOR = 'REGISTRATION_RESPONSE_VENDOR'
export const REGISTRATION_REQUEST_VENDOR = 'REGISTRATION_REQUEST_VENDOR'

//Login vendor constants
export const LOGIN_VENDOR = 'LOGIN_VENDOR';
export const LOGIN_RESPONSE_VENDOR = 'LOGIN_RESPONSE_VENDOR';
export const LOGIN_REQUEST_VENDOR = 'LOGIN_REQUEST_VENDOR';

//Login Admin constants
export const LOGIN_ADMIN = 'LOGIN_ADMIN';
export const LOGIN_RESPONSE_ADMIN = 'LOGIN_RESPONSE_ADMIN';
export const LOGIN_REQUEST_ADMIN = 'LOGIN_REQUEST_ADMIN';


// Add Levels constants
export const ADD_LEVEL = 'ADD_LEVEL';
export const ADD_RESPONSE_LEVEL = 'ADD_RESPONSE_LEVEL'
export const ADD_REQUEST_LEVEL = 'ADD_REQUEST_LEVEL'

// Add User Constants
export const RESPONSE_ADD_USER = 'RESPONSE_ADD_USER'
export const REQUEST_ADD_USER = 'REQUEST_ADD_USER'


// Fetch Levels Constant
export let RESPONSE_LEVEL_FETCH = 'RESPONSE_LEVEL_FETCH';
export let REQUEST_LEVEL_FETCH = 'REQUEST_LEVEL_FETCH';

// Fetch Users Constant
export let RESPONSE_USER_FETCH = 'RESPONSE_USER_FETCH';
export let REQUEST_USER_FETCH = 'REQUEST_USER_FETCH';

//Add Invoices Constants
export const RESPONSE_INVOICE_ADD = 'RESPONSE_INVOICE_ADD'
export const REQUEST_INVOICE_ADD = 'REQUEST_INVOICE_ADD'

//Add Invoices Constants
export const RESPONSE_INVOICE_DELETE = 'RESPONSE_INVOICE_DELETE'
export const REQUEST_INVOICE_DELETE = 'REQUEST_INVOICE_DELETE'


// Fetch Invoice Constants
export let RESPONSE_INVOICE_FETCH = 'RESPONSE_INVOICE_FETCH';
export let REQUEST_INVOICE_FETCH = 'REQUEST_INVOICE_FETCH';

// Fetch PO total count
export let RESPONSE_PO_NUMBER = 'RESPONSE_PO_NUMBER';
export let REQUEST_PO_NUMBER = 'REQUEST_PO_NUMBER';



// Invoices Status Update Constants
export let RESPONSE_REJECTED_INVOICE_LIST = 'RESPONSE_REJECTED_INVOICE_LIST';
export let RESPONSE_APPROVED_INVOICE_LIST = 'RESPONSE_APPROVED_INVOICE_LIST';
export let RESPONSE_PENDING_INVOICE_LIST = 'RESPONSE_PENDING_INVOICE_LIST';

// Invoice Search Constants
export let RESPONSE_INVOICE_SEARCH = 'RESPONSE_INVOICE_SEARCH';
export let REQUEST_INVOICE_SEARCH = 'REQUEST_INVOICE_SEARCH';


// AddPayment Advoice Constants
export const RESPONSE_PAYMENT_ADVOICE_ADD = 'RESPONSE_PAYMENT_ADVOICE_ADD'
export const REQUEST_PAYMENT_ADVOICE_ADD = 'REQUEST_PAYMENT_ADVOICE_ADD'

// Add Purchase Order Constants
export const RESPONSE_PURCHASE_ORDER_ADD = 'RESPONSE_PURCHASE_ORDER_ADD'
export const REQUEST_PURCHASE_ORDER_ADD = 'REQUEST_PURCHASE_ORDER_ADD'

// Add Items For Purchase Order Constants
export const RESPONSE_ITEMS_ADD = 'RESPONSE_ITEMS_ADD'
export const REQUEST_ITEMS_ADD = 'REQUEST_ITEMS_ADD'

//Delete Purchase Order  Constants
export const RESPONSE_PURCHASE_ORDER_DELETE = 'RESPONSE_PURCHASE_ORDER_DELETE'
export const REQUEST_PURCHASE_ORDER_DELETE = 'REQUEST_PURCHASE_ORDER_DELETE'

//Delete Items Constants
export const RESPONSE_ITEM_DELETE = 'RESPONSE_ITEM_DELETE'
export const REQUEST_ITEM_DELETE = 'REQUEST_ITEM_DELETE'

// Fetch Items list
export let RESPONSE_ITEMS_FETCH = 'RESPONSE_ITEMS_FETCH';
export let REQUEST_ITEMS_FETCH = 'REQUEST_ITEMS_FETCH';

// Fetch Purchase Order COnstants
export let RESPONSE_PURCHASE_ORDER_FETCH = 'RESPONSE_PURCHASE_ORDER_FETCH';
export let REQUEST_PURCHASE_ORDER_FETCH = 'REQUEST_PURCHASE_ORDER_FETCH';

//Update Purchase Order Constants
export let RESPONSE_PURCHASE_ORDER_UPDATE = 'RESPONSE_PURCHASE_ORDER_UPDATE';
export let REQUEST_PURCHASE_ORDER_UPDATE = 'REQUEST_PURCHASE_ORDER_UPDATE';

//Reset Vendor Password Constants
export let RESPONSE_RESET_PASSWORD = 'RESPONSE_RESET_PASSWORD';
export let REQUEST_RESET_PASSWORD = 'REQUEST_RESET_PASSWORD';


// Invoices Status Change Constants

export const CHANGE_DOCS_LIST = 'CHANGE_DOCS_LIST';
export const REQUEST_DOCS_LIST = 'REQUEST_DOCS_LIST';
export const RESPONSE_DOCS_LIST = 'RESPONSE_DOCS_LIST';
export const REQUEST_DOC_DATA = 'REQUEST_DOC_DATA';
export const RESPONSE_DOC_DATA = 'RESPONSE_DOC_DATA';
export const REQUEST_DOC_SAVE = 'REQUEST_DOC_SAVE';
export const RESPONSE_DOC_SAVE = 'RESPONSE_DOC_SAVE';
export const CHANGE_DOC_META = 'CHANGE_DOC_META';
export const UPDATE_DOC_REQUEST = 'UPDATE_DOC_REQUEST';
export const UPDATE_DOC_SUCCESS = 'UPDATE_DOC_SUCCESS';
export const UPDATE_DOC_FAILURE = 'UPDATE_DOC_FAILURE';

export const REQUEST_DOC_META_UPDATE = 'REQUEST_DOC_META_UPDATE';
export const RESPONSE_DOC_META_UPDATE = 'RESPONSE_DOC_META_UPDATE';
export const REQUEST_DOC_STATUS_UPDATE = 'REQUEST_DOC_STATUS_UPDATE';
export const RESPONSE_DOC_STATUS_UPDATE = 'RESPONSE_DOC_STATUS_UPDATE';