import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Form,
    Input,
    Tooltip,
    Icon,
    Cascader,
    Select,
    Row,
    Col,
    Upload,
    Checkbox,
    Button,
    AutoComplete,
} from 'antd';
import {addDepartments} from '../../../actions/vendorsAction'

class AddDepartments extends React.Component {
    state = {
        confirmDirty: false,
        autoCompleteResult: [],
    };

    normFile = e => {
        this.setState({file:e.fileList[0]});
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;

    };

    handleSubmit = e => {
        e.preventDefault();
     
        this.props.form.validateFieldsAndScroll((err, values) => {
          if (!err) {
            console.log('Received values of form: ', values);
            this.props.addLevels(  
             {
                levelNo:values.levelNo,
                levelName:values.levelName,
                description:values.description,
                token:this.props.token,
                 createdByVendor:this.props.vendorId
             } 
            )
          }
        });
      };


    render() {
        const { getFieldDecorator } = this.props.form;
        console.log('level meta is',this.props.levelMeta);
        const formItemLayout = {
            labelCol: {
                xs: { span: 5 },
                sm: { span: 5 },
            },
            wrapperCol: {
                xs: { span: 5 },
                sm: { span: 15 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };



        return (
            <div>
                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                <Form.Item label="Level No">
                    {getFieldDecorator('levelNo', {
                        rules: [
                            {
                                required: true,
                                message: 'Please input your Level No'
                            },
                        ],
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="Level Name" hasFeedback>
                    {getFieldDecorator('levelName', {
                        rules: [
                            {
                                required: true,
                                message: 'Please input your Level Name!',
                            },
                        ],
                    })(<Input/>)}
                </Form.Item>
                <Form.Item label="Description" hasFeedback>
                    {getFieldDecorator('description', {
                        rules: [
                            {
                                required: true,
                                message: 'Please input your description',
                            },
                        ],
                    })(<Input />)}
                </Form.Item>


                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        Add Level
                    </Button>
                </Form.Item>
            </Form>
            </div>
            
        );
    }
}



const WrappedLogin = Form.create()(AddDepartments)


function mapStateToProps(state) {
    let userId = null;
    userId = (state.auth.meta && state.auth.meta.data && state.auth.meta.data._id ) ? state.auth.meta.data._id : null;
  return {
      register: state.register,
      isRegistration: state.register.isRegistration,
      levelMeta : state.vendorReducer.levelMeta,
      token:state.auth.token,
      vendorId:userId
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({ addDepartments: addDepartments }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(WrappedLogin)
