import React from 'react';
import { connect } from 'react-redux';
import {bindActionCreators} from "redux";
import { Link } from 'react-router-dom';
import InvoicePendingUpdate from './InvoicePendingUpdate';
import APPCONFIG from "../../constants/appConfig";

import {
  Table,
  List,
  Icon,
  Radio,
  Button,
  Timeline,
  Tooltip,
  Tabs,
  Divider,
  Tag,
  notification,
  Row,
  Col, DatePicker,
} from 'antd';
import moment from 'moment';
import {invoicesList} from "../../actions/vendorsAction";
// import * as actions from '../../../../../actions/deviceActions';
// import Notification from '../../../../../components/Notification';
import { withRouter } from 'react-router-dom';

// import demoData from '../../../../../../constants/demoData.js'
const { RangePicker } = DatePicker;

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const TabPane = Tabs.TabPane;

const style = {

}

class PendingInvoices extends React.Component {
  state = {
    pagination: {},
// userDevices: {}
//loading: false,
    expandedRowKeys: [],

  };
  formattedStatusTag = (recordStatus) => {
    let status = recordStatus;
    switch(recordStatus){
      case 'ASSIGNED':
        status = <Tag color='blue'>L1 Assigned</Tag>;
        break;
      case 'ONHOLD':
        status = <Tag color='blue'>L1 On-Hold</Tag>;
        break;
      case 'PROCESSED':
        status = <Tag color='blue'>L1 Parked</Tag>;
        break;
      case 'REJECTED':
        status = <Tag color='volcano'>L1 Rejected</Tag>;
        break;
      case 'PARKED':
        status = <Tag color='blue'>L2 On-Hold</Tag>;
        break;
      case 'POSTED':
        status = <Tag color='green'>L2 Posted</Tag>;
        break;
      case 'L2_REJECTED':
        status = <Tag color='volcano'>L2 Rejected</Tag>;
        break;
      default:
        status = <Tag color='volcano'>{recordStatus}</Tag>;
        break;
    }
    return status;
  }



  formatL1DocsStatusButtons = (status, record) => {
    return (
        <RadioGroup
            buttonStyle="solid"
            size="small"
            defaultValue={status}
            onChange={e => {
              console.log("handle status change clicked");
              this.handleStatusChange(record._id, e.target.value, null, record);
            }}
        >
          <RadioButton disabled={status === 'PROCESSED'} value="ONHOLD">
            ONHOLD
          </RadioButton>
          <RadioButton value="PROCESSED">PARKED</RadioButton>
          <RadioButton value="REJECTED">L1_REJECTED</RadioButton>

        </RadioGroup>
    );
  };

  columns = [
    {
      title: 'Invoice No',
      dataIndex: 'invoiceNo',
      width:150,
      sorter: (a, b) => {
        a = a.invoiceNo || '';
        b = b.invoiceNo || '';
        return a.localeCompare(b);
      },
//render: name => `${name.first} ${name.last}`,
      key: 'invoiceNo',
//   render: (code, record) => {
//     let path = record.filePath || '';
//     path = path.split('/').slice(1, path.length).join('/');
//     const link = "https://securedrive.nativebyte.co/remote.php/webdav/"+path;
//     return(
//       <a
//         href={link}
//         target="_blank"
//       >
//         {code}
//       </a>
//     );
//   }
    },
    {
      title: 'Services Department',
      dataIndex: 'servicesDepartment',
//render: name => `${name.first} ${name.last}`,
      key: 'servicesDepartment',
      width:150,
      sorter: (a, b) => { if(a.servicesDepartment) return a.servicesDepartment.localeCompare(b.servicesDepartment)},
    },
    {
      title: 'Total Services Cost',
      width:150,
      dataIndex: 'totalServicesCost',
//render: name => `${name.first} ${name.last}`,
      key: 'totalServicesCost',
      sorter: (a, b) => { if(a.totalServicesCost) return a.totalServicesCost.localeCompare(b.totalServicesCost)},
    },

    {
      title: 'Total Cost',
      dataIndex: 'totalCost',
//render: name => `${name.first} ${name.last}`,
      width:180,
      key: 'totalCost',
      sorter: (a, b) => { if(a.totalCost) return a.totalCost.localeCompare(b.totalCost)},
    },
    {
      title: 'Total GST',
      dataIndex: 'totalGst',
      width:150,
      sorter: (a, b) => { if(a.totalGst) return a.totalGst.localeCompare(b.totalGst)},
//render: name => `${name.first} ${name.last}`,
      key: 'totalGst',
    },
    {
      title: 'Total IGST',
      dataIndex: 'totalIgst',
      width:150,
      key: 'totalIgst',
      sorter: (a, b) => { return new Date(a.totalIgst) > new Date(b.totalIgst) }
    },

    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      width:339,
      key: 'totalAmount',
      sorter: (a, b) => { return new Date(a.totalAmount) > new Date(b.totalAmount) }
    },
    {
      title: 'TDS Applicable',
      dataIndex: 'tdsApplicable',
      width:120,
      key: 'tdsApplicable',
      sorter: (a, b) => { return new Date(a.tdsApplicable) > new Date(b.tdsApplicable) }

    },

    {
      title: 'TDS Percentage Input',
      dataIndex: 'tdsPercentageInput',
      width:180,
      key: 'tdsPercentageInput',
      sorter: (a, b) => { return new Date(a.tdsPercentageInput) > new Date(b.tdsPercentageInput) }
    },
    {
      title: 'Description',
      dataIndex: 'description',
      width:180,
      key: 'description',
      sorter: (a, b) => { return new Date(a.description) > new Date(b.description) }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width:180,
      key: 'statusInvoice',
      sorter: (a, b) => { return new Date(a.statusInvoice) > new Date(b.statusInvoice) }
    },
    {
      title: 'Invoice Copy',
      key: 'uploadInvoiceCopy',
      width:120,

      dataIndex: 'uploadInvoiceCopy',
      render: (uploadInvoiceCopy,record) =>{
        const url = `//${APPCONFIG.baseAPIUrl}/invoices/${record.uploadInvoiceCopy}`;
        return(
            <div>
              <a
                  href={url}
                  target="_blank"
              >
                <Button type={'primary'}>
                  View Invoice
                </Button>
              </a> </div>
        )
      }



    },
    {
      title: 'Assigned To L1',
      dataIndex: 'assignedTo',
      width:150,
      render: assignedTo => assignedTo && assignedTo.name,
      key: 'assignedTo',
      sorter: (a, b) => {
        const aName = a.assignedTo && a.assignedTo.name ? a.assignedTo.name : '';
        const bName = b.assignedTo && b.assignedTo.name ? b.assignedTo.name : '';
        return aName.localeCompare(bName);
      },
    },
    {
      title: 'Assigned To L2',
      dataIndex: 'assignedToL2',
      width:150,
      key: 'assignedToL2',
      render: assignedToL2 => assignedToL2 && assignedToL2.name,
      sorter: (a, b) => {
        const aName = a.assignedToL2 && a.assignedToL2.name ? a.assignedToL2.name : '';
        const bName = b.assignedToL2 && b.assignedToL2.name ? b.assignedToL2.name : '';
        return aName.localeCompare(bName);
      },
    },
    {
      title: 'Switch Status',
      dataIndex: 'status',
      render: (status, record) => {
        let type = 'default';
        let type2 = 'default';
        let type3 = 'default';
        let type4 = 'default';
        let type5 = 'default';
        let type6 = 'default';

        if(status==='ONHOLD' ){
          type="primary"
        }
        if(status==='PARKED'|| status==='PROCESSED' ){
          type2="primary"
        }
        if(status==='REJECTED' ){
          type3="primary"
        }
        if(status==='PARKED' || status==='PROCESSED' ){
          type4="primary"
        }
        if(status==='POSTED' ){
          type5="primary"
        }
        if(status==='L2_REJECTED' ){
          type6="primary"
        }
        let statuses=[];
        let statuseslist2=[];
        // if(this.props.statusChangeLoadingDocs){
        //    statuseslist2 = this.props.statusChangeLoadingDocs.map(x=>{
        //     return x.id
        //   });
        // }


  if(record.assignedTo){
    statuses.push(<Button key={Math.random()} disabled={status === 'PROCESSED'|| status === 'PARKED' || status === 'L2_REJECTED' || status === 'POSTED' || status === 'REJECTED'} id={`${record._id}`} type={type}  loading={statuseslist2.indexOf(record._id)>-1} onClick={(e)=>this.handleStatusChange(record._id, e.target.value, null, record)} value="ONHOLD">L1 On-Hold</Button>);
    statuses.push(<Button key={Math.random()} disabled={status === 'PARKED' || status === 'L2_REJECTED' || status === 'POSTED' || status === 'REJECTED'} id={`${record._id}`} type={type2} loading={statuseslist2.indexOf(record._id)>-1} onClick={(e)=>this.handleStatusChange(record._id, e.target.value, null, record)} value="PROCESSED">L1 Parked</Button>);
    statuses.push(<Button key={Math.random()} disabled={status === 'PROCESSED' || status === 'PARKED' || status === 'L2_REJECTED'|| status === 'POSTED'} id={`${record._id}`} type={type3} loading={statuseslist2.indexOf(record._id)>-1} onClick={(e)=>this.handleStatusChange(record._id, e.target.value, null, record)} value="REJECTED">L1 Rejected</Button>);
  }



        return (
            <div>
              <Tooltip title={record.code}>
                {/* <Button key={Math.random()}>L1 On-Hold</Button>
                <Button key={Math.random()}>L1 Parked</Button>
                <Button key={Math.random()}>L1 Rejected</Button> */}
                  {statuses}
              </Tooltip>
            </div>
        );
      },
      key: 'status',
      width: 339
    }
  ];

  handleStatusChange = (recordId, newStatus, remark, record) => {
    console.log('status changed for ', recordId);
    console.log('this.props: ', this.props);
    console.log("newStatus: ", newStatus, 'referenceNumber:', record.invoiceNo);
    if(newStatus === 'PROCESSED' && !record.invoiceNo){
      console.log("handle status change clicked");

      this.openNotificationWithIcon(
          'error',
          'No reference number',
          `Document ${record.code}: Add reference number before marking it as Parked.`
      );
      return false;
    }
    this.props.handleDocStatusUpdate(recordId, newStatus, remark);
  };

//   handleTableChange = (pagination, filters, sorter,extra) => {

//     demoData.sorter = sorter;
//     const pager = { ...this.state.pagination };
//     pager.current = pagination.current;
//     this.setState({
//       pagination: pager,
//     });
//     if(!demoData.sorter){
//       this.props.fetchDocs(this.props.page, { status: this.props.fetchStatus || 'ASSIGNED' });
//     }
//     else{
//       this.props.fetchDocsWithSorter(this.props.page, { status: this.props.fetchStatus || 'ASSIGNED' },demoData.sorter);
//     }
//   };


//handleDeviceStatusClick;
  componentDidMount() {
//handleDeviceStatusClick = this.props.handleDeviceStatusClick.bind(this);
  }

  onExpand = (expanded, record) => {
    console.log('onExpand', expanded, record);
  };

  onExpandedRowsChange = rows => {
    this.setState({
      expandedRowKeys: rows,
    });
  };






//   handleMetaUpdate = (record, data) => {
//     console.log('received doc update details: ', data);

//     actions
//       .updateDocsMeta(
//         {
//           authToken: this.props.authToken,
//         },
//         record._id,
//         data
//       )
//       .then(result => {
//         if(result.error === true) {
//           return this.openNotificationWithIcon(
//             'error',
//             'Error updating data',
//             `Document ${record.code} could not be updated. Please check all fields.`
//           );
//         }
//         this.props.dispatch(
//           actions.changeDocMetaInStore({
//             recordId: record._id,
//             meta: data,
//             record: result.data
//           })
//         );

//         this.openNotificationWithIcon(
//           'success',
//           'Success updated document',
//           `Document ${record.code} was updated successfully.`
//         );
//       })
//       .catch(ex => {
//         console.log('Exception updating meta:', ex);
//         this.openNotificationWithIcon(
//           'error',
//           'Error updating data',
//           `Document ${record.code} could not be updated. Please check all fields.`
//         );
//       });
//   };


  expandedRowView = (record = null) => {
    console.log('record: ', record);
// const timelineRecords = record.activityLog.reverse().map((log, i) => {
//   let statement = `${moment(log.activityTime).format('DD-MMM-YYYY HH:mm')}: `;
//   let forName = log.activityFor && log.activityFor.name ? log.activityFor.name : 'System';
//   const byName = log.activityBy && log.activityBy.name ? log.activityBy.name : 'System';

//   let status = log.status;
//   if (status === 'ONHOLD') {
//     status = 'L1 On-Hold';
//   }
//   else if (status === 'REJECTED') {
//     status = 'L1 Rejected';
//   }
//   else if (status === 'PROCESSED') {
//     status = 'L1 Parked';
//   }
//   else if (status === 'PARKED') {
//     status = 'L2 On-Hold';
//   }
//   else if (status === 'L2_REJECTED') {
//     status = 'L2 Rejected';
//   }
//   else if (status === 'POSTED') {
//     status = 'L2 Posted';
//   }

//   switch (log.activityType) {
//     case 'CREATE':
//       statement += `Document uploaded to SecureDrive.`;
//       break;

//     case 'ASSIGN':
//       statement += `Assigned to ${forName}.`;
//       break;

//     case 'META_UPDATE':
//       statement += `${byName} updated details of the document.`;
//       break;

//     case 'STATUS_CHANGE':
//       statement += `${byName} changed the status to ${status}.`;
//       break;
//   }

//   if (log.remarks) {
//     statement += ' Remark: ' + log.remarks;
//   }

//   return <Timeline.Item key={i}>{statement}</Timeline.Item>;
// });

    return (
        <Tabs defaultActiveKey="1">
          <TabPane tab="Update Details" key="1">
            <div className="device-details-container">
              <Row>
                <Col span={12}>
                  <InvoicePendingUpdate docDetails={record} handleMetaUpdate={this.handleMetaUpdate} />
                </Col>
              </Row>
            </div>
          </TabPane>
          {/*
<TabPane tab="Document Timeline" key="2">
<div className="device-details-container">
<Row>
<Col span={12}></Col>
</Row>
</div>
</TabPane> */}
        </Tabs>
    );
  };

  updateStatusPopover = (status, record) => {
// (status, record) => {
//   if(status === 'PROCESSED' ) {
//     return (
//       <RadioGroup buttonStyle="solid" size="small"
//                   defaultValue={status}
//                   onChange={(e) => {
//                     if(record.status === 'PROCESSED') {
//                       return false;
//                     }
//                     this.handleStatusChange(record._id, e.target.value, null);
//                   }}
//       >
//         <RadioButton disabled value="ONHOLD">On-Hold</RadioButton>
//         <RadioButton value="PROCESSED">Processed</RadioButton>
//       </RadioGroup>
//     );
//   }
//   else {
//     return(
//       <RadioGroup buttonStyle="solid" size="small"
//                   defaultValue={status}
//                   onChange={(e) => {
//                     if (record.status === 'PROCESSED') {
//                       return false;
//                     }
//                     this.handleStatusChange(record._id, e.target.value, null);
//                   }}
//       >
//         <RadioButton value="ONHOLD">On-Hold</RadioButton>
//         <RadioButton value="PROCESSED">Processed</RadioButton>
//       </RadioGroup>
//     );
//   }
//
// }
    console.log('judging status', status);

    if (status === 'REJECTED') {
      console.log('rejected status');
      return <Tag color="volcano">Rejected</Tag>;
    }

    if (['FRESH', 'ASSIGNED', 'ONHOLD'].indexOf(status) > -1) {
      console.log('in fresh/onhold/assigned status. show processed/onhold');
      return (
          <RadioGroup
              buttonStyle="solid"
              size="small"
              defaultValue={status}
              onChange={e => {
                this.handleStatusChange(record._id, e.target.value, null, record);
              }}
          >
            <RadioButton disabled={status === 'PROCESSED'} value="ONHOLD">
              On-Hold
            </RadioButton>
            <RadioButton value="PROCESSED">L1 Parked</RadioButton>
          </RadioGroup>
      );
    } else {
      console.log('in else status. show parked/posted');
      return (
          <RadioGroup
              buttonStyle="solid"
              size="small"
              defaultValue={status}
              onChange={e => {
                this.handleStatusChange(record._id, e.target.value, null, record);
              }}
          >
            <RadioButton value="PARKED">L2 On-Hold</RadioButton>
            <RadioButton value="POSTED">Posted</RadioButton>
          </RadioGroup>
      );
    }
  };

// initTable(){
//   console.log("Props: ", this.props);
//   // this.props.dispatch((dispatch) => {
//   //   dispatch({type: "REQUEST_USERS_LIST", data: {}});
//   //   this.fetch({})
//   //     .then((result) =>{
//   //       // handle success
//   //       dispatch({ type: "RESPONSE_USERS_LIST", data: {docsList: result}});
//   //     })
//   //     .catch((err) => {
//   //       console.error("RESPONSE_USERS_LIST", "Error:", err);
//   //     })
//   // });
//
//
//
// }

  openNotificationWithIcon = (type, title, desc) => {
    notification[type]({
      message: title,
      description: desc,
    });
  };


  downloadDataHandler = () => {
    this.props.downloadData();
  };


  render() {
    console.log('invoice lists props.....',this.props.invoicesList)
    let pendingInvoices = [];
    // console.log("pending Invoices",this.props.invoicesList)
    console.log("search invoices list",this.props.searchInvoicesList)
//     if(this.props.searchInvoicesList!==null){
//       this.props.searchInvoicesList.map((invoice)=>{
//         console.log("invoice assigned to id",invoice.assignedTo._id)
//         console.log("user ID ......",this.props.user.data._id)
// })
//     }

    if(this.props.searchInvoicesList!==null){
      this.props.searchInvoicesList.map((invoice)=>{
        console.log("invoice",invoice.assignedTo._id)
        console.log("user ID ......",this.props.user.data._id)
// console.log("AssignTo id....",this.props.invoicesList.assignedTo._id)

        if(invoice.assignedTo._id===this.props.user.data._id){
            pendingInvoices.push(invoice);
        }else{
          console.log("User id not matched")
        }
      })
    }
    console.log("Pending invoice in ....",pendingInvoices)
    if(pendingInvoices){
      console.log("pending invoice count is",pendingInvoices.length)
    }
    console.log("pending invoices component Invoices is",this.props.invoicesList);
    console.log("vendors are",this.props.vendor);
    console.log("user meta",this.props.userId);

    const closeAll = () => this.setState({ expandedRowKeys: [] });
    return (
        <div>
          <div >
            {/* <Row >
              <Col xs={14} sm={14} md={14} lg={14} xl={14}>
              <RangePicker defaultValue={[this.props.startDate, this.props.endDate]} onChange={this.props.onChange} />
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
              <Button id={'myBtn3'} type={'primary'} onClick={this.props.onClick}  >Go</Button>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
              <Button type={'primary'} icon="download" size='small' loading={this.props.downloadDataLoading} onClick={this.downloadDataHandler} >
              Download
              </Button>
              </Col>
              </Row> */}
          </div>
          <div >
            <Row>
              <Col span={24}>
                <Table
                    onExpandedRowsChange={this.onExpandedRowsChange}
                    onExpand={this.onExpand}
                    columns={this.columns}
                    rowKey={record => record._id}
                    //   expandedRowKeys={this.state.expandedRowKeys}
                    expandedRowRender={this.expandedRowView}
                    dataSource={this.props.searchInvoicesList}
                    pagination={{pageSize : 100,hideOnSinglePage: true}}
                    //   loading={this.props.docsListLoading}
                    onChange={this.handleTableChange}
                    scroll={{x:2200,y: 600 }}
                    size={'small'}
                />
                <button id={'myBtn2'} onClick={closeAll} style={{visibility: 'hidden'}}>Close All</button>;
              </Col>
            </Row>
          </div>
        </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    register: state.register,
    vendor: state.vendorReducer,
    token: state.auth.token,
    // invoicesList:state.vendorReducer.invoicesList,
    user:state.auth.meta,
    searchInvoicesList:state.vendorReducer.searchInvoicesList,
    statusChangeLoadingDocs:state.vendorReducer.statusChangeLoadingDocs
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({ invoicesList2:invoicesList }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(PendingInvoices)