import React,{Component} from 'react';
import { Table,Button,Row,Col,Icon,Divider } from 'antd';
import {Link, NavLink} from 'react-router-dom';
import {bindActionCreators} from "redux";
import {updateVendor,invoicesList} from "../../../actions/vendorsAction";
import {connect} from "react-redux";
import PendingDashboard from '../dashboard/pendingDashboard'
import ApproveDashboard from '../dashboard/approveDashboard'
import EProgress from "../../../elements/EProgress";
import EDynamicTable from "../../../elements/EDynamicTable";

class Dashboard extends Component{
    constructor(props) {
        super(props);
        this.state = {
          showComponent: false,
        };
        this._onButtonClick = this._onButtonClick.bind(this);
      }

      componentDidMount=()=> {
        console.log("Fetching invoices!");
        const {token} = this.props;
        this.props.fetchInvoicesList({token});
      }

    _onButtonClick() {
        this.setState({
            showComponent: true,
          });
      }
    render(){
        // let pendingList = this.props.approvedVendorsList
        console.log('pending vendor list',this.props.pendingVendorsList)
        console.log('Approve vendor list',this.props.approvedVendorsList)
        console.log('Rejected vendor list',this.props.rejectedVendorsList)
        if(this.props.approvedVendorsList){
            console.log("approve vendors count is",this.props.approvedVendorsList.length)
            var approveCount = this.props.approvedVendorsList.length
        }  
        if(this.props.pendingVendorsList){
            console.log("pending vendors count is",this.props.pendingVendorsList.length)
            var pendingCount = this.props.pendingVendorsList.length
        }
        if(this.props.rejectedVendorsList){
            console.log("reject vendors count is",this.props.rejectedVendorsList.length)
            var rejectedCount = this.props.rejectedVendorsList.length
        }
         
        
        return(
            <div>
            <Row>
                {/* Pending Vendor Count */}
                <Col style={{backgroundColor:'#FFF',height:'80px',width:'250px',borderRadius:'10px',boxShadow:'0 4px 10px 0 grey'}} xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
                    <Col style={{backgroundColor:'#4d99d4',float:'left',height:'80px',width:'80px',borderRadius:'10px',paddingLeft:'25px'}} >
                        <div>
                            <Icon style={{fontSize:'30px',paddingRight:'5px',paddingTop:'20px',color:'#FFF'}}  type="clock-circle" />
                        </div>
                    </Col>
                    <div style={{textAlign:'center',paddingTop:'15px',color:'#000'}}>
                        PENDING VENDORS
                     
                    </div>
    
                    <div style={{textAlign:'center',paddingTop:'15px',color:'#000'}}>
                        <strong style={{fontWeight:'bold',fontSize:'20px'}}>
                            {pendingCount}
                    <Col style={{float:'right',textAlign:'center',height:'30px',width:'60px',borderRadius:'10px'}} >
                        <div style={{paddingTop:'5px'}}>
                        <Link to="/admin-dashboard/pending"><Icon onClick={this._onButtonClick} type="eye" /></Link>
                        </div>
                    </Col>
                        </strong>
                    </div>
                </Col>

                {/* Approve Vendor Count */}
                <Col style={{backgroundColor:'#FFF',height:'80px',width:'250px',borderRadius:'10px',boxShadow:'0 4px 10px 0 grey'}} xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
                    <Col style={{backgroundColor:'#4caf50',float:'left',height:'80px',width:'80px',borderRadius:'10px',paddingLeft:'25px'}}>
                        <div>
                        <Icon style={{fontSize:'30px',paddingRight:'5px',paddingTop:'20px',color:'#FFF'}}  type="check-circle" />
                        </div>
                    </Col>   
                        <div style={{textAlign:'center',paddingTop:'15px',color:'#000'}}>
                            APPROVE VENDORS
                        </div>
                    <div style={{textAlign:'center',paddingTop:'15px',color:'#000'}}>
                    <strong style={{fontWeight:'bold',fontSize:'20px'}}>
                        {approveCount}
                        <Col style={{float:'right',textAlign:'center',height:'30px',width:'60px',borderRadius:'10px'}} >
                        <div style={{paddingTop:'5px'}}>
                        <Link to="/admin-dashboard/approved"><Icon onClick={this._onButtonClick} type="eye" /></Link>                        </div>
                    </Col>
                        </strong>
                    </div>

                </Col>


                {/* Rejected vendor count */}
                <Col style={{backgroundColor:'#FFF',height:'80px',width:'250px',borderRadius:'10px',boxShadow:'0 4px 10px 0 grey'}} xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
                <Col style={{backgroundColor:'#E51809',float:'left',height:'80px',width:'80px',borderRadius:'10px',paddingLeft:'25px'}} >
                    <div>
                    <Icon style={{fontSize:'30px',paddingRight:'5px',paddingTop:'20px',color:'#FFF'}}  type="close-circle" />
                    </div>
                </Col>
                <div style={{textAlign:'center',paddingTop:'15px',color:'#000'}}>REJECTED VENDORS</div>
                    <div style={{textAlign:'center',paddingTop:'15px',color:'#000'}}>
                    <strong style={{fontWeight:'bold',fontSize:'20px'}}>
                        {rejectedCount}
                        <Col style={{float:'right',textAlign:'center',height:'30px',width:'60px',borderRadius:'10px'}} >
                        <div style={{paddingTop:'5px'}}>
                        <Link to="/admin-dashboard/rejected"><Icon type="eye" /></Link> 
                        </div>
                    </Col>
                        </strong>    
                    </div>          
                </Col>
         </Row>
            <Divider></Divider>

      {/* <div style={{width:'1000px',height:'50px'}}>
                     {/* /!* Approve Vendor Text *!/*/}
        {/* <div style={{width:'400px',height:'50px',float:'left'}}>
          <h3>Approve Vendors</h3>
         </div> */}
                   {/* Pending Vendor Text  */}
         {/* <div style={{width:'400px',height:'50px',float:'right'}}>*/} 
          {/* <h3>Pending Vendors</h3> */}
         {/* </div>  */}
     {/* </div> */} 

            {/* Approve Vendor Table */}
    {/* <div style={{width:'1000px',height:'400px'}}> */}
         {/* <div style={{borderRadius:'10px',boxShadow:'0 4px 10px 0 #F3F3F1',width:'400px',backgroundColor:'#F3F3F1',height:'250px',float:'left'}}> */}
         {/* <ApproveDashboard/> */}
         {/* {this.state.showComponent ?
           <ApproveDashboard/> :
           null
        // } */}
        {/* </div> */}
       
        {/* <div style={{borderRadius:'10px',boxShadow:'0 4px 10px 0 #F3F3F1',width:'400px',backgroundColor:'#F3F3F1',height:'250px',float:'right'}}>
          <PendingDashboard/>
      </div> */}
    {/* </div> */}
      <Divider></Divider>
      <div>
            <EDynamicTable data={this.props.invoicesList}/>
      </div>
 </div>
            
        )
    }
   
}

function mapStateToProps(state) {
    return {
        register: state.register,
        vendor: state.vendorReducer,
        token: state.auth.token,
        invoicesList:state.vendorReducer.invoicesList,
        pendingVendorsList: state.vendorReducer.pendingVendorsList,
        approvedVendorsList: state.vendorReducer.approvedVendorsList,
        rejectedVendorsList: state.vendorReducer.rejectedVendorsList
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ updateVendor: updateVendor,fetchInvoicesList:invoicesList }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(Dashboard)


