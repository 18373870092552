import React from 'react';
import {
  Form,
  Input,
  Tooltip,
  Icon,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
} from 'antd';
import './invoicependingUpdate.css'
const { Option } = Select;
const AutoCompleteOption = AutoComplete.Option;

const residences = [
  {
    value: 'zhejiang',
    label: 'Zhejiang',
    children: [
      {
        value: 'hangzhou',
        label: 'Hangzhou',
        children: [
          {
            value: 'xihu',
            label: 'West Lake',
          },
        ],
      },
    ],
  },
  {
    value: 'jiangsu',
    label: 'Jiangsu',
    children: [
      {
        value: 'nanjing',
        label: 'Nanjing',
        children: [
          {
            value: 'zhonghuamen',
            label: 'Zhong Hua Men',
          },
        ],
      },
    ],
  },
];

class invoicePendingUpdate extends React.Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
  };


  handleSubmit = (e) => {
    document.getElementById("myBtn2").click();
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        this.props.handleMetaUpdate(this.props.docDetails, values);
      }
    }
    );
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { autoCompleteResult } = this.state;

    const formItemLayout = {
      labelcol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrappercol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrappercol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    console.log('updateDocDetailsForm props: ', this.props.docDetails);
    const meta = this.props.docDetails;
    return (
        <div className="gutter-example">
            <Form onSubmit={this.handleSubmit} >
        <Row gutter={16}>
          <Col className="gutter-row" span={6}>
              <div className="gutter-box">   
              <Form.Item label="Total Cost">
            {getFieldDecorator('totalCost', {
              initialValue: meta.totalCost || null,
              rules: [
                {
                  required: false,
                  message: 'Please enter the total cost!',
                },
              ],
            })(<Input />)}
          </Form.Item>
          </div>
       </Col>

          <Col className="gutter-row" span={6}>
          <div className="gutter-box">
            <Form.Item label="Invoice No">
              {getFieldDecorator('invoiceNo', {
                initialValue: meta.invoiceNo || '',
                rules: [
                  {
                    required: false,
                    message: 'Please enter the invoice number!',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            </div>
          </Col>
          <Col className="gutter-row" span={6}>
          <div className="gutter-box">
            <Form.Item label="Total Amount">
              {getFieldDecorator('totalAmount', {
                initialValue: meta.totalAmount || '',
                rules: [
                  {
                    required: false,
                    message: 'Please enter the total amount!',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            </div>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col className="gutter-row" span={6}>
              <div className="gutter-box">
            <Form.Item label="Vendor Email">
              {getFieldDecorator('vendorEmail', {
                initialValue: meta.createdByVendor.vendorEmail || '',
                rules: [
                  {
                    required: false,
                    message: 'Enter vendor email!',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            </div>
          </Col>

          <Col className="gutter-row" span={6}>
              <div className="gutter-box">
            <Form.Item label="Vendor Code">
              {getFieldDecorator('vendorCode', {
                initialValue: meta.vendorCode || '',
                rules: [
                  {
                    required: false,
                    message: 'Enter vendor code!',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            </div>
          </Col>
          <Col className="gutter-row" span={6}>
              <div className="gutter-box">
            <Form.Item label="Remarks">
              {getFieldDecorator('remarks', {
                initialValue: meta.remarks || '',
                rules: [
                  {
                    required: false,
                    message: 'Enter remarks!',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            </div>
          </Col>
        </Row>
        <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
      </Form>
        </div>
      
    );
  }
}

const WrappedDocDetailsForm = Form.create({ name: 'doc_details_form' })(invoicePendingUpdate);

export default WrappedDocDetailsForm;
