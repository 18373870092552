import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import {Button} from "semantic-ui-react";
const logoImage = require('../../../src/payflow-logo.png');

const mainNavbar = props =>(
<div>
<header className="main-navigation">
          <div className="main-navigation__logo">
           <img height="50" width="50" src={logoImage}/> &nbsp;&nbsp;&nbsp;<strong style={{color:'#000',fontSize:'20px'}}>PayFlow</strong>
          </div>
          {/* <Link to={'/loginVendor'}>
                    <Button id={'goToLogin'} style={{display:'none'}}> Login</Button>
                </Link>
                <Link to={'/vendorDashboard/manage-users'}>
                    <Button id={'goToVendorDashboard'} style={{display:'none'}}> Login</Button>
                </Link> */}
          <nav className="main-navigation__items">
            <ul>
                {/* <button style={{backgroundColor:'blue',borderRadius:'5px'}}>
                <NavLink to="/loginVendor">Login</NavLink>
                </button> */}
              {/* &nbsp;&nbsp;&nbsp;&nbsp;
              <button style={{backgroundColor:'blue',borderRadius:'5px'}}>
              <NavLink to="/registrationVendor">Registration</NavLink>
              </button> */}
              {/* <li>
              <NavLink to="/loginAdmin">Management</NavLink>
              </li> */}
            </ul>
          </nav>
      </header>
      {/* <div style={{textAlign:'center', width:'300px',height:'300px',position:'absolute',left:'30%',top:'30%'}}>
          <img height="250" width="250" src={logoImage}/>
       </div> */}
</div>
    
)

export default mainNavbar;