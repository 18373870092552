import React,{Component} from 'react';
import { Table,Button } from 'antd';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {fetchUsersList} from '../../../actions/userAction'

class invoicesDashboard extends Component{
  fetchUsersList = ()=>{
    this.props.usersList2({
        token: this.props.token,
        
    })
};

    componentDidMount=()=> {
        this.fetchUsersList();
    }
    render(){
        console.log("User List in userDashboard",this.props.usersList);
        // const dataSource = [
        //     {
        //       key: '1',
        //       companyName: 'ABC',
        //       age: 32,
        //       address: '10 Downing Street',
        //     },
        //     {
        //       key: '2',
        //       companyName: 'PQR',
        //       age: 42,
        //       address: '10 Downing Street',
        //     },
        //     {
        //         key: '3',
        //         companyName: 'DFS',
        //         age: 42,
        //         address: '10 Downing Street',
        //       },
        //       {
        //         key: '4',
        //         companyName: 'ASC',
        //         age: 42,
        //         address: '10 Downing Street',
        //       },
        //       {
        //         key: '5',
        //         companyName: 'AWE',
        //         age: 42,
        //         address: '10 Downing Street',
        //       },
        //       {
        //         key: '6',
        //         companyName: 'AQQ',
        //         age: 42,
        //         address: '10 Downing Street',
        //       },
        //   ];
        const columns = [
          {
              title: 'Name',
              dataIndex: 'name',
              sorter: true,
              width: '15%',
          },
          {
              title: 'Email',
              dataIndex: 'email',
          },
 
          {
              title: 'Permissions',
              dataIndex: 'permissions',
              render: permissions => permissions.join(', ')
          }
        ];
        return(
            <div>
                <Table
                    scroll={{ x: 900,y:250}}
                    columns={columns}
                    expandedRowRender={record => <p style={{ margin: 0 }}>{record.bank}</p>}
                    dataSource={this.props.usersList}
                />,
            </div>
        )
    }
}

function mapStateToProps(state) {
  return {
      register: state.register,
      vendor: state.vendorReducer,
      token: state.auth.token,
      usersList: state.users.usersList,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({usersList2:fetchUsersList}, dispatch)
}

export default connect(mapStateToProps,matchDispatchToProps)(invoicesDashboard)

