
import React, { Component } from 'react';
import 'antd/dist/antd.css';
import {Button, Cascader, Icon, Table} from 'antd';
import {invoicesList,editInvoice} from "../../../actions/vendorsAction";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { fetchUsersList } from '../../../actions/userAction';
import {Link,withRouter} from "react-router-dom";
import InvoicesList from "./invoicesList";





const data = [

];

class ManageInvoices extends Component {
    filter(inputValue, path) {
        return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    }

    onChange(value, selectedOptions) {
        console.log(value, selectedOptions);
        this.props.editInvoice()
    }
    fetchInvoicesList = ()=>{
        this.props.invoicesList({
            token:this.props.token
        })
      };
    fetchUsersList2 = ()=>{
        this.props.fetchUsersList({
            token:this.props.token
        })
    };

    viewInvoice = (record)=>{
        const url = `http://localhost:1200/invoices/${record.uploadInvoiceCopy}`;
        window.open(url, '_blank');
    };

    componentDidMount() {
           this.fetchInvoicesList();
           this.fetchUsersList2()
       }
    render(){
       return(
           <InvoicesList vendorInvoicesList={this.props.vendorInvoicesList}/>
       )
    }
}

function mapStateToProps(state) {
    return {
        register: state.register,
        vendor: state.vendorReducer,
        token: state.auth.token,
        usersList: state.users.usersList,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ invoicesList: invoicesList ,editInvoice:editInvoice,fetchUsersList}, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(ManageInvoices)

