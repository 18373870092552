import React, { Component } from 'react'
import {Button,Table, List, Icon, Col, Pagination, Row, Cascader} from 'antd';
import {Link} from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {compose} from "recompose";
import moment from "moment";

class viewPurchaseOrder extends Component {
    state = {
        pagination: {},
        userDevices: {},
        page : 1,
    };

    
    render(){
        let options = [];
        console.log("purchase order in view purchase order",this.props.purchaseOrderList)
        if(this.props.purchaseOrderList){
            this.props.purchaseOrderList.map((purchaseOrder=>{
                console.log('.map purchase order status',purchaseOrder.status)
                if(purchaseOrder.status === 'APPROVED' || purchaseOrder.status === 'REJECTED'){
                    options.push(purchaseOrder)
                }
            }))
        }
        // if(this.props.purchaseOrderList){
        //     this.props.purchaseOrderList.map((purchaseOrder=>{
        //         console.log('.map purchase order amount',purchaseOrder.totalAmount)
        //         console.log('status in .map',purchaseOrder.status)
        //         // let createdDate = new Date(purchaseOrder.createdDate.toISOString().split('T')[0])
        //         let opt = {
        //             createdDate:purchaseOrder.createdDate,
        //             purchaseOrderNo:purchaseOrder.purchaseOrderNo,
        //             companyName:purchaseOrder.vendor.companyName,
        //             amount:purchaseOrder.totalAmount,
        //             purchaseOrderId:purchaseOrder._id,
        //             status:purchaseOrder.status
        //         }
        //         if(purchaseOrder.status === 'APPROVED'){
        //             options.push(opt)
        //         }
        //     }))
        //     console.log('Purchase Order in....',options)

        // }
        let columns = [
            {
                title: 'Created Date',
                dataIndex: 'createdDate',
                width: '15%',
                key: 'x',
                render:(record)=>{
                    console.log("created date record",record)
                    let newDate = new Date(record)
                    // console.log("newDate",newDate.toISOString().split('T')[0])
                    let dateFormat = newDate.toLocaleDateString("en-US")
                    console.log("dateFormat",dateFormat)
                    let convertedDate = moment(dateFormat).format('DD-MM-YYYY');
                    return(
                        <div>
                            {convertedDate}
                        </div>
                    )
                }
            },
            { title: 'Purchase Order No.', 
            dataIndex: 'purchaseOrderNo',
            width: '20%'
            },
            {
                title: 'Vendor Name',
                dataIndex: 'vendor.companyName',
                width: '30%',
            },
            {
                title: 'Status',
                dataIndex: 'status',
                render:(record)=>{
                    console.log("status.....",record)
                    return(

                        <div>
                            {
                                record === 'APPROVED' &&
                                <span style={{color:'green'}}>{record}</span>
                            }
                            {
                                record === 'REJECTED' &&
                                <span style={{color:'red'}}>{record}</span>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Reason',
                dataIndex: 'description',
                width: '15%'
            },
            {
                title: 'Amount',
                dataIndex: 'totalAmount',
                width: '15%',
                render:(record)=>{
                    console.log("totalAmount....",record)
                    let totalAmount = record
                    let newTotalAmount = parseFloat(totalAmount).toFixed(2)
                    return(
                        <div>
                            {newTotalAmount}
                        </div>
                    )
                }
            },
            {
                title: 'Action',
                dataIndex: '',
                key: 'x1',
                render: (code,purchaseOrder) =>
                    <div>
                        {console.log("record,code",purchaseOrder,code)}
                        <span>
                              <Link to={{pathname : this.props.path,state:{purchaseOrder:purchaseOrder}}}>
                                    <Button><Icon type="eye" theme="outlined" />View</Button>
                                </Link>
                      </span>
                    </div>
            }
        ];


        return(
            <div>
                <h3>Purchase Orders</h3>
                    <Table
                        scroll={{ x: 'max-content'}}
                        rowKey={record => record._id}
                        columns={columns}
                        dataSource={options}
                        pagination={this.state.pagination}
                    />
            </div>
        )
    }
}






function mapStateToProps(state) {
    let userId = null;
    userId = (state.auth.meta && state.auth.meta.data && state.auth.meta.data._id ) ? state.auth.meta.data._id : null;
  return {
      register: state.register,
      isRegistration: state.register.isRegistration,
      InvoicesList:state.vendorReducer.invoicesList,
      token:state.auth.token,
      user:state.auth.meta,
      vendorId:userId,
      levelsList: state.vendorReducer.levelsList
  };
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({ }, dispatch)
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)(viewPurchaseOrder);