import React, { Component } from 'react'
import {Button, Table, List, Icon, Col, Pagination, Row, Form, Input, Cascader, Modal,notification} from 'antd';
import {Link} from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {compose} from "recompose";
import {updatePurchaseOrder,deleteItem} from "../../../actions/userAction";
import moment from "moment";
class viewItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pagination: {},
            userDevices: {},
            page : 1,
        };

    }


      handleDelete = (itemId) => {
        this.props.deleteItem({
            token:this.props.token,
            itemId:itemId,
        })
        console.log("item List Id in",itemId);
        this.props.updateMe()
        this.props.updateMe()

    };
    componentDidMount(){
        this.fetchItemsList()
    }

    fetchItemsList(){
        this.props.updateMe()
    }
    render(){
        let columns = [
            {
                title: 'Item Name',
                dataIndex: 'itemName',
                key: 'x',
            },
            { 
            title: 'Part Number', 
            dataIndex: 'partNumber',
            
            },
            {
                title: 'Price',
                dataIndex: 'price',                
            },
            {
                title: 'tax Rate',
                dataIndex: 'taxRate',
                
            },

            {
                title: 'Action',
                dataIndex: '',
                key: 'x1',
                render: (code,item) =>
                <div>
                    {console.log("record,code",item,code)}
                    <Link to={{pathname : '/user-dashboard/addItems', item:item}}>
                                    <Icon type="edit" theme="outlined" />
                                </Link> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span>
                          <Icon onClick={()=>this.handleDelete(item._id)} type="delete" theme="outlined" />

                  </span>
                </div>
            }
        ];
        console.log("Items list in view items",this.props.itemsList)
        return(
            <div>
            <h3>Items List</h3>
            <Table
                        scroll={{ x: 'max-content'}}
                        rowKey={record => record._id}
                        columns={columns}
                        dataSource={this.props.itemsList}
                        pagination={this.state.pagination}
                    />
            </div>
        )
          
        
    }
}






function mapStateToProps(state) {
    let userId = null;
    userId = (state.auth.meta && state.auth.meta.data && state.auth.meta.data._id ) ? state.auth.meta.data._id : null;
  return {
      register: state.register,
      isRegistration: state.register.isRegistration,
      InvoicesList:state.vendorReducer.invoicesList,
      token:state.auth.token,
      user:state.auth.meta,
      vendorId:userId,
      levelsList: state.vendorReducer.levelsList
  };
}

const wrappedItems = Form.create()(viewItems)


function mapDispatchToProps(dispatch) {
  return bindActionCreators({deleteItem:deleteItem, updatePurchaseOrder:updatePurchaseOrder }, dispatch)
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)(wrappedItems);