import React, {Component} from "react";
import {Button, Cascader, Icon, Table} from "antd";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import {editInvoice, invoicesList} from "../../../actions/vendorsAction";
import {connect} from "react-redux";
import APPCONFIG from "../../../constants/appConfig";

class InvoicesList extends Component{
    viewInvoice = (record)=>{
        const url = `//${APPCONFIG.baseAPIUrl}/invoices/${record.uploadInvoiceCopy}`;
        window.open(url, '_blank');
    };

    render() {
        let options = [];
        console.log("Add Invoice props",this.props);
        if(this.props.usersList){
            this.props.usersList.map((user=>{
                let opt = {
                    value: user._id,
                    label:user.name
                };
                options.push(opt)
            }))
        }

        const columns = [
            { title: 'Vendor Name', dataIndex: 'createdByVendor.companyName', key: 'createdBy' },
            { title: 'Invoice ID', dataIndex: '_id', key: 'invoiceId' },
            { title: 'Invoice No', dataIndex: 'invoiceNo', key: 'invoiceNo' },
            { title: 'Services Department', dataIndex: 'servicesDepartment', key: 'servicesDepartment' },
            { title: 'Total Services Cost', dataIndex: 'totalServicesCost', key: 'totalServicesCost' },
            { title: 'Total Cost', dataIndex: 'totalCost', key: 'totalCost' },
            { title: 'Total GST', dataIndex: 'totalGst', key: 'totalGst' },
            { title: 'Total IGST', dataIndex: 'totalIgst', key: 'totalIgst' },
            { title: 'Total Amount', dataIndex: 'totalAmount', key: 'totalAmount' },
            { title: 'TDS Applicable', dataIndex: 'tdsApplicable', key: 'tdsApplicable' },
            { title: 'TDS Percentage Input', dataIndex: 'tdsPercentageInput', key: 'tdsPercentageInput' },
            { title: 'Status', dataIndex: 'status', key: 'status' },
            { title: 'Assigned To', dataIndex: 'assignedTo.name', key: 'assignedTo.name'},
            { title: 'Invoice Copy', dataIndex: 'uploadInvoiceCopy', key: 'uploadInvoiceCopy' ,
                render: (code,record) =>
                    <div>
                        <Button type={'primary'} onClick={()=>this.viewInvoice(record)}>View</Button>
                    </div>
            },
            {
                title: 'Action',
                dataIndex: '',
                key: 'x',
                render: (code,invoice) =>
                    <div>
                        {console.log("record,code",invoice,code)}
                        <span>
                              <Link to={{pathname : '/vendorDashboard/add-invoices',invoice:invoice}}>
                                    <Icon type="edit" theme="outlined" />
                                </Link>

                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Link to={{pathname : '/vendorDashboard/add-payment-Advoice',invoice:invoice}}>
                                    <Button type={'primary'}>Upload Advoice</Button>
                                </Link>
                            {/*<Button type={'primary'}>*/}
                            {/*    <Link to={{pathname : '/vendorDashboard/add-invoices',invoice:invoice}}>*/}
                            {/*        <Icon type="edit" theme="outlined" />*/}
                            {/*        Edit*/}
                            {/*    </Link>*/}
                            {/*</Button>&nbsp;&nbsp;&nbsp;*/}
                            {/*<Button type={'danger'}>Delete</Button>*/}
                      </span>
                    </div>
            },
        ];
        return(
            <div>
                <Table
                    rowKey={record => record._id}
                    scroll={{ x: 'max-content' }}
                    columns={columns}
                    dataSource={this.props.vendorInvoicesList}
                />,
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        register: state.register,
        vendor: state.vendorReducer,
        token: state.auth.token,
        usersList: state.users.usersList,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ fetchInvoicesList: invoicesList ,editInvoice:editInvoice}, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(InvoicesList)

