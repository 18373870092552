import React,{Component} from 'react';
import { Table,Button } from 'antd';
import {bindActionCreators} from "redux";
// import {updateVendor} from "../../actions/vendorsAction";
import {connect} from "react-redux";
import {vendorsList} from '../../../actions/vendorsAction'

class pendingDashboard extends Component{
    state = {
        pendingVendorsList:[],

    };

     // Fetch vendors pending lists
     fetchVendorsPendingList = async () => {
      console.log('fetchVendorsPendingList',this.props.token)
      let data = await this.props.vendorsList({
          token: this.props.token,
          status:'pending'
      });
      this.setState({
          pendingVendorsList:data
      })
  };

    componentDidMount=()=> {
        this.fetchVendorsPendingList();
    }
    render(){

        // const dataSource = [
        //     {
        //       key: '1',
        //       companyName: 'ABC',
        //       age: 32,
        //       address: '10 Downing Street',
        //     },
        //     {
        //       key: '2',
        //       companyName: 'PQR',
        //       age: 42,
        //       address: '10 Downing Street',
        //     },
        //     {
        //         key: '3',
        //         companyName: 'DFS',
        //         age: 42,
        //         address: '10 Downing Street',
        //       },
        //       {
        //         key: '4',
        //         companyName: 'ASC',
        //         age: 42,
        //         address: '10 Downing Street',
        //       },
        //       {
        //         key: '5',
        //         companyName: 'AWE',
        //         age: 42,
        //         address: '10 Downing Street',
        //       },
        //       {
        //         key: '6',
        //         companyName: 'AQQ',
        //         age: 42,
        //         address: '10 Downing Street',
        //       },
        //   ];
        const columns = [
            { title: 'Company Name', dataIndex: 'companyName', key: 'companyName' },
            { title: 'Vendor Email', dataIndex: 'vendorEmail', key: 'vendorEmail' },
            { title: 'GST No', dataIndex: 'gstNo', key: 'gstNo' },
            { title: 'Phone No', dataIndex: 'phoneNo', key: 'phoneNo' },
            { title: 'Account Name', dataIndex: 'accountName', key: 'accountName' },
            { title: 'Bank', dataIndex: 'bank', key: 'bank' },
            { title: 'Account No', dataIndex: 'accountNo', key: 'accountNo' },
            { title: 'IFSC Code', dataIndex: 'ifscCode', key: 'ifscCode' },
            { title: 'Branch', dataIndex: 'branch', key: 'branch' },
            { title: 'services', dataIndex: 'services', key: 'services' },
            { title: 'address', dataIndex: 'address', key: 'address' },
        ];


        return(
            <div>
                <Table
                    scroll={{ x: 1400,y:250}}
                    columns={columns}
                    expandedRowRender={record => <p style={{ margin: 0 }}>{record.bank}</p>}
                    dataSource={this.props.pendingVendorsList}
                />,
            </div>
        )
    }
}

// function mapStateToProps(state) {
//     return {
//         register: state.register,
//         vendor: state.vendorReducer,
//         token: state.auth.token,
//         pendingVendorsList: state.vendorReducer.pendingVendorsList,
//         approvedVendorsList: state.vendorReducer.approvedVendorsList,
//         rejectedVendorsList: state.vendorReducer.rejectedVendorsList
//     };
// }

// function matchDispatchToProps(dispatch) {
//     return bindActionCreators({ updateVendor: updateVendor }, dispatch)
// }

function mapStateToProps(state) {
    return {
        register: state.register,
        vendor: state.vendorReducer,
        token: state.auth.token,
        auth: state.auth,
        pendingVendorsList: state.vendorReducer.pendingVendorsList,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ vendorsList:vendorsList}, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(pendingDashboard)

