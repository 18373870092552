import React,{Component} from 'react';
import { Spin } from 'antd';
// import { Loader } from 'semantic-ui-react';
class Loader extends Component{
    render(){
        return(
            <div class="loaderDemo">
                <Spin size="large" />
            </div>
        )
    }
}

export default Loader