import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {Table, List, Icon, Col, Pagination, Row, Cascader} from 'antd';

import { withRouter } from 'react-router-dom'
import demoData from '../../../constants/demoData';
import {bindActionCreators} from "redux";
import {editInvoice, invoicesList} from "../../../actions/vendorsAction";
import {fetchUsersList} from "../../../actions/userAction";

class App extends React.Component {
    state = {
        pagination: {},
        userDevices: {},
        page : 1,
    };



    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
        });

    };
    fetchUsersList=()=>{
        this.props.fetchUsersList({
            token:this.props.token
        })
    }
    componentDidMount=()=>{
       this.fetchUsersList()
    }

    render() {
        console.log("Add Users props",this.props);
        console.log("level list in map",this.props.usersList);

        let options = [];
        // this.props.levelsList.map((level)=>{
        //     let opt = {
        //         value: level._id,
        //         label:level.levelName
        //     };
        //     options.push(opt)
        // });
         this.props.usersList.map((level)=>{
            let opt = {
                value: level._id,
                label:level.levelName
            };
            console.log("user list and opt in map",level,opt);
            options.push(opt)
        });
        console.log("options",options);
        let columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                sorter: true,
                width: '15%',
            },
            {
                title: 'Email',
                dataIndex: 'email',
            },
            {
                title: 'Permissions',
                dataIndex: 'permissions',
                render: permissions => permissions.join(', ')
            },
            { title: 'Departments', dataIndex: 'departments',
                render: (x,record)=> {
                let departmentNames = []
                if(record.departments){
                    record.departments.map((department)=>{
                        departmentNames.push(department.departmentName)
                    })
                }
                return departmentNames.join(', ')
                }
            },
            {
                title: 'Edit',
                dataIndex: '',
                key: 'x',
                render: (user) => {
                    console.log(user._id);
                    return (
                        <Link to={{pathname : '/admin-dashboard/add-users',user:user}}>
                            <Icon type="edit" theme="outlined" />
                        </Link>
                    )
                },
            },
        ];
        console.log("usersList state: ", this.state);
        console.log("usersList props: ", this.props);

        return (
            <div>
                <div>
                    <Table
                        columns={columns}
                        rowKey={record => record._id}
                        dataSource={this.props.usersList}
                        pagination={this.state.pagination}
                        loading={this.props.userListLoading}
                        onChange={this.handleTableChange}
                    />
                </div>
            </div>
        );
    }
}
function onChange(value, selectedOptions) {
    console.log(value, selectedOptions);
}

function filter(inputValue, path) {
    return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
}
function mapStateToProps(state) {
    return {
        register: state.register,
        vendor: state.vendorReducer,
        token: state.auth.token,
        usersList: state.users.usersList,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ fetchUsersList:fetchUsersList}, dispatch)
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(App))


//export default Article;
