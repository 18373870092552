import React from "react";
import {
    Form,
    Input,
    Tooltip,
    Icon,
    Cascader,
    Select,
    Row,
    Col,
    Radio,
    Upload,
    Checkbox,
    Button,
    AutoComplete, notification,
} from 'antd';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { invoiceAdd,invoicesList ,fetchLevelsList} from '../../../actions/vendorsAction';
import { fetchUsersList } from '../../../actions/userAction';
import PropTypes from "prop-types";
import {compose} from "recompose";
import { withRouter, Link } from "react-router-dom";
import ESelect from '../../../elements/ESelect'
import { DatePicker } from 'antd';
import moment from "moment";

const { MonthPicker, RangePicker, WeekPicker } = DatePicker;


const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message,
        description,
    });
};
class addInvoices extends React.Component {

    constructor(props) {
        super(props);
        if(this.props.location.invoice){
            const { _id } = this.props.location.invoice;
            console.log("invoice",_id);
        }
        const { match: { params } } = props;
        this.state = {
            userDetails: this.props.userDetails || {},
            invoiceId: (this.props.location.invoice && this.props.location.invoice._id),
            permissions: params.permissions,
            initialValue: {},
            confirmDirty: false,
            plainOptions:[],
            servicesDepartment:null,
            autoCompleteResult: [],
            file:null
        };
    }
    fetchInvoicesList = ()=>{
        this.props.invoicesList({
            token:this.props.token
        })
    };
    fetchDepartmentsList = ()=>{
        this.props.fetchLevelsList({
            token:this.props.token
        })
    };
    handleChange=(values)=> {
        this.setState({
            servicesDepartment:values
        })
        console.log(`selected ${values}`);
    }


    onChange(date, dateString) {
        console.log(date, dateString);
    }

    fetchUsersList = ()=>{
        fetchUsersList({
            token:this.props.token
        })
    };
    componentDidMount() {
        console.log("ComponentDidMount");
        this.fetchUsersList();
        this.fetchInvoicesList();
        this.fetchDepartmentsList();
        this.setInitialValue();
    }
    setInitialValue() {
        const { InvoicesList } = this.props;
        const { invoiceId } = this.state;
        console.log("invoicesList",InvoicesList,invoiceId);
        if (invoiceId && InvoicesList.length > 0) {
            let invoice = InvoicesList.find(invoice => invoice._id === invoiceId);
            if (invoice) {
                console.log("Initial Value Invoice",invoice);
                this.setState({
                    initialValue: invoice,
                });
                let departmentsIds= [];
                if(invoice.servicesDepartment){
                        departmentsIds.push(invoice.servicesDepartment._id)
                    };
                this.setState(prevState => ({
                    initialValue: {                   // object that we want to update
                        ...prevState.initialValue,    // keep all other key-value pairs
                        selectedOption: departmentsIds       // update the value of specific key
                    }
                }));
                   this.props.form.setFieldsValue({
                       serviceDepartment: departmentsIds,
                       dueDate:moment(invoice.dueDate )
                   })

            }
        }
    }
    openNotificationWithIcon = (type, message, description) => {
        notification[type]({
            message,
            description,
        });
    };

    normFile = e => {
        this.setState({file:e.fileList[0]});
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
        openNotificationWithIcon('success', 'File upload', 'File upload successful!');


    };


    handleSubmit = e => {
        e.preventDefault();
        const { actions } = this.props;
        const { initialValue, userId } = this.state;
        // this.props.form.validateFieldsAndScroll((err, values) => {
        //     let data = {};
        //     console.log("state permissions",this.state.initialValue.permissions);
        //     data["permissions"]=this.state.initialValue.permissions;
        //     Object.keys(values).map(key=>{
        //         if(key === "role"){
        //             if(initialValue.permissions!==values[key]){
        //                 data.permissions=values[key];
        //             }
        //             return null;
        //         }else if(initialValue[key]!==values[key]) {
        //             data[key]=values[key];
        //             return null;
        //         }
        //         return null;
        //     });
        //     data["email"]=values.email;
        //     data["name"]=values.name;
        //     data["userId"]=userId;
        //
        //     if (!err && data && Object.keys(data).length>0) {
        //         actions.updateUser({
        //             authToken: this.props.authToken,
        //             formData: data
        //         },userId).then(({error,data})=>{
        //             if(error){
        //                 data.errorMsg.map((err) => setTimeout(() => openNotificationWithIcon(
        //                     'error', `Error: ${err.param}`, err.msg)
        //                 ), 500);
        //             }
        //             else {
        //                 openNotificationWithIcon('success', 'Saved User!', 'Successfully saved the user details');
        //                 data.password = '';
        //                 this.setState({initialValue:data});
        //             }
        //         });
        //     }
        // });
        // e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {

                console.log('Received values of form: ', values);
                console.log('invoice meta',this.props.invoiceMeta);
                let formData = new FormData();
                let token = this.props.token;
                console.log('Auth Token5',token);
                let invoiceId = initialValue._id || null;
                formData.append('invoiceId',initialValue._id || null);
                formData.append('createdByVendor',this.props.vendorId);
                formData.append('servicesDepartment',values.servicesDepartment);
                formData.append('purchaseOrderNumber',values.purchaseOrderNumber);
                formData.append('totalServicesCost',values.totalServicesCost);
                formData.append('invoiceNo',values.invoiceNo);
                formData.append('totalCost',values.totalCost);
                formData.append('totalGst',values.totalGst);
                formData.append('dueDate',values.dueDate.startOf('day').valueOf());
                formData.append('totalIgst',values.totalIgst);
                formData.append('totalAmount',values.totalAmount);
                console.log("initialValue.assignedTo",initialValue.assignedTo)
                if(initialValue.assignedTo){
                    formData.append('assignedTo',initialValue.assignedTo[0]._id);
                }
                formData.append('tdsApplicable',values.tdsApplicable);
                formData.append('tdsPercentageInput',values.tdsPercentageInput);
                formData.append('uploadInvoiceCopy',values.upload && values.upload[0] ? values.upload[0].originFileObj : null);
                formData.append('description',values.description);
                console.log('form data and auth token',formData,token);
                this.props.invoiceAdd(
                    formData,
                    token,
                    invoiceId
                  );
                openNotificationWithIcon('success', 'Saved Invoice!', 'Successfully saved the invoice!');
                this.props.form.resetFields();
            }
        });
    };


    render() {
        let options = [];
        let optionList = [];
        if(this.props.levelsList){
            this.props.levelsList.map((level=>{
                let opt = <Radio value={level._id}>{level.departmentName}</Radio>;
                optionList.push(opt)
            }))
        }

        console.log("optionList",optionList)
        const { form, saveUserLoading } = this.props;

        console.log("initialValue",this.state.initialValue);
        if(this.props.vendorUsersList){
            console.log("userslist mapping:",this.props.vendorUsersList);
            this.props.vendorUsersList.map((user=>{
                let opt = {
                    value: user._id,
                    label:user.name
                };
                options.push(opt)
            }))
        }
        const { getFieldDecorator } = form;
        const { initialValue, invoiceId,servicesDepartment } = this.state;

        const formItemLayout = {
            labelCol: {
                xs: { span: 5 },
                sm: { span: 5 },
            },
            wrapperCol: {
                xs: { span: 5 },
                sm: { span: 15 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };


        let uploadSuccess = null;
        if(this.state.file){
            uploadSuccess = 'file upload success'
        }


        return (
        <div>
            <div className="box-header">
                <h2>{`${invoiceId ? 'Update' : 'Add'} Invoice`}</h2>
            </div>
            <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                <Form.Item label="Invoice No">
                    {getFieldDecorator('invoiceNo', {
                        rules: [
                            {
                                required: true,
                                message: 'Please input Invoice No!',
                            },
                        ],
                        initialValue: initialValue.invoiceNo,
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="Purchase Order Number">
                    {getFieldDecorator('purchaseOrderNumber', {
                        rules: [
                            {
                                required: true,
                                message: 'Please input Purchase Order Number!',
                            },
                        ],
                        initialValue: initialValue.purchaseOrderNumber,
                    })(<Input />)}
                </Form.Item>

                <Form.Item label="Due Date">
                    {getFieldDecorator('dueDate', {
                        rules: [
                            {
                                required: true,
                                message: 'Please input the Due Date!',
                            },
                        ],
                    })(    <DatePicker onChange={onChange} />
                    )}
                </Form.Item>

                <Form.Item label="Services Department" hasFeedback>
                    {getFieldDecorator('servicesDepartment', {
                        rules: [
                            {
                                required: true,
                                message: 'Please input Services Department!',
                            },
                        ],
                        initialValue: initialValue.servicesDepartment ? initialValue.servicesDepartment._id : null
                    })(
                        // <Checkbox.Group options={optionList} onChange={this.handleChange} />
                        <Radio.Group onChange={this.onChange} value={this.state.value}>
                            {optionList}
                        </Radio.Group>

                    )}
                </Form.Item>
                <Form.Item label="Total Services Cost" hasFeedback>
                    {getFieldDecorator('totalServicesCost', {
                        rules: [
                            {
                                required: true,
                                message: 'Please Enter Total Services Cost !',
                            },
                        ],
                        initialValue: initialValue.totalServicesCost,

                    })(<Input />)}
                </Form.Item>

                <Form.Item label="Total Cost">
                    {getFieldDecorator('totalCost', {
                        rules: [{ required: true, message: 'Please input Total Cost!' }],
                        initialValue: initialValue.totalCost,
                    })(<Input  />)}
                </Form.Item>
                <Form.Item label="Total Gst">
                    {getFieldDecorator('totalGst', {
                        rules: [{ required: true, message: 'Please input Total Gst!' }],
                        initialValue: initialValue.totalGst,

                    })(<Input  />)}
                </Form.Item>
                <Form.Item label="Total IGST">
                    {getFieldDecorator('totalIgst', {
                        rules: [{ required: true, message: 'Please input Total IGST!' }],
                        initialValue: initialValue.totalIgst,

                    })(<Input  />)}
                </Form.Item>
                <Form.Item label="Total Amount">
                    {getFieldDecorator('totalAmount', {
                        rules: [{ required: true, message: 'Please input Total Amount!' }],
                        initialValue: initialValue.totalAmount,

                    })(<Input  />)}
                </Form.Item>
                <Form.Item label="TDS Applicable">
                    {getFieldDecorator('tdsApplicable', {
                        rules: [{ required: true, message: 'Please input TDS Applicable!' }],
                        initialValue: initialValue.tdsApplicable,
                    })(<Input  />)}
                </Form.Item>
                <Form.Item label="TDS Percentage Input">
                    {getFieldDecorator('tdsPercentageInput', {
                        rules: [{ required: true, message: 'Please input TDS Percentage Input!' }],
                        initialValue: initialValue.tdsPercentageInput,

                    })(<Input  />)}
                </Form.Item>
                <Form.Item label="Upload Invoice Copy">
                    {getFieldDecorator('upload', {
                        valuePropName: 'fileList',
                        getValueFromEvent: this.normFile,
                        // rules: [{ required: true, message: 'Please chooose image' }],

                    })(
                        <Upload name="logo"  >
                            <Button>
                                <Icon type="upload" /> Click to upload
                            </Button>
                            <div style={{color:'#388e3c'}}>
                             {uploadSuccess}
                            </div> 

                        </Upload>,
                    )}
                </Form.Item>
                <Form.Item label="Description">
                    {getFieldDecorator('description', {
                        rules: [{ required: true, message: 'Please input Description!' }],
                        initialValue: initialValue.description,

                    })(<Input  />)}
                </Form.Item>
                 <Form.Item label="Assigned To" style={{display:"none"}}
                 >
                    {getFieldDecorator('assignedTo', {
                        rules: [{  message: 'Please input your assign to!' }],
                    })(
                        <Cascader
                            options={options}
                            onChange={onChange}
                            placeholder="Please select User"
                            showSearch={{ filter }}
                        />
                    )}
                </Form.Item>

                <Form.Item {...tailFormItemLayout}>
                    <Button style={{marginLeft:'18%'}} type="primary" htmlType="submit">
                        {`${invoiceId ? 'Update' : 'Add'}`}
                    </Button>
                </Form.Item>
            </Form>
        </div>
        );
    }
}

const WrappedLogin = Form.create()(addInvoices)


function mapStateToProps(state) {
    let userId = null;
    userId = (state.auth.meta && state.auth.meta.data && state.auth.meta.data._id ) ? state.auth.meta.data._id : null;
  return {
      register: state.register,
      isRegistration: state.register.isRegistration,
      InvoicesList:state.vendorReducer.invoicesList,
      token:state.auth.token,
      user:state.auth.meta,
      vendorId:userId,
      levelsList: state.vendorReducer.levelsList
  };
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({ invoiceAdd: invoiceAdd ,invoicesList:invoicesList,fetchLevelsList:fetchLevelsList}, dispatch)
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)(WrappedLogin);

function onChange(value, selectedOptions) {
    console.log(value, selectedOptions);
}

function filter(inputValue, path) {
    return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
}
