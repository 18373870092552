import React, {Component} from 'react';
import {Button, Cascader, Icon, Table} from "antd";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {invoicesList} from "../../../actions/vendorsAction";


class invoiceList extends Component{
    render(){
        const columns = [
            { title: 'Invoice ID', dataIndex: '_id', key: 'invoiceId' },
            { title: 'Invoice No', dataIndex: 'invoiceNo', key: 'invoiceNo' },
            { title: 'Status', dataIndex: 'status', key: 'status' },
            {
                title: 'Edit',
                dataIndex: '',
                key: 'x',
                render: (invoice) => {
                    console.log('invoice list render....',invoice._id);
                    return (
                        <Link to={{pathname : '/vendorDashboard/add-payment-Advoice',invoice:invoice}}>
                            <Icon type="edit" theme="outlined" />
                        </Link>
                    )
                },
            },
        ]
        console.log("invoices list in manage payment",this.props.vendorInvoicesList)
        return(
            <div>
                <div className="box-header">
                    <h2>Invoices Payment Status</h2>
                </div>
                <div>
                <Table
                    rowKey={record => record._id}
                    scroll={{ x: 'max-content' }}
                    columns={columns}
                    dataSource={this.props.vendorInvoicesList}
                />,
                </div>

            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        register: state.register,
        vendor: state.vendorReducer,
        token: state.auth.token,
        usersList: state.users.usersList,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ fetchInvoicesList: invoicesList}, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(invoiceList)
