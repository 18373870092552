import React, { Component } from 'react'
import { Table, Form } from 'antd';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose } from "recompose";
import { updateRFQQuotation } from "../../actions/userAction";
import moment from "moment";

class vendorViewRFQ extends Component {

    state = {
        loading: false,
    };

    generateComparative = () => {
        this.setState({ loading: true });
        // ajax request after empty completing
        setTimeout(() => {
            this.setState({
                loading: false,
            });
        }, 1000);
    };

    click = (e) => {
        // const history = useHistory();
        console.log("data is", e);
        // this.context.router.push(`/user-dashboard/rfqQuotationList/${e.rfqId}`)

        // return <Redirect to={{ pathname: `/user-dashboard/rfqQuotationList/${e.rfqId}` }} />
        // window.location.href = `/user-dashboard/rfqQuotationList/${e.rfqId}`
    }

    randomDate(start, end, startHour, endHour) {
        var date = new Date(+start + Math.random() * (end - start));
        var hour = startHour + Math.random() * (endHour - startHour) | 0;
        date.setHours(hour);
        return date;
    }


    render() {
        // console.log(permissions);
        const columns = [
            {
                title: 'Date',
                dataIndex: 'date',
                sorter: (a, b) => b.date - a.date,
                key: 'x',
                // with: '30%',
                render: (record) => {
                    console.log("created date record", record)
                    let newDate = new Date(record)
                    // console.log("newDate",newDate.toISOString().split('T')[0])
                    let dateFormat = newDate.toLocaleDateString("en-US")
                    console.log("dateFormat", dateFormat)
                    let convertedDate = moment(dateFormat).format('DD-MM-YYYY');
                    return (
                        <div>
                            {convertedDate}
                        </div>
                    )
                }
            },
            {
                title: 'Title',
                dataIndex: 'title',
                // with: '20%',
            },
            {
                title: 'Last Date',
                dataIndex: 'lastDate',
                sorter: (a, b) => b.date - a.date,
                key: 'x',
                // with: '30%',
                render: (record) => {
                    console.log("created date record", record)
                    let newDate = new Date(record)
                    // console.log("newDate",newDate.toISOString().split('T')[0])
                    let dateFormat = newDate.toLocaleDateString("en-US")
                    console.log("dateFormat", dateFormat)
                    let convertedDate = moment(dateFormat).format('DD-MM-YYYY');
                    return (
                        <div>
                            {convertedDate}
                        </div>
                    )
                }
                // with: '30%',
            },
            {
                title: 'Action',
                dataIndex: 'action',
                // with: '20%',
            },
        ];

        const data = [];
        for (let i = 0; i < 10; i++) {
            // const actionState = Math.random()
            data.push({
                key: i,
                rfqId: Math.round(Math.random() * 1000),
                date: this.randomDate(new Date(2020, 0, 1), new Date(), 0, 24),
                // vendorId: (i * 100) + (i * 3),
                // vendorName: `Edward King ${i}`,
                // amount: Math.random() * 10000,
                title: "For Computer Acquisition",
                responses: [
                    {}, {}, {}, {}, {}
                ],
                lastDate: this.randomDate(new Date(2020, 0, 1), new Date(), 0, 24),
                action: 'Quote',
            });
        }

        return (
            <div>
                <h1>View RFQs</h1>

                <Table onRowClick={this.click} columns={columns} dataSource={data} />
            </div>
        );
    }
}

function mapStateToProps(state) {
    let userId = null;
    userId = (state.auth.meta && state.auth.meta.data && state.auth.meta.data._id) ? state.auth.meta.data._id : null;
    return {
        register: state.register,
        isRegistration: state.register.isRegistration,
        InvoicesList: state.vendorReducer.invoicesList,
        token: state.auth.token,
        user: state.auth.meta,
        vendorId: userId,
        levelsList: state.vendorReducer.levelsList
    };
}

const wrappedPurchaseOrder = Form.create()(vendorViewRFQ)


function mapDispatchToProps(dispatch) {
    return bindActionCreators({ updateRFQQuotation: updateRFQQuotation }, dispatch)
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)(wrappedPurchaseOrder);
