import React, { Component } from 'react'
import { Button, Table, Form, Row, Col, Icon } from 'antd';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose } from "recompose";
import { updateRFQQuotation } from "../../actions/userAction";
import moment from "moment";

class RFQQuotationList extends Component {

    state = {
        selectedRowKeys: [], // Check here to configure the default column
        loading: false,
        data: [],
    };

    constructor(props) {
        super(props);
        for (let i = 0; i < 46; i++) {
            const actionState = Math.random()
            this.state.data.push({
                key: i,
                id: i,
                date: this.randomDate(new Date(2020, 0, 1), new Date(), 0, 24),
                vendorId: (i * 100) + (i * 3),
                vendorName: `Edward King ${i}`,
                amount: (Math.random() * 10000).toFixed(2),
                action: actionState < 0.33 ? `FRESH` : actionState < 0.66 ? `APROVED` : `REJECTED`,
            });
        }
    }

    generateComparative = () => {
        this.setState({ loading: true });
        // ajax request after empty completing
        setTimeout(() => {
            this.setState({
                selectedRowKeys: [],
                loading: false,
            });
        }, 1000);
    };

    click = (e) => {
        console.log("data is", e);
    }

    randomDate(start, end, startHour, endHour) {
        var date = new Date(+start + Math.random() * (end - start));
        var hour = startHour + Math.random() * (endHour - startHour) | 0;
        date.setHours(hour);
        return date;
    }

    onSelectChange = selectedRowKeys => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };

    render() {
        const permissions = this.props.user.data.permissions;
        const columns = [
            {
                title: 'Date',
                dataIndex: 'date',
                sorter: (a, b) => b.date - a.date,
                key: 'x',
                // with: '30%',
                render: (record) => {
                    console.log("created date record", record)
                    let newDate = new Date(record)
                    // console.log("newDate",newDate.toISOString().split('T')[0])
                    let dateFormat = newDate.toLocaleDateString("en-US")
                    console.log("dateFormat", dateFormat)
                    let convertedDate = moment(dateFormat).format('DD-MM-YYYY');
                    return (
                        <div>
                            {convertedDate}
                        </div>
                    )
                }
            },
            {
                title: 'Vendor Id',
                dataIndex: '',
                sorter: (a, b) => b.vendorId - a.vendorId,
                render: data => {
                    if (permissions.indexOf("admin") > -1) {
                        return (<Link to={{ pathname: `/admin-dashboard/rfqQuotation/${data.id}` }}>{data.vendorId}</Link>);
                    } else {
                        return (<Link to={{ pathname: `/user-dashboard/rfqQuotation/${data.id}` }}>{data.vendorId}</Link>);
                    }
                }

                // with: '20%',
            },
            {
                title: 'Vendor Name',
                dataIndex: 'vendorName',
                sorter: (a, b) => b.vendorName - a.vendorName,
                // with: '30%',
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                defaultSortOrder: 'descend',
                sorter: (a, b) => b.amount - a.amount,
                // with: '20%',
            },
            {
                title: 'Action',
                dataIndex: '',
                key: 'x1',
                // with: '10%',
                render: (code, rfqData) =>
                    <div>
                        {console.log("record,code....", rfqData, code)}
                        {/* { purchaseOrderId = purchaseOrder._id} */}
                        <span>
                            <Row style={{ left: `${this.props.permission === 'admin' ? '28%' : '5%'}` }} gutter={12}>

                                {rfqData.action === 'FRESH'
                                    ? <div>
                                        <Col className="gutter-row" span={4}>
                                            <div className="gutter-box">
                                                <Button key={rfqData._id} shape="circle" onClick={() => this.handleApprove(rfqData._id)} style={{ backgroundColor: '#549914', color: '#FFF' }}><Icon type="check" /></Button>
                                            </div>
                                        </Col>
                                        <Col className="gutter-row" span={4}>
                                            <div className="gutter-box">
                                                <Button key={rfqData._id} shape="circle" onClick={() => this.showModal(rfqData._id)} type={'danger'} style={{ color: '#FFF' }}><Icon type="close" /></Button>
                                            </div>
                                        </Col>
                                    </div>
                                    :
                                    rfqData.action === 'APROVED'
                                        ? <div>
                                            <Col className="gutter-row" >
                                                <div className="gutter-box">
                                                    APPROVED
                                       </div>
                                            </Col>
                                        </div>
                                        : <div>
                                            <Col className="gutter-row" >
                                                <div className="gutter-box">
                                                    REJECTED
                                       </div>
                                            </Col>
                                        </div>
                                }

                            </Row>

                        </span>
                        {/* <div>
                            <Modal
                                title="Are you sure you want to reject the advoice?"
                                visible={this.state.visible}
                                onOk={this.handleOk}
                                onCancel={this.handleCancel}
                            >
                                <Form layout={formLayout}>
                                    <Form.Item label="Vendor Name" {...formItemLayout}>
                                        <Input placeholder="Enter Vendor Name" value={this.state.vendorName} />
                                    </Form.Item>
                                    <Form.Item label="Description" {...formItemLayout}>
                                        {/* <Input placeholder="Enter your Reason." onChange={(x) => this.handleChange(x)} /> 
                                    </Form.Item>
                                </Form>
                            </Modal>
                        </div> */}
                    </div>
            }
        ];


        const { loading, selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <div>
                <h1>Quotations List</h1>
                <div style={{ marginBottom: 16 }}>
                    <Button type="primary" onClick={this.generateComparative} disabled={!hasSelected} loading={loading}>
                        Generate Comparative
                    </Button>
                    <span style={{ marginLeft: 8 }}>
                        {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
                    </span>
                </div>
                <Table rowSelection={rowSelection} columns={columns} dataSource={this.state.data} />
            </div>
        );
    }
}

function mapStateToProps(state) {
    let userId = null;
    userId = (state.auth.meta && state.auth.meta.data && state.auth.meta.data._id) ? state.auth.meta.data._id : null;
    return {
        register: state.register,
        isRegistration: state.register.isRegistration,
        InvoicesList: state.vendorReducer.invoicesList,
        token: state.auth.token,
        user: state.auth.meta,
        vendorId: userId,
        levelsList: state.vendorReducer.levelsList
    };
}

const wrappedPurchaseOrder = Form.create()(RFQQuotationList)


function mapDispatchToProps(dispatch) {
    return bindActionCreators({ updateRFQQuotation: updateRFQQuotation }, dispatch)
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)(wrappedPurchaseOrder);
