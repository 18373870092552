import React,{Component} from 'react';
import { Table } from 'antd';

class RejectedVendors extends Component{
    render(){
        const columns = [
            { title: 'Company Name', dataIndex: 'companyName', key: 'companyName' },
            { title: 'Vendor Email', dataIndex: 'vendorEmail', key: 'vendorEmail' },
            { title: 'GST No', dataIndex: 'gstNo', key: 'gstNo' },
            { title: 'Phone No', dataIndex: 'phoneNo', key: 'phoneNo' },
            { title: 'Account Name', dataIndex: 'accountName', key: 'accountName' },
            { title: 'Bank', dataIndex: 'bank', key: 'bank' },
            { title: 'Account No', dataIndex: 'accountNo', key: 'accountNo' },
            { title: 'IFSC Code', dataIndex: 'ifscCode', key: 'ifscCode' },
            { title: 'Branch', dataIndex: 'branch', key: 'branch' },
            { title: 'services', dataIndex: 'services', key: 'services' },
            { title: 'address', dataIndex: 'address', key: 'address' },

        ];




        return(
            <div>
                <Table
                    scroll={{ x: 'max-content' }}
                    columns={columns}
                    expandedRowRender={record => <p style={{ margin: 0 }}>{record.bank}</p>}
                    dataSource={this.props.tableData}
                />,
            </div>
        )
    }
}

export default RejectedVendors