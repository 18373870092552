import React, { Component } from 'react';
import { Layout, Menu, Breadcrumb, Icon, Button, Row, Col, Dropdown } from 'antd';
import { Input } from 'antd';
import '../../components/vendorDashboard/vendorDashboard.modules.css';
import { HashRouter } from 'react-router-dom';

import './adminDashboard.module.css'

import { BrowserRouter as Router, Link, Route } from "react-router-dom";
import PendingVendors from "./PendingVendors";
import ApprovedVendors from "./ApprovedVendors";
import RejectedVendors from "./RejectedVendors";
import AddInvoices from "./invoices/addInvoices";
import ManageInvoices from "./invoices/manageInvoices";
import SearchInvoices from "./search/manageInvoices";

import AddItems from '../userDashboard/purchaseOrder/addItems'
import AddRFQ from '../rfqResponse/addRFQ'
import ViewRFQ from '../rfqResponse/viewRFQ'
import RFQQuotationList from '../rfqResponse/viewRFQQuotationList';
import RFQQuotation from '../rfqResponse/viewRFQQuotation';
import ViewItems from '../userDashboard/purchaseOrder/viewItems'

import AddUsers from './users/addUser';
import ManageUsers from './users/manageUser';
import AddLevels from './levels/addLevels';
import ManageLevels from './levels/manageLevels'

import Dashboard from './dashboard/dashboard'
import { vendorsList, logout, tryAlreadyLoggedInProfile, invoicesList, invoiceSearch } from "../../actions/vendorsAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// import demoData for invoice search
import demoData from '../../constants/demoData.js'

// import ScrollToTop
import ScrollToTop from '../scrollToTop/index'

// import paymentAdvoice
import PaymentAdvoicesList from '../../components/adminDashboard/managePayments/paymentAdvoicesList'

// import Purchase order
import ViewPurchaseOrder from '../../components/userDashboard/purchaseOrder/viewPurchaseOrder'
import { getPurchaseOrder } from '../../actions/adminAction'
import PrintPurchaseOrder from '../../components/userDashboard/purchaseOrder/printPurchaseOrder'

// import Payflow Logo
const logoImage = require('../../../src/payflow-logo.png');

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const Search = Input.Search;

class AdminDashboard extends Component {

    state = {
        pendingVendorsList: [],
        approvedVendorsList: [],
        rejectedVendorsList: [],
        collapsed: false,
    };
    onCollapse = collapsed => {
        console.log(collapsed);
        this.setState({ collapsed });
    };

    // Fetch vendors pending lists
    fetchVendorsPendingList = async () => {
        console.log('fetchVendorsPendingList', this.props.token)
        let data = await this.props.vendorsList({
            token: this.props.token,
            status: 'pending'
        });
        this.setState({
            pendingVendorsList: data
        })
    };
    // fetch vendors approved lists
    fetchVendorsApprovedList = async () => {
        let data = await this.props.vendorsList({
            token: this.props.token,
            status: 'approved'
        });
        this.setState({
            approvedVendorsList: data
        })
    };
    // fetch vendors rejected lists
    fetchVendorsRejectedList = async () => {
        let data = await this.props.vendorsList({
            token: this.props.token,
            status: 'rejected'
        });
        this.setState({
            rejectedVendorsList: data
        })
    };

    // fetch all Invoices
    fetchDocs = () => {
        console.log("calling fetch docs")
        this.props.FetchInvoicesList({
            token: this.props.token,
        })
    }

    onSearchHandler = (value) => {
        console.log("Searched Value", value)
        demoData.q = value.trim();
        console.log("location props pathName is", this.props.location.pathname);
        document.getElementById("myBtn").click();
        console.log("path name after onclick", this.props.location.pathname);
        // let invoiceNo = [];
        // if(this.props.invoicesList!==null){
        //     this.props.invoicesList.map((invoice)=>{
        //         console.log("invoice No in map is",invoice.invoiceNo)
        //         invoiceNo.push(invoice.invoiceNo);
        //         console.log("invoice NO after map",invoiceNo)
        //     })
        // }
        console.log("demo data after props click", demoData.q)

        this.props.invoiceSearch({
            searchTerm: demoData.q,
            token: this.props.token
        })
    };

    fetchPurchaseOrder = () => {
        console.log("calling fetch Purchase Order")
        console.log("Token in user Dashboard for all fetch vendors", this.props.token)
        let data = this.props.getPurchaseOrder({
            token: this.props.token
        })
        this.setState({
            purchaseOrderList: data
        })
        console.log('Purchase Order in function', this.state.purchaseOrderList)
    }
    componentDidMount = () => {
        this.props.tryAlreadyLoggedInProfile()
        this.fetchVendorsPendingList();
        this.fetchVendorsApprovedList();
        this.fetchVendorsRejectedList();
        this.fetchDocs();
        this.fetchPurchaseOrder()
        // this.onSearchHandler()
    }

    render() {
        const menu = (
            <Menu style={{ padding: '10px', margin: '15px' }}>
                <Menu.Item key="0">
                    <h5 onClick={this.props.logout}>LOGOUT</h5>
                </Menu.Item>
            </Menu>
        );

        const meta = this.props.meta;
        const username = meta && meta.data && meta.data.name ? meta.data.name : 'User';
        // const userpermission = meta && meta.data && meta.data.permissions === ["admin", "supervisor"] ? meta.data.permissions : 'Permissions';
        // console.log('userPermissions...',userpermission)
        console.log("user Name is", username)
        console.log('Table Data', this.state.tableData);
        console.log("invoices list fetchdocs", this.props)
        console.log('Auth  Reducer', this.props.auth.meta.data);
        console.log('user permission', this.props.auth.meta.data);
        console.log('Invoice list', this.props.invoicesList)
        const userPermission = 'admin';
        const poStatusApproved = 'APPROVED';
        const poStatusRejected = 'REJECTED';
        const poStatusPending = 'FRESH';
        // let invoiceId = [];
        // if(this.props.invoicesList!==null){
        //     this.props.invoicesList.map((invoice)=>{
        //         console.log("invoice id in map is",invoice._id)
        //         invoiceId.push(invoice._id);
        //         console.log("invoice Id after map",invoiceId)
        //     })
        // }

        let link = null;
        if (this.props.location.pathname !== "/admin-dashboard/searched") {
            link = <Link to="/admin-dashboard/searched" >
                <Button id={'myBtn'} type={'primary'} style={{ visibility: 'hidden' }} >Search</Button>
            </Link>
        } else {
            link = <Link to="/admin-dashboard/searched2" >
                <Button id={'myBtn'} type={'primary'} style={{ visibility: 'hidden' }} >Search</Button>
            </Link>
        }


        return (
            <HashRouter>
                <div>
                    <Layout style={{ height: '120vh', zIndex: 2 }}>
                        <Sider
                            width={250}
                            trigger={null}
                            breakpoint="lg"
                            collapsedWidth="0"
                            onBreakpoint={broken => {
                                console.log(broken);
                            }}
                            onCollapse={(collapsed, type) => {
                                console.log(collapsed, type);
                            }}
                        >
                            <div className="logo">
                                <img style={{ marginLeft: '10%', marginTop: '8%' }} src={logoImage} height="75" width="75" />
                                <span style={{ color: '#FFF', marginLeft: '5%', fontSize: '22px', marginTop: '5%' }}>PayFlow</span>
                            </div>
                            <Menu style={{ padding: '15px' }} theme="dark" mode="inline" defaultSelectedKeys={['Users']}>
                                <Menu.Item key="1">
                                    <Link to={'/admin-dashboard/dashboard'}>
                                        <span>
                                            <Icon type="dashboard" theme="filled" />
                                            <span >Dashboard</span>
                                        </span>
                                    </Link>
                                </Menu.Item>
                                <SubMenu
                                    key="vendors"
                                    title={
                                        <span>
                                            <Icon type="video-camera" />
                                            <span >Vendors</span>
                                        </span>
                                    }
                                >

                                    <Menu.Item key="2">
                                        <Link to={'/admin-dashboard/pending'}>
                                            <span>Pending Vendors</span>
                                        </Link>
                                    </Menu.Item>
                                    {/* Approved Vendors side-nav */}
                                    <Menu.Item key="3">
                                        <Link to={'/admin-dashboard/approved'}>
                                            <span>Approved Vendors</span>
                                        </Link>
                                    </Menu.Item>
                                    {/* Rejected Vendors side-nav */}
                                    <Menu.Item key="4">
                                        <Link to={'/admin-dashboard/rejected'}>
                                            <span>Rejected Vendors</span>
                                        </Link>
                                    </Menu.Item>
                                </SubMenu>
                                {/* Pending Vendor side-nav */}


                                {/* Vendor invoices side-nav */}
                                <SubMenu
                                    key="invoices"
                                    title={
                                        <span>
                                            <Icon type="copy" theme="filled" />

                                            <span>Invoices</span>
                                        </span>
                                    }
                                >
                                    {/*<Menu.Item key="5">*/}
                                    {/*    <Link to={'/admin-dashboard/add-invoices'}  style={{color:'white'}}>*/}
                                    {/*        Add Invoice*/}
                                    {/*    </Link>*/}
                                    {/*</Menu.Item>*/}
                                    <Menu.Item key="6">
                                        <Link to={'/admin-dashboard/manage-invoices'} style={{ color: 'white' }}>
                                            <span>Manage Invoices</span>
                                        </Link>
                                    </Menu.Item>
                                </SubMenu>

                                {/* Vendor levels side-nav */}
                                <SubMenu
                                    key="settings"
                                    title={
                                        <span>
                                            <Icon type="setting" />
                                            <span>Settings</span>
                                        </span>
                                    }
                                >

                                    <Menu.Item key="9">
                                        <Link to={'/admin-dashboard/add-levels'} style={{ color: 'white' }}>
                                            <span>Add Departments</span>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="10">
                                        <Link to={'/admin-dashboard/manage-levels'} style={{ color: 'white' }}>
                                            <span>Manage Departments</span>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="11">
                                        <Link to={'/admin-dashboard/add-users'} style={{ color: 'white' }}>
                                            <span>Add Department User</span>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="12">
                                        <Link to={'/admin-dashboard/manage-users'} style={{ color: 'white' }}>
                                            <span>Manage Department User</span>
                                        </Link>
                                    </Menu.Item>
                                </SubMenu>

                                <Menu.Item key="13">
                                    <Link to={'/admin-dashboard/paymentAdvoicesList'}>
                                        <span>
                                            <Icon type="dashboard" theme="filled" />
                                            <span >View Payments</span>
                                        </span>
                                    </Link>
                                </Menu.Item>
                                {/* 
                                <Menu.Item key="14">
                                    <Link to={'/admin-dashboard/viewPurchaseOrder'}>
                                        <span>
                                        <Icon  type="dashboard" theme="filled" />
                                          <span >View Purchase Order</span>
                                        </span>
                                    </Link>
                                </Menu.Item> */}



                                {/* Vendor levels side-nav */}
                                <SubMenu
                                    key="purchaseOrder"
                                    title={
                                        <span>
                                            <Icon type="transaction" />
                                            <span>Purchase Order</span>
                                        </span>
                                    }
                                >

                                    <Menu.Item key="15">
                                        <Link to={'/admin-dashboard/viewPurchaseOrder'} style={{ color: 'white' }}>
                                            <span>Pending Purchase Order</span>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="16">
                                        <Link to={'/admin-dashboard/viewPurchaseOrderApproved'} style={{ color: 'white' }}>
                                            <span>Approved Purchase Order</span>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="17">
                                        <Link to={'/admin-dashboard/viewPurchaseOrderRejected'} style={{ color: 'white' }}>
                                            <span>Rejected Purchase Order</span>
                                        </Link>
                                    </Menu.Item>
                                </SubMenu>

                                {/*RFQ/RFQ Response*/}
                                <SubMenu
                                    key="rfq"
                                    title={
                                        <span>
                                            <Icon type="video-camera" />
                                            <span>Intenting System</span>
                                        </span>
                                    }
                                >
                                    <Menu.Item key="18">
                                        <Link to={'/admin-dashboard/addRFQ'}>
                                            <span>Add RFQ</span>
                                        </Link>
                                    </Menu.Item>

                                    <Menu.Item key="19">
                                        <Link to={'/admin-dashboard/manageRFQ'} >
                                            <span>Manage RFQs</span>
                                        </Link>
                                    </Menu.Item>

                                    {/* <Menu.Item key="20">
                                        <Link >
                                            <span>Sourcing</span>
                                        </Link>
                                    </Menu.Item>

                                    <Menu.Item key="21">
                                        <Link >
                                            <span>Intent</span>
                                        </Link>
                                    </Menu.Item>

                                    <Menu.Item key="22">
                                        <Link >
                                            <span>Comparative</span>
                                        </Link>
                                    </Menu.Item> */}
                                </SubMenu>


                            </Menu>
                        </Sider>
                        <Layout>
                            {/* <Header style={{ background: '#fff', padding: 0 }}>*/}
                            {/*    <span>*/}
                            {/*    <Search*/}
                            {/*placeholder="input search text"*/}
                            {/*onSearch = {this.onSearchHandler}*/}
                            {/*style={{ width: 200,marginLeft:'40%' }}*/}
                            {/*/>*/}
                            {/*    <Button type={'primary'} style={{marginLeft:'88%'}} onClick={this.props.logout}>LOGOUT</Button>*/}
                            {/*    </span>*/}
                            {/*</Header> */}

                            <div style={{ backgroundColor: '#002140', height: '60px', padding: '8px' }} className="gutter-example" >
                                {link}
                                <Row style={{ float: 'right', marginTop: '5px' }} gutter={20}>
                                    {/* <Col style={{margin:'2px',right:'600px'}} className="gutter-row" span={7}>
                                    <div>
                                      <h3 style={{color:'#FFF'}} >{username}</h3>
                                    </div>
                                  </Col> */}
                                    <Col style={{ margin: '2px', right: '180px' }} className="gutter-row" span={20}>
                                        <div className="gutter-box">
                                            <Search
                                                placeholder="search"
                                                onSearch={this.onSearchHandler}
                                            // style={{ width: 200 }}
                                            />
                                        </div>

                                    </Col>


                                    <Col style={{ margin: '2px', left: '65px', bottom: '36px' }} span={4}>
                                        <Icon style={{ color: '#FFF', fontSize: '30px' }} type="user" />
                                    </Col>
                                    <Col style={{ margin: '2px', left: '65px', bottom: '32px' }}>
                                        <Dropdown overlay={menu} trigger={['click']}>
                                            <a className="ant-dropdown-link" href="#">
                                                <span style={{ color: '#FFF', fontWeight: 'bold', fontSize: '20px' }}>{username}</span><Icon style={{ color: '#FFF' }} type="down" />
                                            </a>
                                        </Dropdown>
                                    </Col>
                                    {/* <Col style={{margin:'2px'}} className="gutter-row" span={1}>
                                    <div className="gutter-box">
                                    <Button type={'primary'}  onClick={this.props.logout}>LOGOUT</Button>
                                     </div>
                                  </Col> */}


                                </Row>
                            </div>
                            <ScrollToTop>
                                <Content style={{ margin: '24px 16px 0' }}>
                                    <div style={{ padding: 24, background: '#fff', minHeight: '110vh' }}>
                                        {/* Dashboard */}
                                        <Route path='/admin-dashboard/dashboard'>
                                            <Dashboard />
                                        </Route>

                                        {/* Pending Vendors */}
                                        <Route path='/admin-dashboard/pending'>
                                            <PendingVendors tableData={this.props.pendingVendorsList} updateMe={this.componentDidMount} />
                                        </Route>
                                        {/* Approved Vendors */}
                                        <Route path='/admin-dashboard/approved'>
                                            <ApprovedVendors tableData={this.props.approvedVendorsList} />
                                        </Route>
                                        {/* Rejected Vendors */}
                                        <Route path='/admin-dashboard/rejected'>
                                            <RejectedVendors tableData={this.props.rejectedVendorsList} />
                                        </Route>
                                        {/* Add Invoices */}
                                        <Route path="/admin-dashboard/add-invoices">
                                            <AddInvoices />
                                        </Route>
                                        {/* Manage Invoices */}
                                        <Route path="/admin-dashboard/manage-invoices">
                                            <ManageInvoices />
                                        </Route>
                                        <Route path="/admin-dashboard/searched">
                                            <SearchInvoices />
                                        </Route>
                                        <Route path="/admin-dashboard/searched2">
                                            <SearchInvoices />
                                        </Route>
                                        {/* Add Users */}
                                        <Route path="/admin-dashboard/add-users">
                                            <AddUsers />
                                        </Route>
                                        {/* Manage Users */}
                                        <Route path="/admin-dashboard/manage-users">
                                            <ManageUsers />
                                        </Route>

                                        {/* Add levels */}
                                        <Route path="/admin-dashboard/add-levels">
                                            <AddLevels />
                                        </Route>
                                        {/* Manage levels */}
                                        <Route path="/admin-dashboard/manage-levels">
                                            <ManageLevels {...this.props} />
                                        </Route>

                                        {/* Manage Payments Advoices */}
                                        <Route path="/admin-dashboard/paymentAdvoicesList">
                                            <PaymentAdvoicesList paymentAdvoiceList={this.props.invoicesList} />
                                        </Route>

                                        <Route path="/admin-dashboard/viewPurchaseOrder">
                                            <ViewPurchaseOrder pendingStatus={poStatusPending} permission={userPermission} path="/admin-dashboard/printPurchaseOrder" updateMe={this.componentDidMount} purchaseOrderList={this.props.purchaseOrderList} />
                                        </Route>

                                        <Route path="/admin-dashboard/viewPurchaseOrderApproved">
                                            <ViewPurchaseOrder approvedStatus={poStatusApproved} permission={userPermission} path="/admin-dashboard/printPurchaseOrder" updateMe={this.componentDidMount} purchaseOrderList={this.props.purchaseOrderList} />
                                        </Route>

                                        <Route path="/admin-dashboard/viewPurchaseOrderRejected">
                                            <ViewPurchaseOrder rejectedStatus={poStatusRejected} permission={userPermission} path="/admin-dashboard/printPurchaseOrder" updateMe={this.componentDidMount} purchaseOrderList={this.props.purchaseOrderList} />
                                        </Route>

                                        <Route path="/admin-dashboard/printPurchaseOrder">
                                            <PrintPurchaseOrder />
                                        </Route>


                                        <Route path="/admin-dashboard/addRFQ">
                                            <AddRFQ />
                                        </Route>

                                        <Route path="/admin-dashboard/manageRFQ">
                                            <ViewRFQ />
                                        </Route>

                                        <Route path="/admin-dashboard/rfqQuotationList">
                                            <RFQQuotationList />
                                        </Route>

                                        <Route path="/admin-dashboard/rfqQuotation">
                                            <RFQQuotation />
                                        </Route>
                                    </div>
                                </Content>
                                {/* <Footer style={{ textAlign: 'center',zIndex:1}}>VMS Design ©2019 Created by NativeByte</Footer> */}
                            </ScrollToTop>
                        </Layout>
                    </Layout>
                </div>
            </HashRouter>

        )
    }
}

function mapStateToProps(state) {
    return {
        register: state.register,
        vendor: state.vendorReducer,
        token: state.auth.token,
        auth: state.auth,
        meta: state.auth.meta,
        invoicesList: state.vendorReducer.invoicesList,
        pendingVendorsList: state.vendorReducer.pendingVendorsList,
        approvedVendorsList: state.vendorReducer.approvedVendorsList,
        rejectedVendorsList: state.vendorReducer.rejectedVendorsList,
        searchInvoicesList: state.vendorReducer.searchInvoicesList,
        purchaseOrderList: state.users.purchaseOrderList,

    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ getPurchaseOrder, FetchInvoicesList: invoicesList, vendorsList: vendorsList, logout: logout, tryAlreadyLoggedInProfile: tryAlreadyLoggedInProfile, invoiceSearch }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(AdminDashboard)



